import axios from "axios";
import { authEndpoints } from "../helpers/constants";
import { toCamelCase } from "../helpers/toCamelCase";
import tokenStorage from "../helpers/tokenStorage";

axios.interceptors.request.use(async (config) => {
  if (authEndpoints.some((e) => config.url?.endsWith(e))) {
    return config;
  }

  if (config.headers) {
    config.headers["Authorization"] = `Bearer ${tokenStorage.token}`;
  }

  if (tokenStorage.isInvalid || tokenStorage.isExpired) {
    window.location.href = "/logout";
  }

  return config;
});
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && (error.response.status === 403 || error.response.status === 401)) {
      tokenStorage.clear();
      window.location.replace("/login");
    }
    console.error(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use((response) => {
  if (
    response.headers &&
    response.headers["content-type"] !== "application/zip"
  ) {
    response.data = toCamelCase(response.data);
  }
  return response;
});
