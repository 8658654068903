import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { generalPost } from "../api/general";

export const usePostQuery = <T>(
  url: string,
  body: any,
  skip: boolean = false
) => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);
  const [loading, setLoading] = useState(false);

  const getData = async (): Promise<void> => {
    setLoading(true);
    try {
      const resp = await generalPost<T>(url, body);
      setData(resp.data);
      setError(null);
    } catch (ex) {
      setError(ex as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!skip) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, body, skip]);

  return { data, error, loading, setData };
};
