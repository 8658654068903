import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { PropsWithChildren } from "react";
import ParsePortImg from "../../images/parseport_black.svg";

interface LoginContainerProps extends PropsWithChildren {
  sx?: SxProps;
}

const LoginContainer = ({ children, ...other }: LoginContainerProps) => {
  return (
    <Container maxWidth="xs" {...other}>
      <Paper variant="outlined" sx={{ p: 4, mb: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={ParsePortImg} alt="Parseport" style={{ maxHeight: 120 }} />
        </Box>
        {children}
      </Paper>
    </Container>
  );
};

export default LoginContainer;
