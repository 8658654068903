import { t, Trans } from "@lingui/macro";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { createNewUser } from "../../api/users";
import {
  getUserEmailValidationError,
  getUserNameValidationError,
  isUserValid,
} from "../../helpers/validateNewUser";

export interface CreateOrganizationUserDialogProps {
  open: boolean;
  organizationId: string;
  onClose: (updateStatus: "none" | "success" | "error", reason?: string) => void;
}

const CreateOrganizationUserDialog = ({
  open,
  organizationId,
  onClose,
}: CreateOrganizationUserDialogProps) => {
  const [currentUserName, setCurrentUserName] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [currentIsAdmin, setCurrentIsAdmin] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const handleClose = (
    updateStatus: "none" | "success" | "error",
    reason?: string
  ) => {
    setCurrentEmail("");
    setCurrentUserName("");
    setCurrentIsAdmin(false);
    setSaving(false);
    onClose(updateStatus, reason);
  };

  const handleFieldValueChange = (fieldName: "name" | "email", val: string) => {
    if (fieldName === "name") {
      setCurrentUserName(val);
      setIsValid(
        isUserValid({
          emailAddress: currentEmail,
          name: val,
        })
      );
    } else if (fieldName === "email") {
      setCurrentEmail(val);
      setIsValid(
        isUserValid({
          emailAddress: val,
          name: currentUserName,
        })
      );
    }
  };

  const handleSubmit = async () => {
    setSaving(true);
    try {
      const createUserPromise = createNewUser({
        emailAddress: currentEmail,
        isAdministrator: currentIsAdmin,
        name: currentUserName,
        organizationId: organizationId,
      });
      await createUserPromise;
      handleClose("success", t`User created successfully`);
    } catch (ex: any) {
      setSaving(false);
      handleClose(
        "error",
        ex.response?.data?.IsTranslated
          ? ex.response.data.Exceptions[0]
          : t`Error creating user. Try again later`
      );
    }
  };
  const nameError =
    currentUserName.length > 0 &&
      getUserNameValidationError(currentUserName).length > 0
      ? getUserNameValidationError(currentUserName)
      : "";
  const emailError =
    currentEmail.length > 0 &&
      getUserEmailValidationError(currentEmail).length > 0
      ? getUserEmailValidationError(currentEmail)
      : "";

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={(reason) => {
        if (reason !== "backdropClick" || "escapeKeyDown") {
          handleClose("none");
        }
      }}
    >
      <>
        <DialogTitle>
          <Trans>Create User</Trans>
        </DialogTitle>
        <DialogContent sx={{ minHeight: 210 }}>
          <Stack spacing={2}>
            {saving && (
              <DialogContentText>
                <Trans>Hang in there, This might take a few minutes...</Trans>
              </DialogContentText>
            )}
            {!saving && (
              <>
                <TextField
                  sx={{ mt: 1 }}
                  error={
                    currentUserName.length > 0 &&
                    getUserNameValidationError(currentUserName).length > 0
                  }
                  value={currentUserName}
                  onChange={(e) =>
                    handleFieldValueChange("name", e.currentTarget.value)
                  }
                  label={t`Name`}
                  helperText={nameError}
                />
                <TextField
                  sx={{ mt: 1 }}
                  error={
                    currentEmail.length > 0 &&
                    getUserNameValidationError(currentEmail).length > 0
                  }
                  value={currentEmail}
                  onChange={(e) =>
                    handleFieldValueChange("email", e.currentTarget.value)
                  }
                  label={t`Email`}
                  helperText={emailError}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={currentIsAdmin}
                      onChange={(e) => setCurrentIsAdmin(e.target.checked)}
                    />
                  }
                  label={t`Is Administrator`}
                />
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => handleClose("none")}>
            <Trans>Close</Trans>
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={saving || !isValid}
          >
            {!saving && <Trans>Save</Trans>}
            {saving && <CircularProgress size={25} />}
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
};

export default CreateOrganizationUserDialog;
