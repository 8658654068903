import axios, { AxiosResponse } from "axios";
import { ApiOrganization, ApiOrganizationRequest, ApiSchemes } from "./types";

export const createNewOrganization = async (
  payload: ApiOrganizationRequest
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/organizations/signup`, payload);
};

export const updateOrganizationDetails = async (
  id: string,
  payload: Pick<
    ApiOrganization,
    | "name"
    | "country"
    | "registrationNumber"
    | "leiCode"
    | "isCredentialAuthDisallowed"
    | "licensing"
    | "SendSignUpEmail"
    | "isMicrosoftAuthDisallowed"
  >
): Promise<AxiosResponse<ApiOrganization>> => {
  return axios.post(`/api/organizations/${id}/update`, payload);
};

export const toggleOrganizationActivation = async (
  id: string,
  activate: boolean
): Promise<AxiosResponse<void>> => {
  const url = `/api/organizations/${id}/${activate ? "activate" : "deactivate"
    }`;
  return axios.post(url);
};

export const getAllSchemes = async (cb: string): Promise<AxiosResponse<ApiSchemes[]>> => {
  return axios.get(`/api/organizations/permissionSchemes/all?cb=${cb}`);
}

export const getOrganizationSchemes = async (id: string, cb: string): Promise<AxiosResponse<ApiSchemes[]>> => {
  return axios.get(`/api/organizations/${id}/permissionSchemes?cb=${cb}`);
}

export const addScheme = async (scheme: string, schemeId: string): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/organizations/${scheme}/permissionSchemes/add/${schemeId}`);
}

export const removeScheme = async (scheme: string, schemeId: string): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/organizations/${scheme}/permissionSchemes/remove/${schemeId}`);
}

export const addSubscription = async (id: string, permissionsSchemeId: string, expireAt: string | null): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/organizations/subscriptions/add`,
    {
      "OrganizationId": id,
      "PermissionsSchemeId": permissionsSchemeId,
      "ExpireAt": expireAt
    });
}

export const getPermissionSchemesAvaliable = async (id: string): Promise<AxiosResponse<ApiSchemes[]>> => {
  return axios.get(`/api/organizations/${id}/permissionSchemes/available`);
}

export const removeSubscription = async (id: string): Promise<AxiosResponse<void>> => {
  return axios.delete(`/api/organizations/subscriptions/${id}/remove`,);
}
