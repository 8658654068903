import { t } from "@lingui/macro";
import { Autocomplete, Box, Container, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableContainer, TextField, Typography } from "@mui/material";
import DataTableRow from "./dataTableRow";
import EmptyTableAlert from "./emptyTableAlert";
import TableHeader, { TableColumnHeader } from "./tableHeader";
import SearchIcon from "@mui/icons-material/Search";
import { Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import React from "react";

export interface DataTableColumn<T> {
  header: TableColumnHeader<T>;
  key: keyof T | string;
  valueFormatter?: (row: T) => string | JSX.Element;
  width?: number;
  shouldWrapVertically?: boolean;
}

export interface DataTableProps<T extends { id: string }> {
  data: T[];
  columns: DataTableColumn<T>[];
  onClickRow?: (rowid: string) => void;
  expandedRowComponent?: React.ComponentType<{
    rowData: T;
    columnCount: number;
    isOpen: boolean;
  }>;
  expandOnRowClick?: boolean;
  nested?: boolean;
  minHeight?: number;
  maxHeight?: number;
  noWidth?: boolean;
  setFilter?: (id: string, value: string) => void;
  noDataMessage?: string;
}

export default function DataTable<T extends { id: string }>({
  data,
  columns,
  onClickRow,
  expandedRowComponent,
  expandOnRowClick,
  nested,
  minHeight,
  maxHeight,
  noWidth,
  setFilter,
  noDataMessage = t`Consider removing some existing filters or adding some`,
}: DataTableProps<T>) {

  // Pass headersFilter to TableHeaderFiltering
  return (
    <Container
      sx={{
        minHeight: 150,
        backgroundColor: "transparent",
        pr: noWidth ? "0!important" : undefined,
        pl: noWidth ? "0!important" : undefined,
      }}
      maxWidth={false}
    >
      <TableContainer
        sx={{
          pb: 5,
          maxHeight: maxHeight,
          minHeight: minHeight,
        }}
      >
        <TableHeaderFiltering
          columnHeaders={columns.map((column) => column.header)}
          setFilter={setFilter}
        />
        <Table
          stickyHeader
          sx={{
            minWidth: nested ? undefined : 750,
            margin: "0 auto",
          }}
          aria-labelledby="tableTitle"
          size={nested ? "small" : "medium"}
        >
          <TableHeader
            setFilter={setFilter}
            columnHeaders={columns.map((column) => column.header)}
            expandable={expandedRowComponent !== undefined}
            nested={nested}
          />
          <TableBody>
            {data.map((row, index) => {
              return (
                <DataTableRow<T>
                  key={`data_table_row_${row.id}`}
                  index={index}
                  columns={columns}
                  onClickRow={onClickRow}
                  rowData={row}
                  expandOnRowClick={expandOnRowClick}
                  expandedRowComponent={expandedRowComponent}
                  nested={nested}
                />
              );
            })}
          </TableBody>
        </Table>
        {data.length === 0 && (
          <EmptyTableAlert
            severity="info"
            title={t`No data found`}
            subTitle={noDataMessage}
            mainIcon={
              <SearchIcon sx={{ fontSize: 75, mb: 2, fill: "#e6e6e6" }} />
            }
            showIcon={true}
          />
        )}
      </TableContainer>
    </Container>
  );
}

interface TableProps<T> {
  columnHeaders: TableColumnHeader<T>[];
  setFilter?: (id: string, value: string) => void;
}

const TableHeaderFiltering = <T,>({
  columnHeaders,
  setFilter,
}: TableProps<T>) => {
  const [selectedValues, setSelectedValues] = useState<{ [key: string]: string }>({});

  const handleSelectChange = (id: string, value: string) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));

    // Update the req object with the filter value using the setFilter prop
    setFilter && setFilter(id, value);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', mb: 2 }}>
      {columnHeaders.map((ch, index) => (
        <Fragment key={index}>
          {ch.shouldBeFiltered && (
            <Box sx={{ display: 'flex', flexDirection: 'column', mr: 2 }}>
              <Typography variant="subtitle2" sx={{ mb: 0.5, color: "gray" }}>
                {ch.label}
              </Typography>
              {ch.inputType === "select" ? (
                <FormControl sx={{ width: ch.width ? ch.width : 210 }} size="small" variant="outlined">
                  <InputLabel>{ch.optionsHeaderText}</InputLabel>
                  <Select
                    label={ch.optionsHeaderText}
                    value={selectedValues[ch.id as string] || ''}
                    onChange={(e) => handleSelectChange(ch.id as string, e.target.value as string)}
                  >
                    {ch.options?.map((option, index) => (
                      <MenuItem key={option + index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  label={ch.optionsHeaderText}
                  variant="outlined"
                  size="small"
                  value={selectedValues[ch.id as string] || ''}
                  onChange={(e) => handleSelectChange(ch.id as string, e.target.value)}
                />
              )}
            </Box>
          )}
        </Fragment>
      ))}
    </Box >
  );
};
