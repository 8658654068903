import { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { AlertColor } from "@mui/material/Alert";
import { t } from "@lingui/macro";

interface AlertMessage {
    message: string;
    severity: AlertColor | undefined;
}

interface AlertSnackbarProps {
    alertMessage: AlertMessage | undefined;
    updateAlertStatus: (
        alertMessage: AlertMessage | undefined
    ) => void;
}

const AlertSnackbar = ({ alertMessage, updateAlertStatus }: AlertSnackbarProps) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (alertMessage && alertMessage.message) {
            setOpen(true);
        }
    }, [alertMessage]);

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
        updateAlertStatus(undefined);
    };

    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
            <Alert
                onClose={handleClose}
                severity={alertMessage?.severity}
                sx={{ width: "100%" }}
            >
                {alertMessage?.message || t`Something went wrong. Please try again later.`}
            </Alert>
        </Snackbar>
    );
};

export default AlertSnackbar;
