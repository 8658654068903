import "./api/config";
import "./api/interceptors";
import { i18n } from "@lingui/core";
import { messages as enMessages } from "./locales/en/messages";
import { I18nProvider } from "@lingui/react";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter } from "react-router-dom";
import createCache from "@emotion/cache";
import { theme } from "./helpers/theme";
import MainNavigation from "./components/mainNavigation";

function App() {
  i18n.load({
    en: enMessages,
  });
  i18n.activate("en");

  return (
    <I18nProvider i18n={i18n}>
      <CacheProvider value={cache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <MainNavigation />
          </BrowserRouter>
        </ThemeProvider>
      </CacheProvider>
    </I18nProvider>
  );
}

const cache = createCache({
  key: "key",
  nonce:
    document.querySelector<HTMLMetaElement>('meta[property="csp-nonce"]')
      ?.content || "",
});

export default App;
