import { t } from "@lingui/macro";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useState } from "react";
import { ApiOrganization, ApiUser } from "../api/types";
import { useGetQuery } from "../hooks/useGetQuery";

export interface AsyncAutoCompleteTextFieldProps {
  onSelect: (val: string | null) => void;
  selectedValueId: string | null;
  label: string;
  endpointUrl: string;
  minimunSearchKeyCount: number;
  useEncodeURIComponent: boolean;
}

export default function AsyncAutoCompleteTextField({
  onSelect,
  selectedValueId,
  label,
  endpointUrl,
  minimunSearchKeyCount,
  useEncodeURIComponent,
}: AsyncAutoCompleteTextFieldProps) {
  const [inputValue, setInputValue] = useState("");

  function generateQueryUrl(
    inputValue: string,
    selectedValueId: string | null,
    minimumSearchKeyCount: number,
    endpointUrl: string
  ): string {
    if (useEncodeURIComponent) {
      if (inputValue.length >= minimumSearchKeyCount) {
        return `${endpointUrl}=${inputValue}`;
      } else if (selectedValueId !== null) {
        return `${endpointUrl}=${encodeURIComponent(selectedValueId)}`;
      } else {
        return endpointUrl;
      }
    } else {
      if (inputValue.length >= minimumSearchKeyCount) {
        return `${endpointUrl}=${inputValue}`;
      } else {
        return endpointUrl;
      }
    }
  }

  const queryUrl = generateQueryUrl(
    inputValue,
    selectedValueId,
    minimunSearchKeyCount,
    endpointUrl,
  );

  const selectedValueIdGuard = (value: unknown) => {
    return (
      typeof value === "string" &&
      value !== null &&
      value !== undefined &&
      value !== ""
    );
  };

  const Shouldskip =
    inputValue.length < minimunSearchKeyCount &&
      !selectedValueIdGuard(selectedValueId)
      ? true
      : false;

  const { data, loading, setData } = useGetQuery<
    ApiUser[] | ApiOrganization[] | ApiUser | ApiOrganization
  >(queryUrl, Shouldskip);


  const val =
    data &&
    ((Array.isArray(data) ? data : [data]) as any[]).find(
      (item: ApiOrganization | ApiUser) =>
        useEncodeURIComponent
          ? item.name === selectedValueId
          : item.id === selectedValueId
    );

  return (
    <Autocomplete
      getOptionLabel={(option: ApiUser | ApiOrganization) => option.name}
      filterOptions={(x) => x}
      options={data ? (Array.isArray(data) ? data : [data]) : []}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={val ? val : null}
      noOptionsText={t`No search results found`}
      onChange={(event: any, newValue: ApiOrganization | ApiUser | null) => {
        onSelect(
          newValue?.id ? newValue.id : newValue?.name ? newValue.name : null
        );
      }}
      onInputChange={(event, newInputValue) => {
        if (newInputValue.length < minimunSearchKeyCount) {
          setData(null);
        }
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option: ApiUser | ApiOrganization) => {
        return <li {...props}>{option.name}</li>;
      }}
    />
  );
}
