import { t } from "@lingui/macro";
import { Container, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiUser } from "../../api/types";
import { useGetQuery } from "../../hooks/useGetQuery";
import ContentHeaderToolbar from "../contentHeaderToolbar";
import DataTable from "../dataTable";
import EmptyTableAlert from "../emptyTableAlert";
import { Loader } from "../loader";
import UserDetailsDialog from "./userDetailsDialog";
import { usersTableColumns } from "./usersTableColumns";
import SearchIcon from "@mui/icons-material/Search";
import { ContainerWrapper } from "../containerWrapper";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import AlertSnackbar from "../alertSnackbar";

const Users = () => {
  const [cacheBuster, setCacheBuster] = useState(crypto.randomUUID());
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [currentQuery, setCurrentQuery] = useState("");
  const { data, error, loading } = useGetQuery<ApiUser[]>(
    `/api/users/search?keyword=${currentQuery}&cb=${cacheBuster}`,
    currentQuery.length < 3
  );
  const [updateStatus, setUpdateStatus] = useState<
    "none" | "success" | "error"
  >("none");

  const handleClosedUserDetailsDialog = (
    updateStatus: "none" | "success" | "error"
  ) => {
    setSelectedUserId(undefined);
    setUpdateStatus(updateStatus);
    if (updateStatus === "success") {
      setCacheBuster(crypto.randomUUID());
    }
  };

  const navigate = useNavigate();

  /* Not optimal but works? - is there a better method? */
  useEffect(() => {
    if (selectedUserId !== undefined) {
      navigate(`/users/${selectedUserId}`);
    }
  }, [selectedUserId, navigate]);

  return (
    <>
      <ContainerWrapper>
        <Container
          maxWidth={false}
          sx={{
            ml: 0,
            mb: 10,
            overflow: "auto",
          }}
        >
          <Paper
            elevation={1}
            sx={{
              maxHeight: 1000,
              minHeight: 650,
              overflow: "auto",
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Container maxWidth={false}>
              <ContentHeaderToolbar
                titleIcon={<GroupOutlinedIcon fontSize="large" />}
                title={t`Users`}
                actions={[]}
                showIcons={false}
                searchBar
                searchAutoFocus={true}
                searchValue={currentQuery}
                onUpdateSearch={setCurrentQuery}
                searchPlaceholder={t`Search users`}
                searchBarWidth={400}
              />
            </Container>
            {loading && <Loader height={500} />}
            {currentQuery.length < 3 && (
              <EmptyTableAlert
                severity="info"
                title={t`No users loaded`}
                subTitle={t`Use the search bar to find users (minimum 3 characters)`}
                mainIcon={
                  <SearchIcon sx={{ fontSize: 75, mb: 2, fill: "#e6e6e6" }} />
                }
                showIcon={true}
              />
            )}
            {currentQuery.length > 2 &&
              !loading &&
              !error &&
              data &&
              data.length === 0 && (
                <EmptyTableAlert
                  severity="info"
                  title={t`No organizations match your query`}
                  subTitle={t`No luck, Try changing your search filter`}
                  mainIcon={
                    <ThumbDownOffAltIcon
                      sx={{ fontSize: 75, mb: 2, fill: "#e6e6e6" }}
                    />
                  }
                  showIcon={true}
                />
              )}
            {currentQuery.length > 2 &&
              !loading &&
              !error &&
              data &&
              data.length > 0 && (
                <DataTable<ApiUser>
                  columns={usersTableColumns()}
                  data={data}
                  onClickRow={setSelectedUserId}
                />
              )}
          </Paper>
        </Container>
        <UserDetailsDialog
          open={selectedUserId !== undefined}
          userId={selectedUserId}
          onClose={handleClosedUserDetailsDialog}
        />
        <AlertSnackbar
          alertMessage={
            updateStatus === "success"
              ? {
                message: t`User updated successfully.`,
                severity: "success",
              }
              : updateStatus === "error"
                ? {
                  message: t`Something went wrong. Please try again later.`,
                  severity: "error",
                }
                : undefined
          }
          updateAlertStatus={() => setUpdateStatus("none")}
        />
      </ContainerWrapper>
    </>
  );
};

export default Users;
