import { t, Trans } from "@lingui/macro";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ApiUserDetails } from "../../api/types";
import { useGetQuery } from "../../hooks/useGetQuery";
import ContentHeaderToolbar from "../contentHeaderToolbar";
import DataTable from "../dataTable";
import EmptyTableAlert from "../emptyTableAlert";
import UserDetailsDialog from "./userDetailsDialog";
import { orgUsersTableColumns } from "./usersTableColumns";
import AddIcon from "@mui/icons-material/Add";
import { toggleUserActivation } from "../../api/users";
import InboxIcon from "@mui/icons-material/Inbox";
import CreateOrganizationUserDialog from "./createOrganizationUserDialog";
import { Loader } from "../loader";
import AlertSnackbar from "../alertSnackbar";

const OrganizationUsers = () => {
  const { id } = useParams();
  const [cacheBuster, setCacheBuster] = useState(crypto.randomUUID());
  const [selectedUser, setSelectedUser] = useState<ApiUserDetails>();
  const [showCreateUserDialog, setShowCreateUserDialog] = useState(false);
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const [showConsentDialog, setShowConsentDialog] = useState(false);
  const [searchName, setSearchName] = useState("");

  const { data, error, loading } = useGetQuery<ApiUserDetails[]>(
    `/api/organizations/${id}/users?&cb=${cacheBuster}`
  );

  const [alertMessage, setAlertMessage] = useState<{
    message: string;
    severity: "error" | "warning" | "info" | "success" | undefined;
  } | undefined>(undefined);

  const handleClosedUserDialog = (
    status: "none" | "success" | "error",
    message: string = ""
  ) => {
    setSelectedUser(undefined);
    setShowDetailsDialog(false);
    setShowCreateUserDialog(false);

    if (status === "success") {
      setCacheBuster(crypto.randomUUID());
      setAlertMessage({ message: t`User updated!`, severity: "success" });
    } else if (status === "error") {
      setAlertMessage({ message, severity: "error" });
    }
  };

  const handleUserActionClicked = (
    userId: string,
    action: "showDetails" | "toggleActivation"
  ) => {
    setSelectedUser(data?.find((u) => u.id === userId));
    if (action === "showDetails") {
      setShowDetailsDialog(true);
    } else if (action === "toggleActivation") {
      setShowConsentDialog(true);
    }
  };

  const handleToggleUserActivation = async (): Promise<void> => {
    try {
      setShowConsentDialog(false);
      await toggleUserActivation(
        selectedUser?.id || "",
        Boolean(selectedUser?.isDeactiviated)
      );
      handleClosedUserDialog("success");
    } catch (ex) {
      handleClosedUserDialog("error", t`Error toggling user activation. Try again later.`);
    }
  };

  return (
    <>
      <Paper sx={{ mt: -1, minHeight: 250, mb: 5, borderRadius: "0px" }}>
        <Container maxWidth={false}>
          <ContentHeaderToolbar
            searchBar
            searchValue={searchName}
            onUpdateSearch={setSearchName}
            searchPlaceholder={t`Search User Name`}
            searchAutoFocus={false}
            searchBarWidth={400}
            title={t`Users `}
            actions={[
              {
                id: "addNew",
                title: t`Create new user`,
                icon: AddIcon,
                disabled: false,
                turnedOn: false,
                clickAction: () => setShowCreateUserDialog(true),
              },
            ]}
            CreateButton={true}
            createButtonWidth={220}
            showIcons
          />
        </Container>
        <>
          {loading && <Loader height={250} />}
          {!error && data && data.length === 0 && (
            <EmptyTableAlert
              severity="info"
              subTitle={t`It would seem like there are no users linked to this company. Try and create one.`}
              mainIcon={
                <InboxIcon sx={{ fontSize: 75, mb: 2, fill: "#e6e6e6" }} />
              }
              showIcon={true}
            />
          )}
          {!loading && !error && data && data.length > 0 && (
            <DataTable<ApiUserDetails>
              columns={orgUsersTableColumns(
                (id) => handleUserActionClicked(id, "toggleActivation"),
                data.some((u) => u.isAdministrator !== undefined)
              )}
              data={data.filter((user) =>
                user.name.toLowerCase().includes(searchName.toLowerCase())
              )}
              onClickRow={(id) => handleUserActionClicked(id, "showDetails")}
            />
          )}
        </>
      </Paper>
      <CreateOrganizationUserDialog
        open={showCreateUserDialog}
        organizationId={id || ""}
        onClose={handleClosedUserDialog}
      />
      <UserDetailsDialog
        open={showDetailsDialog}
        userId={selectedUser?.id}
        onClose={() => handleClosedUserDialog("none")}
        removeActivationToggleField
      />
      <Dialog open={showConsentDialog}>
        <DialogTitle>{t`Are you sure?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Trans>You are about to</Trans>{" "}
            {selectedUser?.isDeactiviated ? t`activate` : t`deactivate`}{" "}
            <Trans>this user</Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConsentDialog(false)}>{t`No`}</Button>
          <Button onClick={handleToggleUserActivation}>{t`Yes`}</Button>
        </DialogActions>
      </Dialog>
      <AlertSnackbar alertMessage={alertMessage} updateAlertStatus={setAlertMessage} />
    </>
  );
};

export default OrganizationUsers;
