import { TableCell, Typography } from "@mui/material";
import { DataTableColumn } from "./dataTable";

interface DataTableCellProps<T> {
  row?: T;
  column?: DataTableColumn<T>;
  nested?: boolean;
}

export default function DataTableCell<T>({
  row,
  column,
  nested,
}: DataTableCellProps<T>) {
  return (
    <TableCell
      sx={{
        overflowY: "auto",
        borderBottom: nested ? "unset" : "default",
        minWidth: column?.width ? column.width : undefined,
        maxWidth: column?.width ? column.width : undefined,
        overflow: "hidden",
      }}
    >
      <>
        <>
          {column?.valueFormatter ? (
            column?.valueFormatter(row as T)
          ) : column?.shouldWrapVertically && row ? (
            <>
              {Array.isArray(row[column.key as keyof T]) ? (
                (row[column.key as keyof T] as any[]).map((item, index) => (
                  <Typography
                    key={index}
                    sx={{
                      display: "block",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      fontSize: "0.8rem",
                    }}
                  >
                    {item}
                  </Typography>
                ))
              ) : (
                <Typography
                  sx={{
                    display: "block",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontSize: "0.8rem",
                  }}
                >
                  {row[column.key as keyof T] as string}
                </Typography>
              )}
            </>
          ) : row && column ? (
            <Typography
              sx={{
                display: "block",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontSize: "0.85rem",
              }}
            >
              {row[column.key as keyof T] as string}
            </Typography>
          ) : (
            ""
          )}
        </>
      </>
    </TableCell>
  );
}
