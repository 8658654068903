import { TaxonomyListProps } from "../api/types";

export const getTaxonomies = (): TaxonomyListProps[] => {
    return [
        {
            "languages": [
                "en"
            ],
            "rewrites": [],
            "baseUri": null,
            "strictParsing": false,
            "useRealTableNamespace": true,
            "validationRuleset": null,
            "identifierElementId": null,
            "identifierElementName": null,
            "reportTypeElementName": null,
            "reportTypeElementId": null,
            "labels": [
                {
                    "lang": "en",
                    "text": "Article8 0.9",
                    "role": "standard",
                    "id": null
                }
            ],
            "id": "65cc69e9dd13616c48c4ba21"
        },
        {
            "languages": [
                "en",
                "da",
                "hi",
                "ar",
                "pt",
                "zh",
                "ru",
                "es",
                "fr",
                "de"
            ],
            "rewrites": [],
            "baseUri": null,
            "strictParsing": false,
            "useRealTableNamespace": true,
            "validationRuleset": null,
            "identifierElementId": null,
            "identifierElementName": null,
            "reportTypeElementName": null,
            "reportTypeElementId": null,
            "labels": [
                {
                    "lang": "en",
                    "text": "DK ÅRL 20201001",
                    "role": "standard",
                    "id": null
                },
                {
                    "lang": "da",
                    "text": "DK ÅRL 20201001",
                    "role": "standard",
                    "id": null
                },
                {
                    "lang": "da",
                    "text": "DK GAAP 20201001",
                    "role": "standard",
                    "id": null
                },
                {
                    "lang": "en",
                    "text": "DK GAAP 20201001",
                    "role": "standard",
                    "id": null
                }
            ],
            "id": "5ffbe7e5dc676a23c880f19a"
        },
        {
            "languages": [
                "en",
                "da",
                "hi",
                "ar",
                "pt",
                "zh",
                "ru",
                "es",
                "fr",
                "de"
            ],
            "rewrites": [],
            "baseUri": null,
            "strictParsing": false,
            "useRealTableNamespace": false,
            "validationRuleset": null,
            "identifierElementId": null,
            "identifierElementName": null,
            "reportTypeElementName": null,
            "reportTypeElementId": null,
            "labels": [
                {
                    "lang": "en",
                    "text": "DK ÅRL 20211001",
                    "role": "standard",
                    "id": null
                },
                {
                    "lang": "da",
                    "text": "DK ÅRL 20211001",
                    "role": "standard",
                    "id": null
                },
                {
                    "lang": "da",
                    "text": "DK GAAP 20211001",
                    "role": "standard",
                    "id": null
                },
                {
                    "lang": "en",
                    "text": "DK GAAP 20211001",
                    "role": "standard",
                    "id": null
                }
            ],
            "id": "619e63a6ae0f62252cf2c650"
        },
        {
            "languages": [
                "en",
                "da",
                "hi",
                "ar",
                "pt",
                "zh",
                "ru",
                "es",
                "fr",
                "de"
            ],
            "rewrites": [],
            "baseUri": null,
            "strictParsing": false,
            "useRealTableNamespace": false,
            "validationRuleset": null,
            "identifierElementId": null,
            "identifierElementName": null,
            "reportTypeElementName": null,
            "reportTypeElementId": null,
            "labels": [
                {
                    "lang": "da",
                    "text": "DK GAAP 20221001",
                    "role": "standard",
                    "id": null
                },
                {
                    "lang": "en",
                    "text": "DK GAAP 20221001",
                    "role": "standard",
                    "id": null
                }
            ],
            "id": "6449c3abae0f6207a49a88c0"
        },
        {
            "languages": [
                "en",
                "da",
                "hi",
                "ar",
                "pt",
                "zh",
                "ru",
                "es",
                "fr",
                "de"
            ],
            "rewrites": [],
            "baseUri": null,
            "strictParsing": false,
            "useRealTableNamespace": true,
            "validationRuleset": null,
            "identifierElementId": null,
            "identifierElementName": null,
            "reportTypeElementName": null,
            "reportTypeElementId": null,
            "labels": [
                {
                    "lang": "en",
                    "text": "DK GAAP 20231001",
                    "role": "standard",
                    "id": null
                },
                {
                    "lang": "da",
                    "text": "DK GAAP 20231001",
                    "role": "standard",
                    "id": null
                },
                {
                    "lang": "en",
                    "text": "DK ÅRL",
                    "role": "standard",
                    "id": null
                },
                {
                    "lang": "da",
                    "text": "DK ÅRL",
                    "role": "standard",
                    "id": null
                },
                {
                    "lang": "en",
                    "text": "DK GAAP",
                    "role": "standard",
                    "id": null
                },
                {
                    "lang": "da",
                    "text": "DK GAAP",
                    "role": "standard",
                    "id": null
                }
            ],
            "id": "66162254092c0a265fcc9bf7"
        },
        {
            "languages": [
                "da",
                "en"
            ],
            "rewrites": [],
            "baseUri": null,
            "strictParsing": true,
            "useRealTableNamespace": true,
            "validationRuleset": null,
            "identifierElementId": null,
            "identifierElementName": null,
            "reportTypeElementName": null,
            "reportTypeElementId": null,
            "labels": [
                {
                    "lang": "da",
                    "text": "DK IFRS 20201220",
                    "role": "standard",
                    "id": null
                },
                {
                    "lang": "en",
                    "text": "DK IFRS 20201220",
                    "role": "standard",
                    "id": null
                }
            ],
            "id": "617708318309c482b0262d9d"
        },
        {
            "languages": [
                "da",
                "en",
                "hi",
                "ar",
                "pt",
                "zh",
                "ru",
                "es",
                "fr",
                "de"
            ],
            "rewrites": [
                {
                    "uri": "http://ifrs/",
                    "rewrite": "http://archprod.service.eogs.dk/taxonomy/20211220/ifrs/"
                },
                {
                    "uri": "http://mrv/",
                    "rewrite": "http://archprod.service.eogs.dk/taxonomy/20211220/mrv/"
                },
                {
                    "uri": "http://arr/",
                    "rewrite": "http://archprod.service.eogs.dk/taxonomy/20211220/arr/"
                },
                {
                    "uri": "http://gsd/",
                    "rewrite": "http://archprod.service.eogs.dk/taxonomy/20211220/gsd/"
                },
                {
                    "uri": "http://sob/",
                    "rewrite": "http://archprod.service.eogs.dk/taxonomy/20211220/sob/"
                }
            ],
            "baseUri": "http://archprod.service.eogs.dk/taxonomy/",
            "strictParsing": false,
            "useRealTableNamespace": false,
            "validationRuleset": null,
            "identifierElementId": null,
            "identifierElementName": null,
            "reportTypeElementName": null,
            "reportTypeElementId": null,
            "labels": [
                {
                    "lang": "da",
                    "text": "DK IFRS 20211220",
                    "role": "standard",
                    "id": null
                },
                {
                    "lang": "en",
                    "text": "DK IFRS 20211220",
                    "role": "standard",
                    "id": null
                },
                {
                    "lang": "da",
                    "text": "DK IFRS",
                    "role": "standard",
                    "id": null
                },
                {
                    "lang": "en",
                    "text": "DK IFRS",
                    "role": "standard",
                    "id": null
                }
            ],
            "id": "619e0329ae0f6226380475bc"
        },
        {
            "languages": [
                "sv",
                "sl",
                "sk",
                "ro",
                "pt",
                "pl",
                "nl",
                "mt",
                "lv",
                "lt",
                "it",
                "hu",
                "hr",
                "fi",
                "et",
                "es",
                "de",
                "el",
                "da",
                "cs",
                "bg",
                "fr",
                "en",
                "no"
            ],
            "rewrites": [],
            "baseUri": null,
            "strictParsing": true,
            "useRealTableNamespace": true,
            "validationRuleset": null,
            "identifierElementId": null,
            "identifierElementName": null,
            "reportTypeElementName": null,
            "reportTypeElementId": null,
            "labels": [
                {
                    "lang": "da",
                    "text": "ESEF 20190327",
                    "role": "standard",
                    "id": null
                },
                {
                    "lang": "en",
                    "text": "ESEF 20190327",
                    "role": "standard",
                    "id": null
                },
                {
                    "lang": "fr",
                    "text": "ESEF 20190327",
                    "role": "standard",
                    "id": null
                }
            ],
            "id": "5e1c9bfe82ac091b20eff353"
        },
        {
            "languages": [
                "sv",
                "sl",
                "sk",
                "ro",
                "pt",
                "pl",
                "nl",
                "mt",
                "lv",
                "lt",
                "it",
                "hu",
                "hr",
                "fi",
                "et",
                "es",
                "de",
                "el",
                "da",
                "cs",
                "bg",
                "fr",
                "en",
                "no",
                "is",
                "he"
            ],
            "rewrites": [],
            "baseUri": null,
            "strictParsing": true,
            "useRealTableNamespace": true,
            "validationRuleset": null,
            "identifierElementId": null,
            "identifierElementName": null,
            "reportTypeElementName": null,
            "reportTypeElementId": null,
            "labels": [
                {
                    "lang": "da",
                    "text": "ESEF 20200316",
                    "role": "standard",
                    "id": null
                },
                {
                    "lang": "en",
                    "text": "ESEF 20200316",
                    "role": "standard",
                    "id": null
                },
                {
                    "lang": "fr",
                    "text": "ESEF 20200316",
                    "role": "standard",
                    "id": null
                }
            ],
            "id": "615cb14e8309c47b18199cb2"
        },
        {
            "languages": [
                "sv",
                "sl",
                "sk",
                "ro",
                "pt",
                "pl",
                "nl",
                "mt",
                "lv",
                "lt",
                "it",
                "hu",
                "hr",
                "fi",
                "et",
                "es",
                "de",
                "el",
                "da",
                "cs",
                "bg",
                "fr",
                "en"
            ],
            "rewrites": [],
            "baseUri": null,
            "strictParsing": true,
            "useRealTableNamespace": true,
            "validationRuleset": null,
            "identifierElementId": null,
            "identifierElementName": null,
            "reportTypeElementName": null,
            "reportTypeElementId": null,
            "labels": [
                {
                    "lang": "en",
                    "text": "ESEF 20210324",
                    "role": "standard",
                    "id": null
                },
                {
                    "lang": "en",
                    "text": "UKSEF",
                    "role": "standard",
                    "id": null
                }
            ],
            "id": "62ed9f844b8c93c718054de6"
        },
        {
            "languages": [
                "sv",
                "sl",
                "sk",
                "ro",
                "pt",
                "pl",
                "nl",
                "mt",
                "lv",
                "lt",
                "it",
                "hu",
                "hr",
                "fi",
                "et",
                "es",
                "de",
                "el",
                "da",
                "cs",
                "bg",
                "fr",
                "en"
            ],
            "rewrites": [],
            "baseUri": null,
            "strictParsing": true,
            "useRealTableNamespace": true,
            "validationRuleset": null,
            "identifierElementId": null,
            "identifierElementName": null,
            "reportTypeElementName": null,
            "reportTypeElementId": null,
            "labels": [
                {
                    "lang": "da",
                    "text": "ESEF 20220324",
                    "role": "standard",
                    "id": null
                },
                {
                    "lang": "en",
                    "text": "ESEF 20220324",
                    "role": "standard",
                    "id": null
                },
                {
                    "lang": "fr",
                    "text": "ESEF 20220324",
                    "role": "standard",
                    "id": null
                },
                {
                    "lang": "en",
                    "text": "ESEF",
                    "role": "standard",
                    "id": null
                }
            ],
            "id": "643e3640ae0f62122c8d578c"
        },
        {
            "languages": [
                "en"
            ],
            "rewrites": [],
            "baseUri": null,
            "strictParsing": true,
            "useRealTableNamespace": true,
            "validationRuleset": null,
            "identifierElementId": null,
            "identifierElementName": null,
            "reportTypeElementName": null,
            "reportTypeElementId": null,
            "labels": [
                {
                    "lang": "en",
                    "text": "ESRS 0.9",
                    "role": "standard",
                    "id": null
                },
                {
                    "lang": "en",
                    "text": "ESRS",
                    "role": "standard",
                    "id": null
                }
            ],
            "id": "65aaa9a10610c50420450797"
        },
        {
            "languages": [
                "en",
                "cy"
            ],
            "rewrites": [],
            "baseUri": null,
            "strictParsing": true,
            "useRealTableNamespace": true,
            "validationRuleset": null,
            "identifierElementId": null,
            "identifierElementName": null,
            "reportTypeElementName": null,
            "reportTypeElementId": null,
            "labels": [
                {
                    "lang": "en",
                    "text": "ESRS 1.0",
                    "role": "standard",
                    "id": null
                },
                {
                    "lang": "cy",
                    "text": "ESRS 1.0",
                    "role": "standard",
                    "id": null
                }
            ],
            "id": "65aaa9a10610c50420450797"
        }
    ];
}
// Updated function to handle the new structure, including labels
export const getTaxonomiesFromLocalStorage = (): TaxonomyListProps[] => {
    const storedTaxonomies = localStorage.getItem("customObjectsSelectedTaxonomies");
    return storedTaxonomies ? JSON.parse(storedTaxonomies) : [];
};

export const combineTaxonomies = (defaultTaxonomies: TaxonomyListProps[], storedTaxonomies: TaxonomyListProps[]): TaxonomyListProps[] => {
    const combinedTaxonomies = defaultTaxonomies.map(defaultTaxonomy => {
        const storedTaxonomy = storedTaxonomies.find(taxonomy => taxonomy.id === defaultTaxonomy.id);
        if (storedTaxonomy) {
            return { ...defaultTaxonomy, isSelected: storedTaxonomy.isSelected };
        }
        return defaultTaxonomy;
    });

    storedTaxonomies.forEach(storedTaxonomy => {
        if (!combinedTaxonomies.find(taxonomy => taxonomy.id === storedTaxonomy.id)) {
            combinedTaxonomies.push(storedTaxonomy);
        }
    });

    return combinedTaxonomies;
};

export const getAllTaxonomies = (): TaxonomyListProps[] => {
    const defaultTaxonomies = getTaxonomies();
    defaultTaxonomies.forEach(taxonomy => taxonomy.isSelected = false);
    const storedTaxonomies = getTaxonomiesFromLocalStorage();
    return combineTaxonomies(defaultTaxonomies, storedTaxonomies);
};

export const handleTaxonomiesChange = (changedTaxonomies: TaxonomyListProps[]): void => {
    const storedTaxonomies = localStorage.getItem("customObjectsSelectedTaxonomies");
    let taxonomies: TaxonomyListProps[] = storedTaxonomies ? JSON.parse(storedTaxonomies) : [];

    const updatedTaxonomies = changedTaxonomies.reduce((acc, changedTaxonomy) => {
        const existingTaxonomyIndex = acc.findIndex(taxonomy => taxonomy.id === changedTaxonomy.id);

        if (changedTaxonomy.isSelected) {
            if (existingTaxonomyIndex === -1) {
                acc.push(changedTaxonomy);
            } else {
                acc[existingTaxonomyIndex] = changedTaxonomy;
            }
        } else {
            if (existingTaxonomyIndex !== -1) {
                acc.splice(existingTaxonomyIndex, 1);
            }
        }
        return acc;
    }, taxonomies);

    localStorage.setItem("customObjectsSelectedTaxonomies", JSON.stringify(updatedTaxonomies));
};
