import { t } from "@lingui/macro";
import { ApiOrganizationRequest, ApiOrganization } from "../api/types";
import {
  getUserEmailValidationError,
  getUserNameValidationError,
} from "./validateNewUser";

export const getOrganizationNameValidationError = (val: string): string => {
  if (val.length > 0 && val.length < 256) return "";
  return t`Value must be between 1 and 256 characters`;
};

export const getOrganizationDomainValidationError = (val: string): string => {
  if (val.length > 0 && val.length < 512) return "";
  return t`Value must be between 1 and 512 characters`;
};

export const getOrganizationRegNumberValidationError = (
  val: string
): string => {
  if (val.length < 33) return "";
  return t`Value must be between 1 and 32 characters`;
};

export const getOrganizationCountryValidationError = (val: string): string => {
  if (val.length === 2) return "";
  return t`Value must be 2 characters long`;
};

export const getOrganizationLeiCodeValidationError = (val: string): string => {
  if (val === "") return "";
  if (val.length !== 20) return t`Value must be exactly 20 characters long`;
  let num = 0;
  for (let x = 0; x < val.length; x++) {
    const char = val.charAt(x);
    const code = val.charCodeAt(x);
    if (!isNaN(parseInt(char))) {
      num = (num * 10 + code - 48) % 97;
      continue;
    }
    if (isNaN(parseInt(char))) {
      num = (num * 100 + code - 55) % 97;
      continue;
    }
  }
  return num === 1 ? "" : t`Invalid LEI Code`;
};

export const isOrganizationValid = (org: ApiOrganization | null): boolean => {
  if (!org) return false;
  return (
    getOrganizationNameValidationError(org.name).length === 0 &&
    getOrganizationRegNumberValidationError(org.registrationNumber || "")
      .length === 0 &&
    getOrganizationCountryValidationError(org.country).length === 0 &&
    getOrganizationLeiCodeValidationError(org.leiCode || "").length === 0
  );
};

export const isOrganizationRequestValid = (
  org: ApiOrganizationRequest | null,
  doNotUseDomain: boolean
): boolean => {
  if (!org) return false;

  const {
    name,
    registrationNumber,
    country,
    leiCode,
    adminUser,
    domain,
  } = org;

  const adminName = adminUser?.name;
  const emailAddress = adminUser?.emailAddress;

  return (
    !getOrganizationNameValidationError(name) &&
    !getOrganizationRegNumberValidationError(registrationNumber || "") &&
    !getOrganizationCountryValidationError(country) &&
    !getOrganizationLeiCodeValidationError(leiCode || "") &&
    (adminName === null || adminName === "" || !getUserNameValidationError(adminName)) &&
    (emailAddress === null || emailAddress === "" || !getUserEmailValidationError(emailAddress, domain || "")) &&
    (!doNotUseDomain || domain !== null)
  );
};