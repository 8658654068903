import { t, Trans } from "@lingui/macro";
import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  ListItemText,
  Paper,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";
import { ApiUserDetails } from "../../api/types";
import {
  reSendActivationEmail,
  toggleUserActivation,
  updateUserDetails,
} from "../../api/users";
import { useGetQuery } from "../../hooks/useGetQuery";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import { Loader } from "../loader";
import { useParams } from "react-router-dom";
import { ContainerWrapper } from "../containerWrapper";
import { LoadingButton } from "@mui/lab";
import AlertSnackbar from "../alertSnackbar";

export interface UserDetailsDialogProps {
  open: boolean;
  userId: string | undefined;
  onClose: (updateStatus: "none" | "success" | "error") => void;
  removeActivationToggleField?: boolean;
}

export const UserById = () => {
  let { id } = useParams();
  const [cacheBuster, setCacheBuster] = useState(crypto.randomUUID());
  const { data, error, loading, setData } = useGetQuery<ApiUserDetails>(
    `/api/users/${id}?cb=${cacheBuster}`,
    id === undefined
  );
  const [originalTitle, setOriginalTitle] = useState("");
  const [updating, setUpdating] = useState(false);
  const [showConsentDialog, setShowConsentDialog] = useState(false);

  const [loadingResendActivation, setLoadingResendActivation] = useState(false);

  const [alertMessage, setAlertMessage] = useState<
    | {
      message: string;
      severity: "error" | "warning" | "info" | "success" | undefined;
    }
    | undefined
  >(undefined);

  const handleSuccess = (text: string) => {
    setAlertMessage({
      message: text,
      severity: "success",
    });
    /* Wait 3 seconds */
  };

  const handleFieldValueChange = (
    key: keyof ApiUserDetails | string | undefined,
    value: string | boolean
  ) => {
    if (key) {
      if (!originalTitle) {
        setOriginalTitle(data?.name || "");
      }
      const updated = { ...data };
      (updated as any)[key] = value;
      setData(updated as ApiUserDetails);
    }
  };

  const handleToggleUserActivation = async () => {
    setUpdating(true);
    try {
      await toggleUserActivation(id as string, data?.isDeactiviated as boolean);
      setCacheBuster(crypto.randomUUID());
      setAlertMessage({
        severity: "success",
        message: t`User updated successfully`,
      });
    } catch (ex: any) {
      setUpdating(false);
      setAlertMessage({
        severity: "error",
        message: ex.response.data.IsTranslated
          ? ex.response.data.Exceptions[0]
          : t`Could not update user. Try again later`,
      });
    } finally {
      setShowConsentDialog(false);
      setUpdating(false);
    }
  };

  const handleClose = (updateStatus: "none" | "success" | "error") => {
    if (updateStatus === "success") {
      setCacheBuster(crypto.randomUUID());
    }
    setOriginalTitle("");
    setUpdating(false);
  };

  const handleSubmit = async () => {
    setUpdating(true);
    try {
      if (data) {
        await updateUserDetails(id as string, {
          name: data?.name,
          isAdministrator: data?.isAdministrator,
        });
      }
      handleClose("success");
      handleSuccess(t`User updated successfully`);
    } catch (ex: any) {
      setUpdating(false);
      setAlertMessage({
        severity: "error",
        message: ex.response.data.IsTranslated
          ? ex.response.data.Exceptions[0]
          : t`Could not update organization. Try again later`,
      });
    }
  };

  const handleResendActivationEmail = async () => {
    setLoadingResendActivation(true);
    try {
      if (data) {
        await reSendActivationEmail(data?.id);
        setAlertMessage({
          severity: "success",
          message: t`Activation email sent successfully`,
        });
      }
      setLoadingResendActivation(false);
    } catch (ex: any) {
      setLoadingResendActivation(false);
      setAlertMessage({
        severity: "error",
        message: ex.response.data.IsTranslated
          ? ex.response.data.Exceptions[0]
          : t`Could not resend activation email. Try again later`,
      });
    }
  };

  return (
    <>
      <ContainerWrapper>
        <Container maxWidth={false}>
          {loading && <Loader height={420} />}
          {!loading && error && (
            <Alert severity="error">
              <Trans>
                Could not load organization details. Please try again later
              </Trans>
            </Alert>
          )}
          {!loading && !error && data && (
            <Paper sx={{ p: 5 }}>
              <Container
                sx={{ mb: 10, display: "flex", justifyContent: "flex-start" }}
                maxWidth={false}
              >
                <Typography variant="h4">
                  {originalTitle || data.name}
                </Typography>
              </Container>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <ListItemText
                        primary={t`ID`}
                        primaryTypographyProps={{ fontWeight: 700 }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <ListItemText
                        title={data?.id}
                        secondary={data?.id}
                        secondaryTypographyProps={{
                          noWrap: true,
                          whiteSpace: "initial",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <ListItemText
                        primary={t`EMAIL`}
                        primaryTypographyProps={{ fontWeight: 700 }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <ListItemText
                        title={data?.emailAddress}
                        secondary={data?.emailAddress}
                        secondaryTypographyProps={{
                          noWrap: true,
                          whiteSpace: "initial",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <ListItemText
                        primary={t`NAME`}
                        primaryTypographyProps={{ fontWeight: 700 }}
                      />
                    </Grid>

                    <Grid item xs={8}>
                      <ListItemText>
                        <TextField
                          variant="standard"
                          value={data?.name}
                          onChange={(e) =>
                            handleFieldValueChange(
                              "name",
                              e.currentTarget.value
                            )
                          }
                        />
                      </ListItemText>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <ListItemText
                        primary={t`IS ADMIN`}
                        primaryTypographyProps={{ fontWeight: 700 }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <Switch
                        checked={data?.isAdministrator}
                        onChange={(e) =>
                          handleFieldValueChange(
                            "isAdministrator",
                            e.target.checked
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <ListItemText
                        primary={t`LOCKED OUT`}
                        primaryTypographyProps={{ fontWeight: 700 }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      {data?.isLockedOut && (
                        <Tooltip title={t`User is locked out`}>
                          <LockOutlinedIcon />
                        </Tooltip>
                      )}
                      {!data?.isLockedOut && (
                        <Tooltip title={t`User is not locked out`}>
                          <LockOpenOutlinedIcon />
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <ListItemText
                        primary={t`ORGANIZATION NAME`}
                        primaryTypographyProps={{ fontWeight: 700 }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <ListItemText
                        title={data?.organizationName}
                        secondary={
                          <Link
                            sx={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              window.open(
                                `../organizations/${data?.organizationId}`,
                                "_blank"
                              );
                            }}
                          >
                            {data?.organizationName}
                          </Link>
                        }
                        secondaryTypographyProps={{
                          noWrap: true,
                          whiteSpace: "initial",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <LoadingButton
                  size="large"
                  sx={{ mt: 5, width: 250, mr: 2 }}
                  variant="contained"
                  loading={loadingResendActivation}
                  onClick={() => handleResendActivationEmail()}
                >
                  Resend Activation Email
                </LoadingButton>
                {data?.isDeactiviated && (
                  <Button
                    size="large"
                    sx={{ mt: 5, width: 250 }}
                    variant="outlined"
                    onClick={() => setShowConsentDialog(true)}
                    disabled={updating}
                  >
                    <CheckIcon sx={{ fill: "green", pr: 0.5 }} />
                    Activate
                  </Button>
                )}
                {!data?.isDeactiviated && (
                  <Button
                    size="large"
                    sx={{
                      mt: 5,
                      width: 250,
                      backgroundColor: "white",
                      color: "black",
                      "&:hover": {
                        background: "#e6e6e6",
                      },
                    }}
                    variant="contained"
                    onClick={() => setShowConsentDialog(true)}
                    disabled={updating}
                  >
                    <CloseIcon
                      sx={{
                        fill: "red",
                        pr: 0.5,
                      }}
                    />
                    DeActivate
                  </Button>
                )}
                <Button
                  size="large"
                  sx={{ mt: 5, width: 250, ml: 2 }}
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={updating}
                >
                  {!updating && <Trans>Update</Trans>}
                  {updating && <CircularProgress size={25} />}
                </Button>
              </Stack>
            </Paper>
          )}
        </Container>
        <Dialog open={showConsentDialog}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Trans>You are about to </Trans>{" "}
              {`${data?.isDeactiviated ? t`activate` : t`deactivate`}`} this{" "}
              <Trans>user</Trans>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowConsentDialog(false)}>No</Button>
            <Button onClick={handleToggleUserActivation}>Yes</Button>
          </DialogActions>
        </Dialog>
      </ContainerWrapper>
      <AlertSnackbar
        alertMessage={alertMessage}
        updateAlertStatus={setAlertMessage}
      />
    </>
  );
};
