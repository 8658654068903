import { t } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { validationTable } from "../../api/types";
import { DataTableColumn } from "../dataTable";
import moment from "moment";

export function validationTableColumns(): DataTableColumn<validationTable>[] {
  return [
    {
      header: {
        id: "code",
        label: t(i18n)`Code`,
      },
      key: "code",
      width: 250,
    },
    {
      header: {
        id: "createdAt",
        label: t(i18n)`Created At`,
      },
      key: "createdAt",
      valueFormatter: (row) =>
        moment(row.createdAt.toString()).format("DD/MM/YYYY HH:mm:ss"),
      width: 50,
    },
    {
      header: {
        id: "explanation",
        label: t(i18n)`Explanation`,
      },
      key: "explanation",
      width: 200,
    },
    {
      header: {
        id: "link",
        label: t(i18n)`Link`,
      },
      key: "link",
      width: 500,
    },
    {
      header: {
        id: "severityOverride",
        label: t(i18n)`SeverityOverride`,
      },
      key: "severityOverride",
    },
  ];
}