import { PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";
import tokenStorageHelper from "../helpers/tokenStorage";
import { intendedPathLocalStorageKey } from "../helpers/constants";

const Protected = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  if (!tokenStorageHelper.isValid) {
    localStorage.setItem(
      intendedPathLocalStorageKey,
      location.pathname + location.search
    );
    return (
      <>
        <Navigate to="/login" replace={true} />
      </>
    );
  }

  return <>{children}</>;
};

export default Protected;
