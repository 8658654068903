import { t } from "@lingui/macro";
import { permissionSchemeTable } from "../../api/types";
import { DataTableColumn } from "../dataTable";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

export const permissionSchemeColumns =
  (): DataTableColumn<permissionSchemeTable>[] => [
    {
      header: {
        id: "Id",
        label: t`Id`,
      },
      key: "id",
      width: 350,
    },
    {
      header: {
        id: "createdAt",
        label: t`Created At`,
      },
      key: "createdAt",
      valueFormatter: (row: permissionSchemeTable) =>
        moment(row.createdAt.toString()).format("DD/MM/YYYY HH:mm:ss"),
      width: 150,
    },
    {
      header: {
        id: "name",
        label: t`Name`,
      },
      key: "name",
      width: 200,
    },
    {
      header: {
        id: "PriceInEuro",
        label: t`Price`,
      },
      key: "priceInEuro",
      valueFormatter: (row: permissionSchemeTable) => `${row.priceInEuro} €`,
      width: 250,
    },
    {
      header: {
        id: "isActive",
        label: t`Is Active`,
      },
      key: "isActive",
      valueFormatter: (row) =>
        row.isActive ? (
          <CheckIcon color="success" />
        ) : (
          <CloseIcon color="error" />
        ),
    },
  ];
