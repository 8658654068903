import { t } from "@lingui/macro"
import { ColumnProps, dataMatchingToNavBar } from "../../api/types"

export const DimensionValue = () => {
    return [
        {
            dimensionValue: {
                taxonomyValue: {
                    dimensionReference: {
                        namespaceAbbreviation: 'NS',
                        name: 'Dimension1',
                        description: 'Description of Dimension1',
                    },
                    dimensionValueReference: {
                        namespaceAbbreviation: 'NS',
                        name: 'Value1',
                        description: 'Description of Value1',
                    },
                },
                customDimensionValue: null,
                onlyWhenConsolidated: null,
                isCustom: false,
                dimensionName: 'Dimension1',
                valueName: 'Value1',
                name: 'Dimension1_Value1',
            },
            typedMemberValue: null,
        },
    ];
}

export const tagsTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "tags",
        details: {
            navBarName: t`Details`,
            label: { key: "label", name: t`label`, fieldType: "text", value: null },
            company: { key: "company", name: t`Company`, fieldType: "searchSelect", value: null, options: ["Parseport", "PwC"] },
            requestGlobal: { key: "requestGlobal", name: t`Request Global`, fieldType: "checkbox", value: null },
            tagGroup: {
                key: "tagGroup", name: t`Tag Group`, fieldType: "tagGroup", value: null, options: [
                    {
                        "name": "Assets",
                        "id": "5e692ba94d626d13202ccb64"
                    },
                    {
                        "name": "BalanceSheet",
                        "id": "66a181c17a6bd5a793d894ac"
                    },
                    {
                        "name": "CashFlowsStatement",
                        "id": "5ae01cac82ac090a3cb8465f"
                    },
                    {
                        "name": "ComprehensiveIncomeBeforeTax",
                        "id": "5c9c6a3a072d762160ad532e"
                    },
                    {
                        "name": "ComprehensiveIncomeNetOfTax",
                        "id": "5e5622c64d626d19007d3a90"
                    },
                    {
                        "name": "CurrentAssets",
                        "id": "5ae01cac82ac090a3cb84660"
                    },
                    {
                        "name": "CurrentLiabilities",
                        "id": "5dd293774d626d21acca1745"
                    },
                    {
                        "name": "Debt",
                        "id": "5ae01cac82ac090a3cb84661"
                    },
                    {
                        "name": "Disclosures",
                        "id": "5ae01cac82ac090a3cb84672"
                    },
                    {
                        "name": "DST",
                        "id": "5ae01cac82ac090a3cb84658"
                    },
                    {
                        "name": "EmployeeBenefitsExpense",
                        "id": "5ae01cac82ac090a3cb84655"
                    },
                    {
                        "name": "Equity",
                        "id": "5ae01cac82ac090a3cb84662"
                    },
                    {
                        "name": "FinancingActivities",
                        "id": "5ae01cac82ac090a3cb84663"
                    },
                ]
            },
            dateSpectrum: { key: "dateSpectrum", name: t`Date Spectrum`, fieldType: "dateSpectrum", value: null, options: ["end", "previous", "standard", "start", "total"] },
            isLabel: { key: "isLabel", name: t`Is Label`, fieldType: "checkbox", value: null },
            isSetting: { key: "isSetting", name: t`Is Setting`, fieldType: "checkbox", value: null },
            mergePresentation: { key: "mergePresentation", name: t`Merge Presentation`, fieldType: "checkbox", value: null },
            isFootnote: { key: "isFootnote", name: t`Is Footnote`, fieldType: "checkbox", value: null, },
            isArea: { key: "isArea", name: t`Is Area`, fieldType: "checkbox", value: null, },
            language: { key: "language", name: t`Language`, fieldType: "searchSelect", value: null, options: ["de", "dk", "US", "AUS"] },
            context: { key: "context", name: t`Context`, fieldType: "searchSelect", value: null, options: ["test", "test2"] },
        },
        tableTag: {
            navBarName: t`Table Tag`,
            isTableTag: { key: "isTableTag", name: t`Is Table Tag`, fieldType: "checkbox", value: null },
            isSetting: { key: "isSetting", name: t`Is Setting`, fieldType: "checkbox", value: null },
            strictParsing: { key: "strictParsing", name: t`Strict Parsing`, fieldType: "checkbox", value: null },
        },
        dimensionValues: {
            navBarName: t`Dimension Values`,
            isDataTable: true,
            dataTable: {
                columns: [
                    { header: { id: "dimensionValue", label: t`Dimension Values` }, width: 400, key: "dimensionValue" },
                    { header: { id: "dimension", label: t`Dimension` }, width: 250, key: "dimension" },
                    { header: { id: "typedMemberValue", label: t`Typed Member Value` }, width: 300, key: "typedMemberValue" },
                    { header: { id: "consolidated", label: t`Consolidated` }, width: 200, key: "consolidated" }
                ],
                data: []
            }
        },
        extension: {
            navBarName: t`Extension Hierarchy`,
            isDataTable: true,
            customTaxonomyGroup: { key: "customTaxonomyGroup", name: t`Custom Taxonomy Group`, fieldType: "text", value: null },
            dataTable: {
                columns: [
                    { header: { id: "dimensionValue", label: t`Dimension Values` }, width: 400, key: "dimensionValue" },
                    { header: { id: "dimension", label: t`Dimension` }, width: 250, key: "dimension" },
                    { header: { id: "typedMemberValue", label: t`Typed Member Value` }, width: 300, key: "typedMemberValue" },
                    { header: { id: "consolidated", label: t`Consolidated` }, width: 200, key: "consolidated" }
                ],
                data: [],
            },
        },
        extensionStructure: {
            navBarName: t`Extension Structure`,
            isDataTable: true,
            customTaxonomyGroup: { key: "customTaxonomyGroup", name: t`Custom Taxonomy Group`, fieldType: "text", value: null },
            dataTable: {
                columns: [
                    { header: { id: "dimensionValue", label: t`Dimension Values` }, width: 400, key: "dimensionValue" },
                    { header: { id: "dimension", label: t`Dimension` }, width: 250, key: "dimension" },
                    { header: { id: "typedMemberValue", label: t`Typed Member Value` }, width: 300, key: "typedMemberValue" },
                    { header: { id: "consolidated", label: t`Consolidated` }, width: 200, key: "consolidated" }
                ],
                data: [],
            },
        },
        taxonomies: [],
    }
}

export const headerTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "header",
        details: {
            navBarName: t`Details`,
            label: { key: "label", name: t`Label`, fieldType: "text", value: null, placeholder: "" },
            company: { key: "company", name: t`Company`, fieldType: "text", value: null, placeholder: "" },
            requestGlobal: { key: "requestGlobal", name: t`Request Global`, fieldType: "checkbox", value: null },
            element: { key: "element", name: t`Element`, fieldType: "element", value: null, endpointUrl: "/test/element" },
            tagGroup: { key: "tagGroup", name: t`Tag Group`, fieldType: "select", value: null, options: ["Tag Group 1", "Tag Group 2", "Tag Group 3"] },
            dateSpectrum: { key: "dateSpectrum", name: t`Date Spectrum`, fieldType: "select", value: null, options: ["Daily", "Weekly", "Monthly", "Yearly"] },
            invertSign: { key: "invertSign", name: t`Invert Sign`, fieldType: "checkbox", value: null },
            skipTagging: { key: "skipTagging", name: t`Skip Tagging`, fieldType: "checkbox", value: null },
            isLabelFootnote: { key: "isLabelFootnote", name: t`Is Label Footnote`, fieldType: "checkbox", value: null },
            language: { key: "language", name: t`Language`, fieldType: "searchSelect", value: null, options: ["English", "Spanish", "French"] },
            ignoreInPresentation: { key: "ignoreInPresentation", name: t`Ignore In Presentation`, fieldType: "checkbox", value: null },
        },
        overrides: {
            navBarName: t`Overrides`,
            context: { key: "context", name: t`Context`, fieldType: "searchSelect", value: null, options: ["Context 1", "Context 2", "Context 3"] },
            currency: { key: "currency", name: t`Currency`, fieldType: "text", value: null, options: ["USD", "EUR", "GBP"] },
            scale: { key: "scale", name: t`Scale`, fieldType: "text", value: null, options: ["Scale 1", "Scale 2", "Scale 3"] },
            valueLanguage: { key: "valueLanguage", name: t`Value Language`, fieldType: "searchSelect", value: null, options: ["English", "Spanish", "French"] },
            dateOverride: { key: "dateOverride", name: t`Date Override`, fieldType: "text", value: null, placeholder: "" },
        },
        searchHint: {
            navBarName: t`Search Hint`,
            searchDirection: { key: "searchDirection", name: t`Search Direction`, fieldType: "select", value: null, options: ["Search Direction 1", "Search Direction 2", "Search Direction 3"] },
        },
        dimensionValues: {
            navBarName: t`Dimension Values`,
            isDataTable: true,
            dataTable: {
                columns: [
                    { header: { id: "dimensionValue", label: t`Dimension Values` }, width: 400, key: "dimensionValue" },
                    { header: { id: "dimension", label: t`Dimension` }, width: 250, key: "dimension" },
                    { header: { id: "typedMemberValue", label: t`Typed Member Value` }, width: 300, key: "typedMemberValue" },
                    { header: { id: "consolidated", label: t`Consolidated` }, width: 200, key: "consolidated" }
                ],
                data: []
            }
        },
        tuple: {
            navBarName: t`Tuple`,
            isTuple: { key: "isTuple", name: t`Is Tuple`, fieldType: "checkbox", value: null },
            element: { key: "element", name: t`Element`, fieldType: "element", value: null, endpointUrl: "/test/element" },
        },
        taxonomies: [],
    };
};


export const labelsTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "labels",
        details: {
            navBarName: t`Details`,
            label: { key: "label", name: t`Label`, fieldType: "text", value: null },
            company: { key: "company", name: t`Company`, fieldType: "text", value: null, options: ["Parseport", "Pwc", "Deloitte"] },
            requestGlobal: { key: "requestGlobal", name: t`Request Global`, fieldType: "checkbox", value: null },
            element: { key: "element", name: t`Element`, fieldType: "element", value: null, endpointUrl: "/test/element" },
            nameElement: { key: "nameElement", name: t`Name Element`, fieldType: "text", value: null },
            tagGroup: {
                key: "tagGroup", name: t`Tag Group`, fieldType: "tagGroup", value: null, options: [
                    {
                        "name": "Assets",
                        "id": "5e692ba94d626d13202ccb64"
                    },
                    {
                        "name": "BalanceSheet",
                        "id": "66a181c17a6bd5a793d894ac"
                    },
                    {
                        "name": "CashFlowsStatement",
                        "id": "5ae01cac82ac090a3cb8465f"
                    },
                    {
                        "name": "ComprehensiveIncomeBeforeTax",
                        "id": "5c9c6a3a072d762160ad532e"
                    },
                    {
                        "name": "ComprehensiveIncomeNetOfTax",
                        "id": "5e5622c64d626d19007d3a90"
                    },
                    {
                        "name": "CurrentAssets",
                        "id": "5ae01cac82ac090a3cb84660"
                    },
                    {
                        "name": "CurrentLiabilities",
                        "id": "5dd293774d626d21acca1745"
                    },
                    {
                        "name": "Debt",
                        "id": "5ae01cac82ac090a3cb84661"
                    },
                    {
                        "name": "Disclosures",
                        "id": "5ae01cac82ac090a3cb84672"
                    },
                    {
                        "name": "DST",
                        "id": "5ae01cac82ac090a3cb84658"
                    },
                    {
                        "name": "EmployeeBenefitsExpense",
                        "id": "5ae01cac82ac090a3cb84655"
                    },
                    {
                        "name": "Equity",
                        "id": "5ae01cac82ac090a3cb84662"
                    },
                    {
                        "name": "FinancingActivities",
                        "id": "5ae01cac82ac090a3cb84663"
                    },
                ]
            },
            dateSpectrum: {
                key: "dateSpectrum", name: t`Date Spectrum`, fieldType: "select", value: null, options: ["end", "previous", "standard", "start", "total"]
            },
            invertSign: { key: "invertSign", name: t`Invert Sign`, fieldType: "checkbox", value: null },
            language: { key: "language", name: t`Language`, fieldType: "text", value: null, },
            ignoreInPresentation: { key: "ignoreInPresentation", name: t`Ignore In Presentation`, fieldType: "checkbox", value: null },
        },
        overrides: {
            navBarName: t`Overrides`,
            context: { key: "context", name: t`Context`, fieldType: "searchSelect", value: null, options: ["Context 1", "Context 2", "Context 3"] },
            currency: { key: "currency", name: t`Currency`, fieldType: "text", value: null, options: ["USD", "EUR", "GBP"] },
            scale: { key: "scale", name: t`Scale`, fieldType: "select", value: null, options: ["Scale 1", "Scale 2", "Scale 3"] },
            valueLanguage: { key: "valueLanguage", name: t`Value Language`, fieldType: "select", value: null, options: ["English", "Spanish", "French"] },
            transformation: { key: "transformation", name: t`Transformation`, fieldType: "text", value: null },
            before: { key: "before", name: t`Before`, fieldType: "text", value: null },
            merge: { key: "merge", name: t`Merge`, fieldType: "checkbox", value: null },
            allowHTML: { key: "allowHTML", name: t`Allow HTML`, fieldType: "checkbox", value: null },
            skipSpace: { key: "skipSpace", name: t`Skip Space`, fieldType: "checkbox", value: null },
            dateOverride: { key: "dateOverride", name: t`Date Override`, fieldType: "text", value: null },
        },
        taxonomies: [],
        dimensionValues: {
            navBarName: t`Dimension Values`,
            isDataTable: true,
            dataTable: {
                columns: [
                    { header: { id: "dimensionValue", label: t`Dimension Values` }, width: 400, key: "dimensionValue" },
                    { header: { id: "dimension", label: t`Dimension` }, width: 250, key: "dimension" },
                    { header: { id: "typedMemberValue", label: t`Typed Member Value` }, width: 300, key: "typedMemberValue" },
                    { header: { id: "consolidated", label: t`Consolidated` }, width: 200, key: "consolidated" }
                ],
                data: []
            }
        }
    };
};

export const customDimensionsTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "customDimensions",
        details: {
            navBarName: t`Details`,
            customDimensionName: { key: "customDimensionName", name: t`Custom Dimension Name`, fieldType: "text", value: null },
            company: { key: "company", name: t`Company`, fieldType: "text", value: null },
            requestGlobal: { key: "requestGlobal", name: t`Request Global`, fieldType: "checkbox", value: null }
        },
        dimensionLabels: {
            navBarName: t`Dimension Labels`,
            language: { key: "language", name: t`Language`, fieldType: "select", value: null, options: ["English", "Spanish", "French"] },
            label: { key: "label", name: t`Label`, fieldType: "text", value: null }
        },
        taxonomies: [],
        customLabels: {
            navBarName: t`Custom Labels`,
            isDataTable: true,
            dataTable: { columns: [], data: [] }
        },
        customHeaders: {
            navBarName: t`Custom Headers`,
            isDataTable: true,
            dataTable: { columns: [], data: [] }
        },
        customTags: {
            navBarName: t`Custom Tags`,
            isDataTable: true,
            dataTable: { columns: [], data: [] }
        },
        contexts: {
            navBarName: t`Contexts`,
            isDataTable: true,
            dataTable: { columns: [], data: [] }
        }
    };
}

export const dimensionValueTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "dimensionValue",
        details: {
            navBarName: t`Details`,
            element: { key: "element", name: t`Element`, fieldType: "element", value: null, endpointUrl: "/test/element" },
            customValue: { key: "customValue", name: t`Custom Value`, fieldType: "text", value: null },
            company: { key: "company", name: t`Company`, fieldType: "text", value: null },
            requestGlobal: { key: "requestGlobal", name: t`Request Global`, fieldType: "checkbox", value: null },
            isTypedMember: { key: "isTypedMember", name: t`Is Typed Member`, fieldType: "checkbox", value: null },
            isDefaultMember: { key: "isDefaultMember", name: t`Is Default Member`, fieldType: "checkbox", value: null },
            parentMember: { key: "parentMember", name: t`Parent Member`, fieldType: "text", value: null }
        },
        taxonomies: [],
        childDimensionValues: {
            navBarName: t`Child Dimension Values`,
            isDataTable: true,
            dataTable: { columns: [], data: [] }
        },
        customLabels: {
            navBarName: t`Custom Labels`,
            isDataTable: true,
            dataTable: { columns: [], data: [] }
        },
        customHeaders: {
            navBarName: t`Custom Headers`,
            isDataTable: true,
            dataTable: { columns: [], data: [] }
        },
        customTags: {
            navBarName: t`Custom Tags`,
            isDataTable: true,
            dataTable: { columns: [], data: [] }
        },
        contexts: {
            navBarName: t`Contexts`,
            isDataTable: true,
            dataTable: { columns: [], data: [] }
        },
        customDimensionValues: {
            navBarName: t`Custom Dimension Values`,
            isDataTable: true,
            dataTable: { columns: [], data: [] }
        }
    };
}


export const extensionElementsTemplate: () => dataMatchingToNavBar = () => {
    return {
        isCreating: true,
        id: "extensionElements",
        details: {
            navBarName: t`Details`,
            element: { key: "element", name: t`Element`, fieldType: "text", value: null, endpointUrl: "/test/element" },
            company: { key: "company", name: t`Company`, fieldType: "text", value: null },
            requestGlobal: { key: "requestGlobal", name: t`Request Global`, fieldType: "checkbox", value: null },
            elementDataType: { key: "elementDataType", name: t`Element Data Type`, fieldType: "select", value: null, options: ["String", "Number", "Boolean"] },
            isInstant: { key: "isInstant", name: t`Is Instant`, fieldType: "checkbox", value: null },
            invertSign: { key: "invertSign", name: t`Invert Sign`, fieldType: "checkbox", value: null },
            isCredit: { key: "isCredit", name: t`Is Credit`, fieldType: "checkbox", value: null },
            isAbstract: { key: "isAbstract", name: t`Is Abstract`, fieldType: "checkbox", value: null },
            parent: { key: "parent", name: t`Parent`, fieldType: "text", value: null }
        },
        childElements: {
            navBarName: t`Child Elements`,
            isDataTable: true,
            dataTable: { columns: [], data: [] }
        },
        labels: {
            navBarName: t`Labels`,
            isDataTable: true,
            dataTable: { columns: [], data: [] }
        },
        taxonomies: [],
        documentation: {
            navBarName: t`Documentation`,
            isDataTable: true,
            dataTable: { columns: [], data: [] }
        },
        usage: {
            navBarName: t`Usage`,
            isDataTable: true,
            dataTable: { columns: [], data: [] }
        },
        customLabels: {
            navBarName: t`Custom Labels`,
            isDataTable: true,
            dataTable: { columns: [], data: [] }
        },
        customHeaders: {
            navBarName: t`Custom Headers`,
            isDataTable: true,
            dataTable: { columns: [], data: [] }
        }
    };
}

export const settingsTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "settings",
        details: {
            navBarName: t`Details`,
            label: { key: "name", name: t`Name`, fieldType: "select", value: null, options: ["Name 1", "Name 2", "Name 3"] },
            required: { key: "required", name: t`Required`, fieldType: "checkbox", value: null }
        },
        labels: {
            navBarName: t`Labels`,
            isDataTable: true,
            dataTable: {
                columns: [
                    { header: { id: "label", label: t`Label` }, width: 400, key: "label" },
                    { header: { id: "description", label: t`Description` }, width: 250, key: "description" }
                ],
                data: []
            }
        },
        taxonomies: [],
    };
}


export const ContextSettingsTemplate: () => dataMatchingToNavBar = () => {
    return {
        id: "contextSettings",
        details: {
            navBarName: t`Details`,
            context: { key: "context", name: t`Context`, fieldType: "text", value: null },
            period: { key: "period", name: t`Period`, fieldType: "select", value: null, options: ["Period 1", "Period 2", "Period 3"] }
        },
        dimensionValues: {
            navBarName: t`Dimension Values`,
            isDataTable: true,
            dataTable: { columns: [], data: [] }
        },
        taxonomies: [],
    };
}


export const Tagscolumns: () => ColumnProps[] = () => {
    return [
        {
            header: {
                id: "label",
                label: t`Label`,
                optionsHeaderText: t`Search Label`,
                shouldBeFiltered: true,
                inputType: "text",
            },
            key: "label",
            width: 200,
        },
        {
            header: {
                id: "tagGroup",
                label: t`Tag Groups`,
                shouldBeFiltered: true,
                optionsHeaderText: t`Select Tag Group`,
                endpointUrl: "/test",
                inputType: "select",
            },
            key: "tagGroup",
            width: 100,
        },
        {
            header: {
                id: "company",
                label: t`Companies`,
                shouldBeFiltered: true,
                optionsHeaderText: t`Search Company`,
                inputType: "text",
            },
            key: "company",
            width: 100,
        },
        {
            header: {
                id: "dateSpectrum",
                label: t`Date Spectrum`,
                shouldBeFiltered: true,
                optionsHeaderText: t`Select Date Spectrum`,
                inputType: "select",
                endpointUrl: "/test",
            },
            key: "dateSpectrum",
            width: 100,
        },
        {
            header: {
                id: "isLabel",
                label: t`Is Label`,
                shouldBeFiltered: true,
                inputType: "select",
            },
            key: "isLabel",
            width: 50,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
                shouldBeFiltered: false,
                optionsHeaderText: t`Select Taxonomy`,
                inputType: "select",
            },
            key: "taxonomies",
            width: 100,
            shouldWrapVertically: true,
        },
        {
            header: {
                id: "dimensionValues",
                label: t`Dimension Values`,
                shouldBeFiltered: false,
            },
            key: "dimensionValues",
            width: 100,
            shouldWrapVertically: true,
        },
    ];
};


export const HeadersColumns: () => ColumnProps[] = () => {
    return [
        {
            header: {
                id: "label",
                label: t`Label`,
                //shouldBeFiltered: true,
            },
            key: "label",
            width: 200,
        },
        {
            header: {
                id: "element",
                label: t`Element`,
                shouldNotBeFiltered: true,
            },
            key: "element",
            width: 100,
        },
        {
            header: {
                id: "company",
                label: t`Company`,
                shouldNotBeFiltered: true,
            },
            key: "company",
            width: 100,
        },
        {
            header: {
                id: "tagGroup",
                label: t`Tag Group`,
                options: ["Tag Group 1", "Tag Group 2", "Tag Group 3"],
                optionsHeaderText: t`Select Tag Group`,
                shouldNotBeFiltered: true,
            },
            key: "tagGroup",
            width: 100,
        },
        {
            header: {
                id: "dateSpectrum",
                label: t`Date Spectrum`,
                options: ["Daily", "Weekly", "Monthly", "Yearly"],
                optionsHeaderText: t`Select Date Spectrum`,
                shouldNotBeFiltered: true,
            },
            key: "dateSpectrum",
            width: 100,
        },
        {
            header: {
                id: "language",
                label: t`Language`,
                options: ["English", "Spanish", "French"],
                optionsHeaderText: t`Select Language`,
                shouldNotBeFiltered: true,
            },
            key: "language",
            width: 100,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
                shouldNotBeFiltered: true,
            },
            key: "taxonomies",
            width: 100,
        },
        {
            header: {
                id: "dimensionValues",
                label: t`Dimension Values`,
                shouldNotBeFiltered: true,
            },
            key: "dimensionValues",
            width: 100,
        },
        {
            header: {
                id: "additionalElements",
                label: t`Additional Elements`,
                shouldNotBeFiltered: true,
            },
            key: "additionalElements",
            width: 100,
        }
    ];
}

export const labelsColumns: () => ColumnProps[] = () => {
    return [
        {
            header: {
                id: "label",
                label: t`Label`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Search Label`,
            },
            key: "label",
            width: 250,
        },
        {
            header: {
                id: "element",
                label: t`Element`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Search Element`,
            },
            key: "element",
            width: 250,
        },
        {
            header: {
                id: "company",
                label: t`Company`,
                shouldBeFiltered: true,
                inputType: "text",
                optionsHeaderText: t`Search Company`,
            },
            key: "company",
            width: 100,
        },
        {
            header: {
                id: "tagGroup",
                label: t`Tag Group`,
                shouldBeFiltered: true,
                inputType: "select",
                optionsHeaderText: t`Select Tag Group`,
                endpointUrl: "/test",
            },
            key: "tagGroup",
            width: 200,
        },
        {
            header: {
                id: "dateSpectrum",
                label: t`Date Spectrum`,
                shouldBeFiltered: true,
                inputType: "select",
                optionsHeaderText: t`Select Date Spectrum`,
                endpointUrl: "/test",
            },
            key: "dateSpectrum",
            width: 100,
        },
        {
            header: {
                id: "language",
                label: t`Language`,
                shouldBeFiltered: true,
                inputType: "select",
                optionsHeaderText: t`Select Language`,
                endpointUrl: "/test",
            },
            key: "language",
            width: 100,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
            },
            key: "taxonomies",
            width: 100,
        },
        {
            header: {
                id: "dimensionValues",
                label: t`dimensionValues`,
            },
            key: "dimensionValues",
            width: 50,
        },
        {
            header: {
                id: "additionalElements",
                label: t`Additional Elements`,
            },
            key: "additionalElements",
            width: 50,
        }
    ]
}

export const customDimensionColumns: () => ColumnProps[] = () => {
    return [
        {
            header: {
                id: "dimension",
                label: t`Dimension`,
            },
            key: "dimension",
            width: 600,
        },
        {
            header: {
                id: "company",
                label: t`Company`,
            },
            key: "company",
            width: 400,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
            },
            key: "taxonomies",
            width: 200,
        },
    ]
}
export const dimensionValuesColumns: () => ColumnProps[] = () => {
    return [
        {
            header: {
                id: "dimension",
                label: t`Dimension`,
            },
            key: "dimension",
            width: 200,
        },
        {
            header: {
                id: "value",
                label: t`Value`,
            },
            key: "value",
            width: 200,
        },
        {
            header: {
                id: "isTyped",
                label: t`Is Typed`,
            },
            key: "isTyped",
            width: 100,
        },
        {
            header: {
                id: "isDefault",
                label: t`Is Default`,
            },
            key: "isDefault",
            width: 200,
        },
        {
            header: {
                id: "company",
                label: t`Company`,
            },
            key: "company",
            width: 100,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
            },
            key: "taxonomies",
            width: 100,
        },
        {
            header: {
                id: "wider",
                label: t`Wider`,
            },
            key: "wider",
            width: 200,
        },
        {
            header: {
                id: "narrower",
                label: t`Narrower`,
            },
            key: "narrower",
            width: 200,
        },
    ]
}
export const extensionElementsColumns: () => ColumnProps[] = () => {
    return [
        {
            header: {
                id: "elementName",
                label: t`Element Name`,
            },
            key: "elementName",
            width: 200,
        },
        {
            header: {
                id: "company",
                label: t`Company`,
            },
            key: "company",
            width: 100,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
            },
            key: "taxonomies",
            width: 100,
        },
        {
            header: {
                id: "wider",
                label: t`Wider`,
            },
            key: "wider",
            width: 200,
        },
        {
            header: {
                id: "narrower",
                label: t`Narrower`,
            },
            key: "narrower",
            width: 200,
        },
    ]
}

export const settingsColumns: () => ColumnProps[] = () => {
    return [
        {
            header: {
                id: "setting",
                label: t`Setting`,
                options: ["Setting 1", "Setting 2", "Setting 3"],
                optionsHeaderText: t`Select Setting`
            },
            key: "setting",
            width: 200,
        },
        {
            header: {
                id: "required",
                label: t`Required`,
                options: ["true", "false"],
                optionsHeaderText: t`Select Required`
            },
            key: "required",
            width: 100,
        },
        {
            header: {
                id: "labels",
                label: t`Labels`,
            },
            key: "labels",
            width: 200,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
            },
            key: "taxonomies",
            width: 100,
        },
    ]
}

export const contextSettingsColumns: () => ColumnProps[] = () => {
    return [
        {
            header: {
                id: "context",
                label: t`Context`,
            },
            key: "context",
            width: 200,
        },
        {
            header: {
                id: "period",
                label: t`Period`,
            },
            key: "period",
            width: 200,
        },
        {
            header: {
                id: "taxonomies",
                label: t`Taxonomies`,
            },
            key: "taxonomies",
            width: 100,
        },
        {
            header: {
                id: "dimensionValues",
                label: t`Dimension Values`,
            },
            key: "dimensionValues",
            width: 200,
        },
    ]
}

export const globalRequestColumns: () => ColumnProps[] = () => {
    return [
        {
            header: {
                id: "label",
                label: t`Label`,
            },
            key: "label",
            width: 200,
        },
        {
            header: {
                id: "entity",
                label: t`Entity`,
                options: ["Entity 1", "Entity 2", "Entity 3"],
                optionsHeaderText: t`Select Entity`
            },
            key: "entity",
            width: 200,
        },
        {
            header: {
                id: "company",
                label: t`Company`,
            },
            key: "company",
            width: 200,
        },
        {
            header: {
                id: "requster",
                label: t`Requester`,
                options: ["Requester 1", "Requester 2", "Requester 3"],
                optionsHeaderText: t`Select Requester`
            },
            key: "requester",
            width: 200,
        },
    ]
}

export const tempoDataSaved = [
    {
        "DateSpectrum": {
            "Name": "standard",
            "PreferredLabelRole": "http://www.xbrl.org/2003/role/label",
            "IsDeleted": 0,
            "CreatedAt": "2024-06-05T08:25:46.537057+00:00",
            "Id": "e79c030f-2f38-4ba3-ada7-00769501326f"
        },
        "TagGroup": {
            "Name": "StatementOfChangesInEquity",
            "IsDeleted": 0,
            "CreatedAt": "2024-06-05T10:08:44+00:00",
            "Id": "97690933-2323-11ef-825f-0242ac120006"
        },
        "Label": "Statement of changes in equity period 2, previously stated",
        "Language": "en",
        "IsLabel": false,
        "IsFootnote": false,
        "IsSettingTag": false,
        "MergePresentation": true,
        "IsArea": false,
        "DimensionValueReferences": [
            {
                "TaxonomyDimensionReferenceId": "f750a4fe-e512-484e-b54d-2474570bc222",
                "TaxonomyDimensionReference": {
                    "Name": "RetrospectiveApplicationAndRetrospectiveRestatementAxis",
                    "NameNormalized": "RETROSPECTIVEAPPLICATIONANDRETROSPECTIVERESTATEMENTAXIS",
                    "NamespaceAbbreviation": "ifrs-full",
                    "Description": "RetrospectiveApplicationAndRetrospectiveRestatementAxis",
                    "IsDeleted": 0,
                    "CreatedAt": "2024-06-04T09:26:28.446515+00:00",
                    "Id": "f750a4fe-e512-484e-b54d-2474570bc222"
                },
                "TaxonomyDimensionValueReferenceId": "8a8e1fcb-8798-4654-a826-033e3e1b2a71",
                "TaxonomyDimensionValueReference": {
                    "Name": "PreviouslyStatedMember",
                    "NameNormalized": "PREVIOUSLYSTATEDMEMBER",
                    "NamespaceAbbreviation": "ifrs-full",
                    "Description": "PreviouslyStatedMember",
                    "IsDeleted": 0,
                    "CreatedAt": "2024-06-04T09:27:15.791003+00:00",
                    "Id": "8a8e1fcb-8798-4654-a826-033e3e1b2a71"
                },
                "IsDeleted": 0,
                "CreatedAt": "2024-06-05T08:25:46.537666+00:00",
                "Id": "2d9a8c04-5270-4507-8fa4-6ec0b72d1b59"
            }
        ],
        "HierarchyElementReferences": [
            {
                "Name": "StatementOfChangesInEquityLineItems",
                "NameNormalized": "STATEMENTOFCHANGESINEQUITYLINEITEMS",
                "NamespaceAbbreviation": "ifrs-full",
                "Description": "StatementOfChangesInEquityLineItems",
                "IsDeleted": 0,
                "CreatedAt": "2024-06-04T09:27:06.629907+00:00",
                "Id": "0c3b7d71-4b31-4e8d-beb0-149c2829dae7"
            },
            {
                "Name": "StatementOfChangesInEquityAbstract",
                "NameNormalized": "STATEMENTOFCHANGESINEQUITYABSTRACT",
                "NamespaceAbbreviation": "ifrs-full",
                "Description": "StatementOfChangesInEquityAbstract",
                "IsDeleted": 0,
                "CreatedAt": "2024-06-04T09:27:12.005104+00:00",
                "Id": "5d01cba8-e935-4c8b-8d5e-2514047f597c"
            }
        ],
        "ExtensionStructureReferences": [
            {
                "Name": "StatementOfChangesInEquityLineItems",
                "NameNormalized": "STATEMENTOFCHANGESINEQUITYLINEITEMS",
                "NamespaceAbbreviation": "ifrs-full",
                "Description": "StatementOfChangesInEquityLineItems",
                "IsDeleted": 0,
                "CreatedAt": "2024-06-04T09:27:06.629907+00:00",
                "Id": "0c3b7d71-4b31-4e8d-beb0-149c2829dae7"
            },
            {
                "Name": "StatementOfChangesInEquityAbstract",
                "NameNormalized": "STATEMENTOFCHANGESINEQUITYABSTRACT",
                "NamespaceAbbreviation": "ifrs-full",
                "Description": "StatementOfChangesInEquityAbstract",
                "IsDeleted": 0,
                "CreatedAt": "2024-06-04T09:27:12.005104+00:00",
                "Id": "5d01cba8-e935-4c8b-8d5e-2514047f597c"
            }
        ],
        "Taxonomies": [{
            "systemName": "ESEF_20220324.zip",
            "languages": [
                "en",
                "ro",
                "ga",
                "pt",
                "sk",
                "sl",
                "sv",
                "bg",
                "cs",
                "da",
                "de",
                "el",
                "es",
                "fi",
                "et",
                "fr",
                "hr",
                "hu",
                "nl",
                "it",
                "lt",
                "lv",
                "mt",
                "pl"
            ],
            "isReferenceTaxonomy": false,
            "isDeleted": 0,
            "createdAt": "2024-06-04T10:40:00.06112+00:00",
            "id": "adc96e98-f5b5-4345-9d93-3b55e65b22e5"
        },
        {
            "systemName": "ESEF_20210324.zip",
            "languages": [
                "en",
                "ro",
                "ga",
                "pt",
                "sk",
                "sl",
                "sv",
                "bg",
                "cs",
                "da",
                "de",
                "el",
                "es",
                "fi",
                "et",
                "fr",
                "hr",
                "hu",
                "nl",
                "it",
                "lt",
                "lv",
                "mt",
                "pl"
            ],
            "isReferenceTaxonomy": false,
            "isDeleted": 0,
            "createdAt": "2024-06-04T11:24:45.179457+00:00",
            "id": "eacc26f2-cdb8-444f-b2b7-4157a6197b30"
        }
        ]
    }
];
export const hardcodedLabelsData = [
    {
        id: "1",
        label: "ProfitBeforeTax",
        element: "Element1",
        company: "CompanyA",
        tagGroup: "FinancialStatements",
        dateSpectrum: "standard",
        language: "en",
        taxonomies: "ESEF 20220324",
        dimensionValues: "Dimension 1",
        additionalElements: ""
    },
    {
        id: "2",
        label: "Revenue",
        element: "Element2",
        company: "CompanyA",
        tagGroup: "FinancialStatements",
        dateSpectrum: "standard",
        language: "en",
        taxonomies: "ESEF 20220324",
        dimensionValues: "Dimension 1",
        additionalElements: ""
    },
    {
        id: "3",
        label: "TotalAssets",
        element: "Element3",
        company: "CompanyA",
        tagGroup: "BalanceSheet",
        dateSpectrum: "standard",
        language: "en",
        taxonomies: "ESEF 20220324",
        dimensionValues: "Dimension 1",
        additionalElements: ""
    },
    {
        id: "4",
        label: "NetIncome",
        element: "Element4",
        company: "CompanyB",
        tagGroup: "FinancialStatements",
        dateSpectrum: "standard",
        language: "en",
        taxonomies: "ESEF 20220324",
        dimensionValues: "Dimension 1",
        additionalElements: ""
    },
    {
        id: "5",
        label: "EarningsPerShare",
        element: "Element5",
        company: "CompanyB",
        tagGroup: "FinancialStatements",
        dateSpectrum: "standard",
        language: "en",
        taxonomies: "ESEF 20220324",
        dimensionValues: "Dimension 1",
        additionalElements: ""
    },
    {
        id: "6",
        label: "OperatingExpenses",
        element: "Element6",
        company: "CompanyB",
        tagGroup: "IncomeStatement",
        dateSpectrum: "standard",
        language: "en",
        taxonomies: "ESEF 20220324",
        dimensionValues: "Dimension 1",
        additionalElements: ""
    },
    {
        id: "7",
        label: "CashFlowFromOperations",
        element: "Element7",
        company: "CompanyC",
        tagGroup: "CashFlowStatement",
        dateSpectrum: "standard",
        language: "en",
        taxonomies: "ESEF 20220324",
        dimensionValues: "Dimension 1",
        additionalElements: ""
    },
    {
        id: "8",
        label: "CashFlowFromInvesting",
        element: "Element8",
        company: "CompanyC",
        tagGroup: "CashFlowStatement",
        dateSpectrum: "standard",
        language: "en",
        taxonomies: "ESEF 20220324",
        dimensionValues: "Dimension 1",
        additionalElements: ""
    },
    {
        id: "9",
        label: "CashFlowFromFinancing",
        element: "Element9",
        company: "CompanyC",
        tagGroup: "CashFlowStatement",
        dateSpectrum: "standard",
        language: "en",
        taxonomies: "ESEF 20220324",
        dimensionValues: "Dimension 1",
        additionalElements: ""
    },
    {
        id: "10",
        label: "TotalLiabilities",
        element: "Element10",
        company: "CompanyD",
        tagGroup: "BalanceSheet",
        dateSpectrum: "standard",
        language: "en",
        taxonomies: "ESEF 20220324",
        dimensionValues: "Dimension 1",
        additionalElements: ""
    },
    {
        id: "11",
        label: "ShareholderEquity",
        element: "Element11",
        company: "CompanyD",
        tagGroup: "BalanceSheet",
        dateSpectrum: "standard",
        language: "en",
        taxonomies: "ESEF 20220324",
        dimensionValues: "Dimension 1",
        additionalElements: ""
    },
    {
        id: "12",
        label: "GrossProfit",
        element: "Element12",
        company: "CompanyD",
        tagGroup: "IncomeStatement",
        dateSpectrum: "standard",
        language: "en",
        taxonomies: "ESEF 20220324",
        dimensionValues: "Dimension 1",
        additionalElements: ""
    },
    {
        id: "13",
        label: "DepreciationAndAmortization",
        element: "Element13",
        company: "CompanyE",
        tagGroup: "IncomeStatement",
        dateSpectrum: "standard",
        language: "en",
        taxonomies: "ESEF 20220324",
        dimensionValues: "Dimension 1",
        additionalElements: ""
    },
    {
        id: "14",
        label: "ResearchAndDevelopmentExpense",
        element: "Element14",
        company: "CompanyE",
        tagGroup: "IncomeStatement",
        dateSpectrum: "standard",
        language: "en",
        taxonomies: "ESEF 20220324",
        dimensionValues: "Dimension 1",
        additionalElements: ""
    },
    {
        id: "15",
        label: "InterestIncome",
        element: "Element15",
        company: "CompanyE",
        tagGroup: "IncomeStatement",
        dateSpectrum: "standard",
        language: "en",
        taxonomies: "ESEF 20220324",
        dimensionValues: "Dimension 1",
        additionalElements: ""
    },
    {
        id: "16",
        label: "InterestExpense",
        element: "Element16",
        company: "CompanyF",
        tagGroup: "IncomeStatement",
        dateSpectrum: "standard",
        language: "en",
        taxonomies: "ESEF 20220324",
        dimensionValues: "Dimension 1",
        additionalElements: ""
    },
    {
        id: "17",
        label: "NetCashProvidedByOperatingActivities",
        element: "Element17",
        company: "CompanyF",
        tagGroup: "CashFlowStatement",
        dateSpectrum: "standard",
        language: "en",
        taxonomies: "ESEF 20220324",
        dimensionValues: "Dimension 1",
        additionalElements: ""
    },
    {
        id: "18",
        label: "NetCashUsedInInvestingActivities",
        element: "Element18",
        company: "CompanyF",
        tagGroup: "CashFlowStatement",
        dateSpectrum: "standard",
        language: "en",
        taxonomies: "ESEF 20220324",
        dimensionValues: "Dimension 1",
        additionalElements: ""
    },
    {
        id: "19",
        label: "NetCashUsedInFinancingActivities",
        element: "Element19",
        company: "CompanyG",
        tagGroup: "CashFlowStatement",
        dateSpectrum: "standard",
        language: "en",
        taxonomies: "ESEF 20220324",
        dimensionValues: "Dimension 1",
        additionalElements: ""
    },
    {
        id: "20",
        label: "ComprehensiveIncome",
        element: "Element20",
        company: "CompanyG",
        tagGroup: "IncomeStatement",
        dateSpectrum: "standard",
        language: "en",
        taxonomies: "ESEF 20220324",
        dimensionValues: "Dimension 1",
        additionalElements: ""
    },
    {
        id: "21",
        label: "IncomeTaxExpense",
        element: "Element21",
        company: "CompanyG",
        tagGroup: "IncomeStatement",
        dateSpectrum: "standard",
        language: "en",
        taxonomies: "ESEF 20220324",
        dimensionValues: "Dimension 1",
        additionalElements: ""
    },
    {
        id: "22",
        label: "CostOfGoodsSold",
        element: "Element22",
        company: "CompanyH",
        tagGroup: "IncomeStatement",
        dateSpectrum: "standard",
        language: "en",
        taxonomies: "ESEF 20220324",
        dimensionValues: "Dimension 1",
        additionalElements: ""
    },
    {
        id: "23",
        label: "OperatingIncome",
        element: "Element23",
        company: "CompanyH",
        tagGroup: "IncomeStatement",
        dateSpectrum: "standard",
        language: "en",
        taxonomies: "ESEF 20220324",
        dimensionValues: "Dimension 1",
        additionalElements: ""
    },
    {
        id: "24",
        label: "NetWorkingCapital",
        element: "Element24",
        company: "CompanyH",
        tagGroup: "BalanceSheet",
        dateSpectrum: "standard",
        language: "en",
        taxonomies: "ESEF 20220324",
        dimensionValues: "Dimension 1",
        additionalElements: ""
    },
    {
        id: "25",
        label: "EBITDA",
        element: "Element25",
        company: "CompanyH",
        tagGroup: "IncomeStatement",
        dateSpectrum: "standard",
        language: "en",
        taxonomies: "ESEF 20220324",
        dimensionValues: "Dimension 1",
        additionalElements: ""
    }
];
