import { Stack, Container, Box, Typography, Divider, Chip, TablePagination } from "@mui/material";
import { getStatusColor } from "../../helpers/getConversionStatusColoer";
import { useMemo } from "react";

interface StatData {
    total: number;
    cancelled: number;
    running: number;
    failed: number;
    completed: number;
    averageTime: string;
}

interface RecentActivityBarProps {
    statData: StatData | null;
    statLoading: boolean;
    statError: boolean;
    currentConversionRequestNumber: number;
    changeReqValue: (path: string, val: any) => void;
    startTime: string;
}

const StatItem = ({ label, value, color }: { label: string; value: number; color: string }) => {
    const memoizedValue = useMemo(() => `${label}: ${value}`, [label, value]);

    return (
        <>
            <Divider orientation="vertical" flexItem />
            <Typography variant="subtitle2">
                <Chip
                    sx={{ minWidth: 100, maxWidth: 150 }}
                    label={memoizedValue}
                    variant="filled"
                    size="small"
                    color={color as "primary" | "secondary" | "error" | "success" | "info" | "warning" | "default"}
                />
            </Typography>
        </>
    );
};

export const RecentActivityBar = ({
    statData,
    statLoading,
    statError,
    currentConversionRequestNumber,
    changeReqValue,
    startTime,
}: RecentActivityBarProps) => {
    if (statLoading) {
        return <Container />;
    }

    if (statError) {
        return <Box />;
    }

    return (
        <Stack
            sx={{
                mt: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}
            direction="row"
            spacing={2}
            width={"100%"}
        >
            <Box sx={{ color: "black" }}>
                <Stack
                    sx={{
                        mt: 1,
                        display: "flex",
                        alignItems: "center",
                    }}
                    direction="row"
                    spacing={2}
                    width={"100%"}
                >
                    <Typography variant="subtitle2" fontWeight={"bold"} title={`Since ${startTime}`}>
                        Recent Activity
                    </Typography>
                    <Divider orientation="vertical" flexItem />
                    <Typography variant="subtitle2">Total: {statData?.total}</Typography>
                    {statData && (
                        <>
                            <StatItem label="Cancelled" value={statData.cancelled} color={getStatusColor("Cancelled")} />
                            <StatItem label="Running" value={statData.running} color={getStatusColor("Running")} />
                            <StatItem label="Failed" value={statData.failed} color={getStatusColor("Error")} />
                            <StatItem label="Completed" value={statData.completed} color={getStatusColor("Completed")} />
                        </>
                    )}
                    <Divider orientation="vertical" flexItem />
                    <Typography variant="subtitle2">Average Time: {statData?.averageTime ?? "N/A"}</Typography>
                </Stack>
            </Box>
            <TablePagination
                component="div"
                count={-1}
                rowsPerPage={25}
                rowsPerPageOptions={[]}
                page={currentConversionRequestNumber - 1}
                SelectProps={{
                    inputProps: {
                        "aria-label": "rows per page",
                    },
                    native: true,
                }}
                onPageChange={(_, pageNumber) => changeReqValue("paging", pageNumber + 1)}
                showFirstButton
            />
        </Stack>
    );
};
