import { createTheme } from "@mui/material";
import NotoSansLatin500 from "../style/fonts/noto-sans-v27-latin-500.woff";
import NotoSansLatin500_2 from "../style/fonts/noto-sans-v27-latin-500.woff2";
import NotoSansLatin600 from "../style/fonts/noto-sans-v27-latin-600.woff";
import NotoSansLatin600_2 from "../style/fonts/noto-sans-v27-latin-600.woff2";
import NotoSansLatin700 from "../style/fonts/noto-sans-v27-latin-700.woff";
import NotoSansLatin700_2 from "../style/fonts/noto-sans-v27-latin-700.woff2";

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#072a44",
    },
    secondary: {
      main: "#279F24",
    },
    background: {
      default: "#c4c6c6",
    },
  },
  typography: {
    fontFamily: "Noto Sans",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-display: swap; 
        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 500;
        src: url(${NotoSansLatin500_2}) format('woff2'), 
             url(${NotoSansLatin500}) format('woff'); 
      }
      
      @font-face {
        font-display: swap; 
        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 600;
        src: url(${NotoSansLatin600_2}) format('woff2'), 
             url(${NotoSansLatin600}) format('woff'); 
      }
      
      @font-face {
        font-display: swap; 
        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 700;
        src: url(${NotoSansLatin700_2}) format('woff2'), 
             url(${NotoSansLatin700}) format('woff'); 
      }`,
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#8ae991",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#fff",
          },
        },
      },
    },
  },
});
