import { t } from "@lingui/macro";
import { i18n } from "@lingui/core";
import moment from "moment";
import { ApiOrganization } from "../../api/types";
import { DataTableColumn } from "../dataTable";

export function organizationsTableColumns(): DataTableColumn<ApiOrganization>[] {
  return [
    {
      header: {
        id: "name",
        label: t(i18n)`ORGANIZATION NAME`,
      },
      key: "name",
    },
    {
      header: {
        id: "createdAt",
        label: t(i18n)`CREATED AT`,
      },
      key: "createdAt",
      valueFormatter: (row) =>
        moment(row.createdAt.toString()).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      header: {
        id: "country",
        label: t(i18n)`COUNTRY`,
      },
      key: "country",
    },
    {
      header: {
        id: "registrationNumber",
        label: t(i18n)`REG. NO`,
      },
      key: "registrationNumber",
    },
    {
      header: {
        id: "domain",
        label: t(i18n)`DOMAIN`,
      },
      key: "domain",
    },
    {
      header: {
        id: "leiCode",
        label: t(i18n)`LEI`,
      },
      key: "leiCode",
    },
  ];
}
