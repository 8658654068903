import { t } from "@lingui/macro";
import { Backdrop, CircularProgress } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { authenticateWithMicrosoft } from "../../api/auth";
import tokenStorageHelper from "../../helpers/tokenStorage";
import { intendedPathLocalStorageKey } from "../../helpers/constants";

function MicrosoftLoginCallback() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const state = searchParams.get("state");
  const navigate = useNavigate();

  const callAuth = useCallback(async () => {
    const logoutOnError = () => {
      alert(t`Something went wrong, please try again later`);
      navigate("/logout", { replace: true });
    };

    const codeChallenge = localStorage.getItem("code_challenge");
    if (codeChallenge === null) {
      return;
    }

    if (state !== localStorage.getItem("state")) {
      console.error("State does not match");
      logoutOnError();
      return;
    }

    localStorage.removeItem("code_challenge");
    localStorage.removeItem("state");
    try {
      const resp = await authenticateWithMicrosoft(code || "", codeChallenge);
      tokenStorageHelper.setTokenValues(resp.data);
      let navPath = "/";
      const intendedPath = localStorage.getItem(intendedPathLocalStorageKey);
      if (intendedPath) {
        navPath = intendedPath;
        localStorage.removeItem(intendedPathLocalStorageKey);
      }
      navigate(navPath, { replace: true });
    } catch (ex) {
      console.log(ex);
      logoutOnError();
    }
  }, [code, state, navigate]);

  useEffect(() => {
    callAuth();
  }, [callAuth]);

  return (
    <Backdrop
      open={true}
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default MicrosoftLoginCallback;
