export const assignDeep = (obj: any, value: any, path: string) => {
  const a = path.split(".");
  let o: any = obj;
  while (a.length - 1) {
    const n: any = a.shift();
    if (!(n in o)) o[n] = {};
    o = o[n];
  }
  o[a[0]] = value;
};
