import { Button, Container, Paper, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ContainerWrapper } from "../components/containerWrapper";

export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <ContainerWrapper>
      <Paper sx={{ minHeight: 650, p: 5 }}>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h1" sx={{ fontWeight: "bold" }}>
            404
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: "bold" }}>
            Page Not Found
          </Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            The Page you are looking for dosen't exist or an other error
            occured. Go,
          </Typography>
          <Stack direction={"row"} sx={{ p: 2 }} spacing={5}>
            <Button variant="outlined" onClick={() => navigate("/")}>
              Go Home
            </Button>
            <Button variant="contained" onClick={() => navigate(-1)}>
              Go Back
            </Button>
          </Stack>
        </Container>
      </Paper>
    </ContainerWrapper>
  );
};
