import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  SvgIconTypeMap,
  TextField,
  Box,
  Button,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import ConversionSearchByIdDialog from "./conversions/conversionSearchByIdDialog";
import { t } from "@lingui/macro";
import { ConversionQueryRequest } from "../api/types";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

export interface ContentHeaderToolbarAction {
  id: "importData" | "importTax" | "filter" | "search" | "addNew" | "scheme";
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  title: string;
  clickAction: (e: any) => void;
  turnedOn: boolean;
  disabled: boolean;
}

interface ContentHeaderToolbarProps {
  titleIcon?: JSX.Element;
  title: string;
  actions: ContentHeaderToolbarAction[];
  actionsFullText?: boolean;
  CreateButton?: boolean;
  createButtonWidth?: number;
  conversionSearchAction?: boolean;
  showIcons: boolean;
  searchBar?: boolean;
  searchAutoFocus?: boolean;
  searchValue?: string;
  searchBarWidth?: number;
  searchPlaceholder?: string;
  showExportButton?: boolean;
  disableExportButton?: boolean;
  onExport?: () => void;
  onUpdateSearch?: (val: string) => void;
  currentConversionRequest?: ConversionQueryRequest;
}

const ContentHeaderToolbar = ({
  titleIcon,
  title,
  actions,
  actionsFullText,
  CreateButton,
  createButtonWidth,
  conversionSearchAction,
  showIcons,
  searchBar,
  searchAutoFocus,
  searchValue,
  searchBarWidth,
  searchPlaceholder,
  onUpdateSearch,
  showExportButton,
  disableExportButton,
  onExport,
}: ContentHeaderToolbarProps) => {
  return (
    <Toolbar
      sx={{
        pt: 2,
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        pb: 3,
        mb: 2,
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mr: 1,
        }}
      >
        {titleIcon}
      </Box>
      <Typography sx={{ flex: "1 1" }} variant="h4" id="tableTitle">
        {title}
      </Typography>
      {actionsFullText === false ||
        actionsFullText === undefined ||
        CreateButton === false ||
        (CreateButton === undefined && (
          <>
            {showIcons &&
              actions.map((action) => (
                <Tooltip
                  title={action.title}
                  key={`action_icon_${action.title}`}
                >
                  <span>
                    <IconButton
                      disabled={action.disabled}
                      onClick={action.clickAction}
                      sx={{
                        color: action.turnedOn
                          ? (theme) => theme.palette.error.main
                          : "",
                      }}
                    >
                      <action.icon />
                    </IconButton>
                  </span>
                </Tooltip>
              ))}
          </>
        ))}
      {actionsFullText && (
        <>
          {actions.map((action) => (
            <Button
              key={`action_button_${action.title}`}
              variant="outlined"
              disabled={action.disabled}
              onClick={action.clickAction}
              size="large"
              sx={{
                width: 250,
                ml: 5,
                p: 1,
                mr: 4,
                color: action.turnedOn
                  ? (theme) => theme.palette.error.main
                  : "",
              }}
            >
              <action.icon />
              <Typography variant="body2" sx={{ pl: 2 }}>
                {action.title}
              </Typography>
            </Button>
          ))}
        </>
      )}

      {CreateButton && (
        <Box sx={{ zIndex: 2 }}>
          {actions.map((action) => (
            <Button
              key={`action_button_${action.title}`}
              size="large"
              sx={{
                position: "relative",
                mr: 3.5,
                ml: 3.5,
                width: createButtonWidth,
                height: 50,
              }}
              variant="contained"
              disabled={action.disabled}
              onClick={action.clickAction}
            >
              <action.icon />
              <Typography variant="body2" sx={{ pl: 2 }}>
                {action.title}
              </Typography>
            </Button>
          ))}
        </Box>
      )}
      {searchBar && onUpdateSearch && (
        <TextField
          autoFocus={searchAutoFocus ? true : false}
          sx={{ width: searchBarWidth }}
          value={searchValue}
          onChange={(e) => onUpdateSearch(e.currentTarget.value)}
          placeholder={searchPlaceholder}
        />
      )}
      {conversionSearchAction ? <ConversionSearchByIdDialog /> : null}
      {showExportButton && (
        <IconButton
          sx={{ ml: 3 }}
          title={t`Export Conversions`}
          color="primary"
          disabled={disableExportButton}
          onClick={onExport && onExport}
          size="large"
        >
          <FileDownloadIcon></FileDownloadIcon>
        </IconButton>
      )}
    </Toolbar>
  );
};

export default ContentHeaderToolbar;
