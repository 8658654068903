import { Container } from "@mui/system";
import { Paper } from "@mui/material";

export const ObjectsWrapper = ({ children }: any) => {
  return (
    <Container
      maxWidth={false}
      sx={{
        ml: 0,
        mt: 2,
        overflow: "auto",
      }}
    >
      <Paper sx={{ p: 2 }}>{children}</Paper>
    </Container>
  );
};
