import { t } from "@lingui/macro";
import { Box, Button } from "@mui/material";
import { redirectForMicrosoftLogin } from "../../helpers/redirectToMicrosoftLogin";
import LoginContainer from "./loginContainer";
import MicrosoftImg from "../../images/microsoft_icon.svg";
import BackgroundImg from "../../images/background.png";
import tokenStorageHelper from "../../helpers/tokenStorage";
import { DecodedToken } from "../../api/types";
import { Navigate } from "react-router";
import { jwtDecode } from "jwt-decode";

const Login = () => {
  if (tokenStorageHelper.isValid) {
    var decodedToken = jwtDecode(tokenStorageHelper.token) as
      | DecodedToken
      | undefined;
    if (decodedToken) {
      if (decodedToken.hasConversionManagement === "False") {
        if (decodedToken.hasTaxonomyManagement === "True") {
          return <Navigate to="/taxonomies" replace={true} />;
        }
        if (decodedToken.hasUserManagement === "True") {
          return <Navigate to="/users" replace={true} />;
        }
      } else {
        return <Navigate to="/conversions" replace={true} />;
      }
    }
  }
  return (
    <Box
      height="100vh"
      sx={{
        backgroundImage: `url(${BackgroundImg})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <LoginContainer sx={{ pt: 15 }}>
        <Box sx={{ mt: 1 }}>
          <Button
            color="inherit"
            variant="outlined"
            startIcon={<img src={MicrosoftImg} width="20px" alt="Microsoft" />}
            fullWidth
            onClick={redirectForMicrosoftLogin}
            sx={{ mt: 3, mb: 2 }}
          >
            {t`Sign in with Microsoft`}
          </Button>
        </Box>
      </LoginContainer>
    </Box>
  );
};

export default Login;
