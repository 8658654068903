import { microsoftLoginUrl } from "./constants";

const createCodeChallenge = (length: number = 43): string => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const redirectForMicrosoftLogin = (): void => {
  var state = crypto.randomUUID();
  var codeChallenge = createCodeChallenge();

  localStorage.setItem("state", state);
  localStorage.setItem("code_challenge", codeChallenge);

  const loginUrl = microsoftLoginUrl(
    process.env.REACT_APP_TENANT_ID || "",
    process.env.REACT_APP_CLIENT_ID || "",
    state,
    process.env.REACT_APP_REDIRECTURL || "",
    codeChallenge
  );
  window.location.replace(loginUrl);
};
