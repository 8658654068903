import { Navigate } from "react-router-dom";
import { DecodedToken } from "../api/types";
import tokenStorageHelper from "../helpers/tokenStorage";
import { jwtDecode } from "jwt-decode";

const DefaultRoute = () => {
  if (tokenStorageHelper.isValid) {
    var decodedToken = jwtDecode(tokenStorageHelper.token) as
      | DecodedToken
      | undefined;
    if (decodedToken) {
      if (decodedToken.hasConversionManagement === "False") {
        if (decodedToken.hasTaxonomyManagement === "True") {
          return <Navigate to="/taxonomies" replace={true} />;
        }
        if (decodedToken.hasUserManagement === "True") {
          return <Navigate to="/users" replace={true} />;
        }
      }
    }
  }

  return <Navigate to="/conversions" replace={true} />;
};

export default DefaultRoute;
