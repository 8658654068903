import { Alert, AlertColor, AlertTitle, Container, Stack } from "@mui/material";

export interface EmptyTableAlertProps {
  title?: string;
  subTitle: string;
  severity: AlertColor;
  showIcon?: boolean;
  mainIcon?: JSX.Element;
}

const EmptyTableAlert = ({
  subTitle,
  severity,
  mainIcon,
  showIcon,
}: EmptyTableAlertProps) => {
  return (
    <Container
      sx={{
        maxHeight: 850,
        display: "flex",
        justifyItems: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {!showIcon && (
        <Alert icon={false} severity={severity} sx={{ mt: 10 }} color="info">
          <AlertTitle>{subTitle}</AlertTitle>
        </Alert>
      )}
      {showIcon && (
        <Container
          sx={{
            minHeight: 250,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            direction={"column"}
            justifyContent="center"
            alignItems="center"
          >
            {mainIcon}
            {subTitle}
          </Stack>
        </Container>
      )}
    </Container>
  );
};

export default EmptyTableAlert;
