import axios, { AxiosResponse } from "axios";
import { ApiUserDetails } from "./types";

export const createNewUser = async (payload: {
  organizationId: string;
  name: string;
  emailAddress: string;
  isAdministrator: boolean;
}): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/users/signup`, payload);
};

export const updateUserDetails = async (
  id: string,
  payload: Pick<ApiUserDetails, "name" | "isAdministrator">
): Promise<AxiosResponse<ApiUserDetails>> => {
  return axios.post(`/api/users/${id}/update`, payload);
};

export const toggleUserActivation = async (
  id: string,
  activate: boolean
): Promise<AxiosResponse<void>> => {
  const url = `/api/users/${id}/${activate ? "activate" : "deactivate"}`;
  return axios.post(url);
};

export const reSendActivationEmail = async (
  UserId: string
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/users/signup/resend`, { UserId });
};
