import { t } from "@lingui/macro";
import { ApiSubscription } from "../../../api/types";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";

interface SubscriptionColumnsProps {
  openDialog: (setState: boolean) => void;
  setId: (setState: string) => void;
}

export const SubscriptionColumns = ({
  openDialog,
  setId,
}: SubscriptionColumnsProps) => {
  return [
    {
      header: {
        id: "id",
        label: t`Id`,
      },
      key: "id",
    },
    {
      header: {
        id: "name",
        label: t`Name`,
      },
      key: "name",
    },
    {
      header: {
        id: "expireAt",
        label: t`Expire At`,
      },
      key: "expireAt",
      valueFormatter: (row: ApiSubscription) =>
        row.expireAt ? moment(row.expireAt.toString()).format("DD/MM/YYYY HH:mm:ss") : "",
    },
    {
      header: {
        id: "purchaseReference",
        label: t`Purchase Reference`,
      },
      key: "purchaseReference",
    },
    {
      header: {
        id: "isActive",
        label: t`Is Active`,
      },
      key: "isActive",
      valueFormatter: (row: ApiSubscription) =>
        row.isActive ? (
          <CheckIcon color="success" />
        ) : (
          <CloseIcon color="error" />
        ),
    },
    {
      header: {
        id: "",
        label: t`Action`,
      },
      key: "",
      width: 150,
      valueFormatter: (row: ApiSubscription) => (
        <Button
          variant="outlined"
          onClick={() => {
            openDialog(true);
            setId(row.id);
          }}
        >
          {t`Remove Subscription`}
        </Button>
      ),
    },
  ];
};
