import { Container } from "@mui/material";
import { ContainerMaxWidth } from "../helpers/constants";

export const ContainerWrapper = ({ children }: any) => {
  return (
    <Container
      maxWidth={ContainerMaxWidth}
      sx={{
        maxWidth: 2100,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        pt: 15,
      }}
    >
      {children}
    </Container>
  );
};
