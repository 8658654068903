import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Button, SvgIconTypeMap, useTheme } from "@mui/material";
import { t } from "@lingui/macro";
import { Link, useLocation } from "react-router-dom";
import tokenStorageHelper from "../helpers/tokenStorage";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import SelectTaxonomies from "./customObjects/selectTaxonomies";
import { getAllTaxonomies, handleTaxonomiesChange } from "../helpers/taxonomyHelpers";

interface NavbarTab {
  path: string;
  label: string;
  icon?: OverridableComponent<SvgIconTypeMap>;
  hasAccess?: boolean;
}

interface DecodedToken {
  hasConversionManagement: string;
  hasUserManagement: string;
  hasTaxonomyManagement: string;
}

// Renders the top navbar for the application
const NavbarCustomObjects = () => {
  var decodeToken = jwtDecode(tokenStorageHelper.token) as DecodedToken;
  useEffect(() => {
    if (!tokenStorageHelper.token) return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenStorageHelper.token]);
  const [openTaxonomySelecor, setOpenTaxonomySelecor] = useState(false);

  const hasTaxonomyAccess = decodeToken.hasTaxonomyManagement === "True";

  const tabs: NavbarTab[] = [
    {
      path: "/customobjects/tags",
      label: t`Tags`,
      hasAccess: hasTaxonomyAccess,
    },
    {
      path: "/customobjects/headers",
      label: t`Headers`,
      hasAccess: hasTaxonomyAccess,
    },
    {
      path: "/customobjects/labels",
      label: t`Labels`,
      hasAccess: hasTaxonomyAccess,
    },
    {
      path: "/customobjects/dimensions",
      label: t`Dimensions`,
      hasAccess: hasTaxonomyAccess,
    },
    {
      path: "/customobjects/dimensionvalues",
      label: t`Dimension Values`,
      hasAccess: hasTaxonomyAccess,
    },
    {
      path: "/customobjects/extensionelements",
      label: t`Extension Elements`,
      hasAccess: hasTaxonomyAccess,
    },
    {
      path: "/customobjects/settings",
      label: t`Settings`,
      hasAccess: hasTaxonomyAccess,
    },
    {
      path: "/customobjects/contextsettings",
      label: t`Context Settings`,
      hasAccess: hasTaxonomyAccess,
    },
    {
      path: "/customobjects/consistencycheck",
      label: t`Consistency Check`,
      hasAccess: hasTaxonomyAccess,
    },
    {
      path: "/customobjects/globalrequests",
      label: t`Global Requests`,
      hasAccess: hasTaxonomyAccess,
    },
  ];
  const location = useLocation();
  const theme = useTheme();

  if (!tokenStorageHelper.isUserLoggedIn) return null;

  let currentPath: string | boolean = location.pathname.toLowerCase();
  if (!tabs || !tabs.map((t) => t.path).includes(currentPath)) {
    currentPath = false;
  }

  const tabElements = tabs.map((tab) => (
    <Tab
      key={tab.label}
      component={Link}
      to={tab.path}
      value={tab.path}
      label={tab.label}
      iconPosition="start"
      disabled={!tab.hasAccess}
      style={{
        color: tab.hasAccess ? theme.palette.common.black : "grey",
      }}
      {...a11yProps(tab.label)}
    />
  ));

  const taxonomiesList = getAllTaxonomies();

  const selectedTaxonomies = taxonomiesList.filter(taxonomy => taxonomy.isSelected);

  return (
    <AppBar
      sx={{
        mt: 9,
        height: 48,
        zIndex: (theme) => theme.zIndex.drawer - 1,
        backgroundColor: "#f8f8f8",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Toolbar>
        {tabs && tabs.length > 0 && (
          <Tabs value={currentPath}>{tabElements}</Tabs>
        )}
        <Button
          sx={{ marginLeft: "auto", display: "flex" }}
          variant="text"
          onClick={(e) => {
            setOpenTaxonomySelecor(!openTaxonomySelecor);
          }}
        >
          {selectedTaxonomies.length > 1 && (
            <>
              {selectedTaxonomies[0].labels.find((label) => label.lang === "en")?.text}
              {" + "}
              {selectedTaxonomies.length - 1}
              {" "}{t`more`}
            </>
          )}
          {selectedTaxonomies.length === 1 && <>{selectedTaxonomies[0].labels.find((label) => label.lang === "en")?.text}</>}
          {selectedTaxonomies.length === 0 && <>{t`Select Taxonomies`}</>}
        </Button>
      </Toolbar>

      <>
        <SelectTaxonomies
          open={openTaxonomySelecor}
          isDialog={true}
          onClose={() => {
            setOpenTaxonomySelecor(false);
          }}
          onApply={(selectedTaxonomyIds) => {
            handleTaxonomiesChange(getAllTaxonomies().map((taxonomy) => ({
              ...taxonomy,
              isSelected: selectedTaxonomyIds.includes(taxonomy.id),
            }))
            );
            setOpenTaxonomySelecor(false);
          }}
        />

      </>
    </AppBar>
  );
};

function a11yProps(value: string) {
  return {
    id: `simple-tab-${value}`,
    "aria-controls": `simple-tabpanel-${value}`,
  };
}

export default NavbarCustomObjects;
