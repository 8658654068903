import axios, { AxiosResponse } from "axios";
import { allowOnly200 } from "../helpers/constants";
import { AccessTokenRespons } from "./types";

export const authenticateWithMicrosoft = async (
  code: string | boolean,
  codeVerifier: string | null
): Promise<AxiosResponse<AccessTokenRespons>> => {
  return axios.post<AccessTokenRespons>(
    "/api/accounts/login/microsoft",
    {
      AppId: "Default",
      Code: code,
      CodeVerifier: codeVerifier,
      RedirectUri: process.env.REACT_APP_REDIRECTURL,
    },
    {
      validateStatus: allowOnly200,
    }
  );
};
