import axios, { AxiosResponse } from "axios";
import tokenStorageHelper from "../helpers/tokenStorage";
import { getFileNameFromHeader } from "../helpers/getFileNameFromHeader";
import { t } from "@lingui/macro";

export const generalGet = <T>(url: string): Promise<AxiosResponse<T>> => {
  return axios.get(url, {
    headers: {
      "Cache-Control": "no-cache",
    },
  });
};

export const generalPost = <T>(
  url: string,
  body: any
): Promise<AxiosResponse<T>> => {
  return axios.post(url, body);
};

export const fileGet = async (url: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(url, { responseType: "blob" });
    return response;
  } catch (ex: any) {
    throw new Error(
      ex.response.data.IsTranslated
        ? ex.response.data.Exceptions[0]
        : t`An error occurred while fetching the file.`
    );
  }
};

export const getPackageBlob = async (
  url: string
): Promise<{ blob: Blob; name: string }> => {
  const resp = await fetch(
    `${process.env.REACT_APP_PROTOCOL_USE}${process.env.REACT_APP_HOST_PREFIX}${process.env.REACT_APP_HOST_USE}${url}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokenStorageHelper.token}`,
      },
    }
  );
  if (resp.status !== 200) {
    throw new Error(resp.statusText);
  }
  const blob = await resp.blob();
  return {
    blob: blob,
    name: getFileNameFromHeader(resp.headers.get("content-disposition") || ""),
  };
};

export const postPackageBlob = async (
  url: string,
  body: any
): Promise<{ blob: Blob; name: string }> => {
  const resp = await fetch(
    `${process.env.REACT_APP_PROTOCOL_USE}${process.env.REACT_APP_HOST_PREFIX}${process.env.REACT_APP_HOST_USE}${url}`,
    {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${tokenStorageHelper.token}`,
        "Content-Type": "application/json",
      },
    }
  );
  if (resp.status !== 200) {
    throw new Error(resp.statusText);
  }
  const blob = await resp.blob();
  return {
    blob: blob,
    name: getFileNameFromHeader(resp.headers.get("content-disposition") || ""),
  };
};
