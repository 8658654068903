import { t } from "@lingui/macro";
import { i18n } from '@lingui/core';

export const allowOnly200 = (status: number) => status === 200;

export const authEndpoints = [
  "login",
  "login/microsoft",
  "login/refresh",
  "password/forgot",
  "password/reset",
];

export const microsoftLoginUrl = (
  tenantId: string,
  clientId: string,
  randomState: string,
  redirectUri: string,
  codeChallenge: string
) =>
  `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&response_mode=query&scope=email openid profile offline_access User.Read.All GroupMember.Read.All&state=${randomState}&redirect_uri=${redirectUri}&code_challenge=${codeChallenge}`;

export const appBarFixedHeight = 72;

export const taxonomyViewNoValueText = () => t(i18n)`No value found`;

export const ContainerMaxWidth = false;

export const ExpandedBorderColor = "#C7C7C7"

export const messageTextLength = 108;

export const taxonomyImportAllowedFileTypes = [
  "application/x-zip-compressed",
  "application/zip",
];

export const taxonomyDataImportAllowedFileTypes = ["application/xml", "text/xml"];

export const taxonomyImportAllowedFileExtensions = ["zip"];

export const taxonomyDataImportAllowedFileExtensions = ["xsd", "xml"];

export const intendedPathLocalStorageKey = "web_admin_intended_path"
