import { Trans, t } from "@lingui/macro";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { assignDeep } from "../../helpers/assignDeep";
import { createNewPermissionScheme } from "../../api/permissionSchemes";
import { permissionSchemeTable } from "../../api/types";

export interface CreateSchemeDialogProps {
  open: boolean;
  onClose: (
    updateStatus: "none" | "success" | "error",
    reason?: string | undefined
  ) => void;
}

const initial: Pick<permissionSchemeTable, "name" | "priceInEuro"> = {
  name: "",
  priceInEuro: 0,
};

const CreateSchemeDialog = ({ open, onClose }: CreateSchemeDialogProps) => {
  const [currentRequest, setCurrentRequest] =
    useState<Pick<permissionSchemeTable, "name" | "priceInEuro">>(initial);
  const [saving, setSaving] = useState(false);

  const handleClose = (
    updateStatus: "none" | "success" | "error",
    reason?: string | undefined
  ) => {
    onClose(updateStatus, reason);
    handleClear();
  };

  const handleClear = () => {
    setCurrentRequest(initial);
  };

  const handleSubmit = async () => {
    setSaving(true);
    try {
      await createNewPermissionScheme(currentRequest);
      handleClose("success", t`Permission Scheme created`);
      setSaving(false);
    } catch (ex: any) {
      setSaving(false);
      onClose(
        "error",
        ex.response.data.IsTranslated
          ? ex.reponse.data.Exceptions[0]
          : t`Something went wrong`
      );
    }
  };

  const handleFieldValueChange = (path: string, val: any) => {
    const req = currentRequest;
    assignDeep(req, val, path);
    setCurrentRequest((prev) => ({ ...req }));
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        onClose={(reason) => {
          if (reason !== "backdropClick" || "escapeKeyDown") {
            handleClose("none", "");
          }
        }}
      >
        <DialogTitle>
          <Trans>Create Permission Scheme</Trans>
        </DialogTitle>
        <DialogContent sx={{ minHeight: 210 }}>
          {saving && (
            <DialogContentText>
              <Trans>Hang in there, This might take a few minutes...</Trans>
            </DialogContentText>
          )}
          {!saving && (
            <>
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  sx={{ mt: 1, width: "100%" }}
                  value={currentRequest.name}
                  onChange={(e) =>
                    handleFieldValueChange("name", e.currentTarget.value)
                  }
                  label={t`Name *`}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  variant="standard"
                  sx={{ mt: 1, width: "100%" }}
                  value={currentRequest?.priceInEuro}
                  onChange={(e) =>
                    handleFieldValueChange("priceInEuro", e.currentTarget.value)
                  }
                  label={t`Price in Euro`}
                />
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose("none");
            }}
          >
            <Trans>Close</Trans>
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={saving || currentRequest.name === ""}
          >
            {!saving && <Trans>Save</Trans>}
            {saving && <CircularProgress size={25} />}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateSchemeDialog;
