import { Paper, Box, CircularProgress } from "@mui/material";

interface LoaderProps {
  height: number;
}

export const Loader = ({ height }: LoaderProps) => {
  return (
    <Paper sx={{ minHeight: height }} elevation={0}>
      <Box
        sx={{
          minHeight: height,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    </Paper>
  );
};
