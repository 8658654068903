export const formatTableTimeSpanValue = (from: string, to: Date): string => {
  const fromDate = new Date(from);
  if (isNaN(fromDate.getDate())) return "";
  const span = Math.floor(Math.abs(to.getTime() - fromDate.getTime()));
  const days = Math.floor(span / (1000 * 60 * 60 * 24));
  const hours = Math.floor(span / (1000 * 60 * 60)) - days * 24;
  const minutes =
    Math.floor(span / (1000 * 60)) - (days * 24 * 60 + hours * 60);
  const seconds =
    Math.floor(span / 1000) -
    (days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60);
  return `${days > 0 ? `${days} days` : ""} ${
    hours > 9 ? hours : `0${hours}`
  }:${minutes > 9 ? minutes : `0${minutes}`}:${
    seconds > 9 ? seconds : `0${seconds}`
  }`;
};
