import { t } from "@lingui/macro";
import {
  AppBar,
  Box,
  CircularProgress,
  Container,
  Paper,
  Popover,
  TablePagination,
} from "@mui/material";
import { useEffect, useState, useMemo, useCallback } from "react";
import {
  ApiConversion,
  ApiStatistics,
  ConversionQueryRequest,
} from "../../api/types";
import { startPolling } from "../../helpers/pollingHelper";
import { usePostQuery } from "../../hooks/usePostQuery";
import ContentHeaderToolbar from "../contentHeaderToolbar";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CachedIcon from "@mui/icons-material/Cached";
import DataTable from "../dataTable";
import { conversionTableColumns } from "./conversionTableColumns";
import ConversionExpandedatableRow from "./conversionExpandedatableRow";
import { ContainerWrapper } from "../containerWrapper";
import InboxIcon from "@mui/icons-material/Inbox";
import EmptyTableAlert from "../emptyTableAlert";
import { useLocation, useNavigate } from "react-router-dom";
import ConversionFilterMenu from "./conversionFilterMenu";
import { generalGet, postPackageBlob } from "../../api/general";
import moment from "moment";
import { isConversionQueryRequestEmpty } from "../../helpers/isConversionQueryRequestEmpty";
import { DownloadAsBlob } from "../../helpers/downloadAsBlob";
import { RecentActivityBar } from "./recentActivityBar";
import AlertSnackbar from "../alertSnackbar";

export const EmptyConversionQuery: ConversionQueryRequest = {
  paging: {
    count: 25,
    number: 1,
  },
  ConvertStartAt: null,
  ConvertEndAt: null,
  hasErrors: null,
  hasWarnings: null,
  organizationId: null,
  status: null,
  taxonomyId: null,
  userId: null,
  ReporterIdentifier: null,
  ReporterName: null,
  appId: null,
};

const useQueryParams = () => {
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  return useMemo(() => ({
    userId: searchParams.get("userId"),
    organizationId: searchParams.get("organizationId"),
    taxonomyId: searchParams.get("taxonomyId"),
    convertStartAt: searchParams.get("ConvertStartAt"),
    convertEndAt: searchParams.get("ConvertEndAt"),
    status: searchParams.get("status"),
    hasWarnings: searchParams.get("hasWarnings"),
    hasErrors: searchParams.get("hasErrors"),
    pagingNumber: searchParams.get("page"),
    ReporterIdentifier: searchParams.get("ReporterIdentifier"),
    ReporterName: searchParams.get("ReporterName"),
    appId: searchParams.get("appId"),
  }), [searchParams]);
};

const useConversionRequest = (queryParams: ReturnType<typeof useQueryParams>) => {
  const [req, setReq] = useState<ConversionQueryRequest>({
    userId: queryParams.userId ?? null,
    organizationId: queryParams.organizationId ?? null,
    taxonomyId: queryParams.taxonomyId ?? null,
    hasErrors: queryParams.hasErrors === "true" ? true : queryParams.hasErrors === "false" ? false : null,
    hasWarnings: queryParams.hasWarnings === "true" ? true : queryParams.hasWarnings === "false" ? false : null,
    status: queryParams.status as ApiConversion["status"] ?? null,
    ConvertStartAt: queryParams.convertStartAt ? new Date(queryParams.convertStartAt) : null,
    ConvertEndAt: queryParams.convertEndAt ? new Date(queryParams.convertEndAt) : null,
    ReporterIdentifier: queryParams.ReporterIdentifier ?? null,
    ReporterName: queryParams.ReporterName ?? null,
    appId: queryParams.appId ?? null,
    paging: {
      count: 25,
      number: queryParams.pagingNumber ? parseInt(queryParams.pagingNumber) : 1,
    },
  });

  const changeReqValue = useCallback((path: string, val: any) => {
    setReq(prevState => ({
      ...prevState,
      [path]: path === "paging" ? { ...prevState.paging, number: val ?? 1 } : val,
    }));
  }, []);

  return [req, setReq, changeReqValue] as const;
};

const usePolling = (fetchData: () => void, interval: number) => {
  useEffect(() => {
    fetchData();
    const intervalId = startPolling(fetchData, interval);

    return () => clearInterval(intervalId);
  }, [fetchData, interval]);
};

const Conversions = () => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();

  const [req, setReq, changeReqValue] = useConversionRequest(queryParams);

  const [initialLoading, setInitialLoading] = useState(true);
  const [cacheBuster, setCacheBuster] = useState(() => crypto.randomUUID());
  const [statLoading, setStatLoading] = useState(true);
  const [statError, setStatError] = useState(false);
  const [statData, setStatData] = useState<ApiStatistics | null>(null);
  const [alertMessage, setAlertMessage] = useState<{
    message: string;
    severity: "error" | "warning" | "info" | "success" | undefined;
  } | undefined>(undefined);
  const [exporting, setExporting] = useState(false);

  const { data, error, loading } = usePostQuery<ApiConversion[]>(
    `/api/conversions/query?cb=${cacheBuster}`,
    req
  );

  useEffect(() => {
    startPolling(() => {
      setCacheBuster(crypto.randomUUID());
    }, 30);
  }, []);

  useEffect(() => {
    if (error) {
      const errorData = error.response?.data as { IsTranslated?: boolean; Exceptions?: string[] };
      if (errorData?.IsTranslated && errorData?.Exceptions) {
        setAlertMessage({ message: errorData.Exceptions[0], severity: "error" });
      } else {
        setAlertMessage({ message: t`An unexpected error occurred`, severity: "error" });
      }
    }
  }, [error]);

  const fetchData = useCallback(async () => {
    const resp = await generalGet(`/api/conversions/statistics?cb=${cacheBuster}`);
    if (resp) {
      setStatData(resp.data as ApiStatistics);
      setStatLoading(false);
    } else {
      setStatError(true);
      setStatLoading(false);
      setAlertMessage({ message: t`Failed to fetch statistics`, severity: "error" });
    }
  }, [cacheBuster]);

  usePolling(fetchData, 30);

  useEffect(() => {
    if (initialLoading && !loading && (error || data)) {
      setInitialLoading(false);
    }
  }, [loading, initialLoading, error, data]);

  useEffect(() => {
    const searchParams = new URLSearchParams();
    Object.entries(req).forEach(([key, value]) => {
      if (key === "paging" && value && typeof value === "object" && "number" in value) {
        searchParams.append("page", value.number.toString());
      } else if (value) {
        searchParams.append(key, value.toString());
      }
    });

    navigate({ pathname: "/conversions", search: searchParams.toString() });
  }, [req, navigate]);

  const handleExportConversion = async () => {
    setExporting(true);
    try {
      const { blob, name } = await postPackageBlob("api/conversions/export", req);
      DownloadAsBlob(blob, name);
      setExporting(false);
      setAlertMessage({ message: t`Export successful`, severity: "success" });
    } catch (ex) {
      setExporting(false);
      setAlertMessage({ message: t`Could not export. Try again later`, severity: "error" });
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleCloseFilter = () => setAnchorEl(null);

  const startTime = useMemo(() => moment().subtract(4, "hours").format("HH:mm"), []);

  return (
    <>
      <AppBar position="fixed" sx={{ mt: 8, backgroundColor: "white", opacity: 1, zIndex: 3 }}>
        <Container sx={{ maxWidth: 1900 }} maxWidth={false}>
          <RecentActivityBar
            statData={statData}
            statLoading={statLoading}
            statError={statError}
            currentConversionRequestNumber={req.paging.number}
            changeReqValue={changeReqValue}
            startTime={startTime}
          />
        </Container>
      </AppBar>
      <ContainerWrapper>
        <Container maxWidth={false} sx={{ ml: 0, mb: 10, overflow: "auto" }} component="div">
          <Paper elevation={0} sx={{ minHeight: 650, overflow: "auto", display: "flex", justifyItems: "center", alignItems: "center", flexDirection: "column" }}>
            <Container maxWidth={false}>
              <ContentHeaderToolbar
                titleIcon={<CachedIcon fontSize="large" />}
                title={t`Conversions`}
                conversionSearchAction={true}
                actions={[
                  {
                    id: "filter",
                    disabled: false,
                    title: t`Filter`,
                    turnedOn: JSON.stringify(req) !== JSON.stringify(EmptyConversionQuery),
                    icon: FilterAltOutlinedIcon,
                    clickAction: handleClickFilter,
                  },
                ]}
                CreateButton={true}
                createButtonWidth={220}
                showExportButton
                onExport={handleExportConversion}
                disableExportButton={isConversionQueryRequestEmpty(req) || exporting}
                showIcons
              />
            </Container>
            {initialLoading && (
              <Container maxWidth={false} sx={{ minHeight: 550 }}>
                <Box sx={{ minHeight: 550, display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <CircularProgress />
                </Box>
              </Container>
            )}
            {!initialLoading && !data && (
              <EmptyTableAlert
                severity="info"
                subTitle={t`No data found for conversions`}
                mainIcon={<InboxIcon sx={{ fontSize: 75, mb: 2, fill: "#e6e6e6" }} />}
                showIcon={true}
              />
            )}
            {!initialLoading && data && (
              <Container maxWidth={false} sx={{ backgroundColor: "transparent", pr: "0!important", pl: "0!important" }}>
                <DataTable<ApiConversion>
                  noWidth={true}
                  columns={conversionTableColumns()}
                  data={data}
                  expandedRowComponent={ConversionExpandedatableRow}
                  expandOnRowClick
                />
                {data.length === req.paging.count || req.paging.number > 1 ? (
                  <TablePagination
                    component="div"
                    count={-1}
                    rowsPerPage={25}
                    rowsPerPageOptions={[]}
                    page={req.paging.number - 1}
                    SelectProps={{ inputProps: { "aria-label": "rows per page" }, native: true }}
                    onPageChange={(_, pageNumber) => changeReqValue("paging", pageNumber + 1)}
                    showFirstButton
                  />
                ) : null}
                <AlertSnackbar
                  alertMessage={alertMessage}
                  updateAlertStatus={setAlertMessage}
                />
                <Popover
                  id={anchorEl ? "simple-popover" : undefined}
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handleCloseFilter}
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                  <ConversionFilterMenu
                    request={req}
                    changeReqValue={changeReqValue}
                    clearSearchParams={() => setReq(EmptyConversionQuery)}
                    hasUrlParams={() => {
                      return !isConversionQueryRequestEmpty(req);
                    }}
                    currentConversionRequest={req}
                  />
                </Popover>
              </Container>
            )}
          </Paper>
        </Container>
      </ContainerWrapper>
    </>
  );
};

export default Conversions;
