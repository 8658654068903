import { ImportType } from "../components/taxonomies/taxonomyImportDialog";
import {
  taxonomyDataImportAllowedFileExtensions,
  taxonomyDataImportAllowedFileTypes,
  taxonomyImportAllowedFileExtensions,
  taxonomyImportAllowedFileTypes,
} from "./constants";

export const validateAllowedTaxImportFileType = (
  type: string,
  importType: ImportType | undefined
): boolean => {
  const acceptedFileTypes =
    importType === "data"
      ? taxonomyDataImportAllowedFileTypes
      : taxonomyImportAllowedFileTypes;

  return acceptedFileTypes.includes(type.toLowerCase());
};

export const validateAllowedTaxImportFileExtension = (
  ext: string,
  importType: ImportType | undefined
): boolean => {
  const acceptedFileTypes =
    importType === "data"
      ? taxonomyDataImportAllowedFileExtensions
      : taxonomyImportAllowedFileExtensions;

  return acceptedFileTypes.includes(ext.toLowerCase());
};
