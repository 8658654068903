import { Trans, t } from "@lingui/macro";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { validationTable } from "../../api/types";
import { createNewValidation } from "../../api/validation";
import { assignDeep } from "../../helpers/assignDeep";

export interface CreateOrganizationDialogProps {
  open: boolean;
  onClose: (
    updateStatus: "none" | "success" | "error",
    reason?: string
  ) => void;
}

const initial = {
  id: "",
  createdAt: "",
  code: "",
  explanation: "",
  link: "",
  severityOverride: null,
};

const CreateValidationDialog = ({
  open,
  onClose,
}: CreateOrganizationDialogProps) => {
  const [currentRequest, setCurrentRequest] =
    useState<validationTable>(initial);
  const [saving, setSaving] = useState(false);

  const handleClose = (updateStatus: "none" | "success" | "error") => {
    setCurrentRequest({ ...initial });
    onClose(updateStatus);
  };

  const handleSubmit = async () => {
    setSaving(true);
    try {
      await createNewValidation(currentRequest);
      handleClose("success");
      setSaving(false);
    } catch (ex: any) {
      setSaving(false);
      onClose(
        "error",
        ex.response.data.IsTranslated
          ? ex.reponse.data.Exceptions[0]
          : t`Something went wrong`
      );
    }
  };

  const handleFieldValueChange = (path: string, val: any) => {
    const req = currentRequest;
    assignDeep(req, val, path);
    setCurrentRequest((prev) => ({ ...req }));
  };

  const severities = [
    "Error",
    "Warning",
    "Info",
    "Inconsistency",
    "FatalError",
    "Critical",
  ];

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={(reason) => {
        if (reason !== "backdropClick" || "escapeKeyDown") {
          onClose("none");
        }
      }}
    >
      <>
        <DialogTitle>
          <Trans>Create Validation</Trans>
        </DialogTitle>
        <DialogContent sx={{ minHeight: 210 }}>
          {saving && (
            <DialogContentText>
              <Trans>Hang in there, This might take a few minutes...</Trans>
            </DialogContentText>
          )}
          {!saving && (
            <>
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  sx={{ mt: 1, width: "100%" }}
                  value={currentRequest.code}
                  onChange={(e) =>
                    handleFieldValueChange("code", e.currentTarget.value)
                  }
                  label={t`Code *`}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  sx={{ mt: 1, width: "100%" }}
                  value={currentRequest.explanation}
                  onChange={(e) =>
                    handleFieldValueChange("explanation", e.currentTarget.value)
                  }
                  label={t`Explanation`}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  sx={{ mt: 1, width: "100%" }}
                  value={currentRequest.link}
                  onChange={(e) =>
                    handleFieldValueChange("link", e.currentTarget.value)
                  }
                  label={t`Link`}
                />
              </Grid>
              <Grid item xs={6} sx={{ mt: 2 }}>
                <FormControl fullWidth>
                  <InputLabel variant="standard" sx={{ width: "100%", ml: 2 }}>
                    {t`Override Severity`}
                  </InputLabel>
                  <Select
                    sx={{ width: "40%" }}
                    label={t`Severity`}
                    onChange={(e) =>
                      handleFieldValueChange(
                        "severityOverride",
                        e.target.value as string
                      )
                    }
                  >
                    {severities.map((severity) => (
                      <MenuItem key={severity} value={severity}>
                        {severity}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose("none");
            }}
          >
            <Trans>Close</Trans>
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={saving || currentRequest.code === ""}
          >
            {!saving && <Trans>Save</Trans>}
            {saving && <CircularProgress size={25} />}
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
};

export default CreateValidationDialog;
