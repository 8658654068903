import axios, { AxiosResponse } from "axios";
import { permissionSchemeTable } from "./types";

export const createNewPermissionScheme = async (
    payload: Pick<permissionSchemeTable, 'name' | 'priceInEuro'>
): Promise<AxiosResponse<void>> => {
    return axios.post(`/api/taxonomies/permissionSchemes/create`, payload);
}

export const toggleReflectPermissionSchemeDetails = async (
    id: string,
    payload: Pick<Pick<permissionSchemeTable, 'name' | 'priceInEuro'>,
        | "name"
        | "priceInEuro"
    >
): Promise<AxiosResponse<Pick<permissionSchemeTable, 'name' | 'priceInEuro'>>> => {
    return axios.post(`/api/taxonomies/permissionSchemes/${id}/update`, payload);
}

export const togglePermissionScheme = async (
    id: string,
    state: boolean
): Promise<AxiosResponse<void>> => {
    return axios.post(`/api/taxonomies/permissionSchemes/${id}/${state ? "activate" : "deactivate"}`);
}