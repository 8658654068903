import { AccessTokenRespons } from "../api/types";

const tokenStorageHelper: {
  token: string;
  expireAt: string | null;
  isExpired: boolean;
  isValid: boolean;
  isInvalid: boolean;
  isUserLoggedIn: boolean;
  setTokenValues: (resp: AccessTokenRespons) => void;
  clear: () => void;
} = {
  get token() {
    return localStorage.getItem("UserToken") || "";
  },

  get expireAt() {
    return localStorage.getItem("ExpireAt");
  },

  get isExpired() {
    return tokenStorageHelper.expireAt
      ? tokenStorageHelper.expireAt < new Date().toISOString()
      : true;
  },

  get isValid() {
    return (tokenStorageHelper.token && !tokenStorageHelper.isExpired) || false;
  },

  get isInvalid() {
    return !tokenStorageHelper.isValid;
  },

  get isUserLoggedIn() {
    return tokenStorageHelper.isValid && tokenStorageHelper.token.length > 0;
  },

  setTokenValues(tokenResponse: AccessTokenRespons) {
    localStorage.setItem("UserToken", tokenResponse.token);
    localStorage.setItem("ExpireAt", tokenResponse.expireAt.toString());
  },

  clear() {
    localStorage.clear();
  },
};

export default tokenStorageHelper;
