import { t } from "@lingui/macro";
import { i18n } from "@lingui/core";
import moment from "moment";
import { ApiTaxonomy } from "../../api/types";
import { DataTableColumn } from "../dataTable";

export function taxonomiesTableColumns(): DataTableColumn<ApiTaxonomy>[] {
  return [
    {
      header: {
        id: "id",
        label: t(i18n)`ID`,
      },
      key: "id",
    },
    {
      header: {
        id: "createdAt",
        label: t(i18n)`CREATED AT`,
      },
      key: "createdAt",
      valueFormatter: (row) =>
        moment(row.createdAt?.toString() || "").format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      header: {
        id: "packageName",
        label: t(i18n)`PACKAGE NAME`,
      },
      key: "packageName",
    },
    {
      header: {
        id: "systemName",
        label: t(i18n)`SYSTEM NAME`,
      },
      key: "systemName",
    },
    {
      header: {
        id: "isActive",
        label: t(i18n)`STATUS`,
      },
      key: "isActive",
      valueFormatter: (val) =>
        Boolean(val) === true ? t`Imported` : t`Importing`,
    },
  ];
}
