import { t } from "@lingui/macro";
import { Box, Container, Grid, Paper } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiConversion, ApiConversionStep } from "../../api/types";
import { startPolling } from "../../helpers/pollingHelper";
import { useGetQuery } from "../../hooks/useGetQuery";
import { ContainerWrapper } from "../containerWrapper";
import DataTable from "../dataTable";
import ConversionExpandedatableRow, {
  ConversionExpandedatableRowProps,
} from "./conversionExpandedatableRow";
import { conversionTableColumns } from "./conversionTableColumns";
import StepMessagesDialog from "./steps/stepMessagesDialog";
import CachedIcon from "@mui/icons-material/Cached";
import ContentHeaderToolbar from "../contentHeaderToolbar";
import EmptyTableAlert from "../emptyTableAlert";
import InboxIcon from "@mui/icons-material/Inbox";

const ConversionDetailsPage = () => {
  const { id } = useParams();
  const [initialLoading, setInitialLoading] = useState(true);
  const [cacheBuster, setCacheBuster] = useState(crypto.randomUUID());
  const [intervalId, setIntervalId] = useState(-1);
  const {
    data: conversionData,
    loading: loadingConversionData,
    error: errorLoadingConversionData,
  } = useGetQuery<ApiConversion>(`/api/conversions/${id}?cb=${cacheBuster}`);

  const { data: conversionSteps, loading: loadingConversionSteps } =
    useGetQuery<ApiConversionStep[]>(
      `/api/conversions/${id}/steps?cb=${cacheBuster}`
    );
  const [selectedStepId, setSelectedStepId] = useState<string>();
  useEffect(() => {
    if (intervalId === -1) {
      setIntervalId(
        startPolling(() => {
          setCacheBuster(crypto.randomUUID());
        }, 5)
      );
    }
  }, [conversionData?.status, intervalId]);

  useEffect(() => {
    if (initialLoading && !loadingConversionSteps && !loadingConversionData) {
      setInitialLoading(false);
    }
  }, [initialLoading, loadingConversionSteps, loadingConversionData]);

  const expandedRowComponent = useMemo(
    () => (rowProps: ConversionExpandedatableRowProps) =>
      <ConversionExpandedatableRow {...rowProps} isOpen={true} />,
    []
  );

  return (
    <>
      <ContainerWrapper>
        <Container
          maxWidth={false}
          sx={{
            ml: 0,
            mb: 10,
            overflow: "auto",
          }}
          component="div"
        >
          <Paper
            elevation={0}
            sx={{
              maxHeight: 1500,
              minHeight: 650,
              overflow: "auto",
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Container disableGutters={true} maxWidth={false}>
              <ContentHeaderToolbar
                titleIcon={<CachedIcon fontSize="large" />}
                title={t`Conversion Details`}
                actions={[]}
                showIcons
              />
              <Box
                sx={{
                  borderRadius: 1,
                  backgroundColor: "white",
                }}
              >
                <Grid container spacing={2}>
                  {!initialLoading && errorLoadingConversionData && (
                    <EmptyTableAlert
                      severity="info"
                      subTitle={t`No data found for conversion`}
                      mainIcon={
                        <InboxIcon
                          sx={{ fontSize: 75, mb: 2, fill: "#e6e6e6" }}
                        />
                      }
                      showIcon={true}
                    />
                  )}
                  {conversionData &&
                    !initialLoading &&
                    !errorLoadingConversionData && (
                      <>
                        <Grid item xs={12} sm={12}>
                          {conversionSteps && (
                            <DataTable<ApiConversion>
                              noWidth={true}
                              columns={conversionTableColumns()}
                              data={conversionData ? [conversionData] : []}
                              expandedRowComponent={expandedRowComponent}
                              expandOnRowClick={true}
                            />
                          )}
                        </Grid>
                      </>
                    )}
                </Grid>
              </Box>
            </Container>
          </Paper>
        </Container>
      </ContainerWrapper>
      <StepMessagesDialog
        onClose={() => setSelectedStepId(undefined)}
        open={selectedStepId !== undefined}
        stepId={selectedStepId}
        stepName={
          conversionSteps?.find((step) => step.id === selectedStepId)?.name
        }
      />
    </>
  );
};

export default ConversionDetailsPage;
