import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Select,
} from "@mui/material";
import { validationTable } from "../../api/types";
import { useGetQuery } from "../../hooks/useGetQuery";
import { useState } from "react";
import { t } from "@lingui/macro";
import {
  deleteValidation,
  updateValidationDetails,
} from "../../api/validation";

export interface DialogProps {
  id: string | undefined;
  open: boolean;
  onClose: (
    updateStatus: "none" | "success" | "error",
    reason?: string
  ) => void;
}

export const ValidationById = ({ id, open, onClose }: DialogProps) => {
  const [cacheBuster, setCacheBuster] = useState(crypto.randomUUID());
  const { data, error, loading, setData } = useGetQuery<validationTable>(
    `/api/validation/entryinformation/${id}?cb=${cacheBuster}`,
    id === undefined
  );
  const [deleteDialog, setDeleteDialog] = useState(false);

  const handleFieldValueChange = (
    key: keyof validationTable | string | undefined,
    value: string | boolean
  ) => {
    if (key) {
      const updated = { ...data };
      (updated as any)[key] = value;
      setData(updated as validationTable);
      setHasChanges(true);
    }
  };

  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const handleSubmit = async () => {
    try {
      if (data && id) {
        await updateValidationDetails(id, data);
        setCacheBuster(crypto.randomUUID());
        onClose("success", t`Validation updated`);
      }
    } catch (ex: any) {
      console.log(ex);
      onClose(
        "error",
        ex.response.data.IsTranslated
          ? ex.response.data.Exceptions[0]
          : t`Error. Try again later`
      );
    }
  };

  const severities = [
    "Error",
    "Warning",
    "Info",
    "Inconsistency",
    "FatalError",
    "Critical",
  ];

  const handleDelete = async () => {
    try {
      if (data && id) {
        await deleteValidation(id);
        setCacheBuster(crypto.randomUUID());
        setDeleteDialog(false);
        onClose("success", t`Validation deleted`);
      }
    } catch (ex: any) {
      console.log(ex);
      onClose(
        "error",
        ex.response.data.IsTranslated
          ? ex.response.data.Exceptions[0]
          : t`Error. Try again later`
      );
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={(reason) => {
        if (reason !== "backdropClick" || "escapeKeyDown") {
          onClose("none");
          setHasChanges(false);
        }
      }}
    >
      <Box sx={{ minWidth: 450, minHeight: 450 }}>
        <DialogTitle>Edit: {data && data.code}</DialogTitle>
        <DialogContent>
          {loading && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
              <CircularProgress />
            </Box>
          )}
          {error && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
              <p>{t`Error. Try again later`}</p>
            </Box>
          )}
          {data && !loading && !error && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 5,
              }}
            >
              <Grid sm={12} sx={{ width: "100%" }}>
                <Grid item xs={6}>
                  <TextField
                    variant="standard"
                    sx={{ mt: 1, width: "100%" }}
                    value={data.code}
                    onChange={(e) =>
                      handleFieldValueChange("code", e.currentTarget.value)
                    }
                    label={t`Code *`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="standard"
                    sx={{ mt: 1, width: "100%" }}
                    value={data.explanation}
                    onChange={(e) =>
                      handleFieldValueChange(
                        "explanation",
                        e.currentTarget.value
                      )
                    }
                    label={t`Explanation`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="standard"
                    sx={{ mt: 1, width: "100%" }}
                    value={data.link}
                    onChange={(e) =>
                      handleFieldValueChange("link", e.currentTarget.value)
                    }
                    label={t`Link`}
                  />
                </Grid>
                <Grid item xs={6} sx={{ mt: 2, mb: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      sx={{ width: "100%", ml: 2 }}
                    >
                      {t`Override Severity`}
                    </InputLabel>
                    <Select
                      sx={{ width: "40%" }}
                      label={t`Severity`}
                      value={data.severityOverride}
                      onChange={(e) =>
                        handleFieldValueChange(
                          "severityOverride",
                          e.target.value as string
                        )
                      }
                    >
                      {severities.map((severity) => (
                        <MenuItem key={severity} value={severity}>
                          {severity}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>
      </Box>
      <DialogActions sx={{ p: 2 }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button
              onClick={() => {
                setDeleteDialog(true);
              }}
              variant="contained"
              sx={{ backgroundColor: "red", color: "white" }}
            >{t`Delete`}</Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                onClose("none");
                setHasChanges(false);
              }}
            >{t`Close`}</Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={!hasChanges}
            >
              {t`Save`}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      <Dialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{t`Delete Validation`}</DialogTitle>
        <DialogContent>
          <p>{t`Are you sure you want to delete this validation?`}</p>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={() => setDeleteDialog(false)}>{t`Cancel`}</Button>
          <Button
            onClick={() => {
              handleDelete();
            }}
            variant="contained"
            sx={{ backgroundColor: "red", color: "white" }}
          >{t`Delete`}</Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default ValidationById;
