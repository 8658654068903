import React, { ChangeEvent, useEffect, useState } from 'react';
import { Autocomplete, Checkbox, FormControlLabel, Grid, ListItemText, MenuItem, Popper, TextField, Tooltip, Typography } from '@mui/material';
import { cDateSpectrums, cDimensionValueReference, cTagGroup, cTaxonomyElement } from '../api/types';

// Define the props interface for the component
interface LabeledTextFieldProps {
  label: string;
  fieldType?: "text" | "select" | "endpoint" | "checkbox" | "element" | "tagGroup" | "dateSpectrum" | "searchSelect" | "dimension";
  selectEndpoint?: string;
  options?: string[] | cDateSpectrums[] | cTagGroup[] | cDimensionValueReference[];
  placeholder?: string;
  value?: string | boolean | undefined;
  disabled?: boolean;
  endpointUrl?: string | undefined;
  onValueChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ElementDetails = ({ element, fontSize }: { element: cTaxonomyElement, fontSize: number, showMore?: boolean }) => (
  <>
    <Grid container direction="row" gap={1}>
      {[
        { label: 'Dimension Value', value: element.extension.name },
        { label: 'Company', value: element.extension?.parentReference?.name },
        { label: 'Children', value: element.extension.childElements.length },
        { label: 'Parent', value: element.extension?.parentReference?.name },
      ].map((item, index) => (
        <Grid item key={index} sx={{ minWidth: 0 }}>
          <Typography fontSize={fontSize} align='left' fontWeight="bold" color="grey" noWrap>
            {item.label}:
          </Typography>
          <Tooltip title={item.value} arrow>
            <Typography fontSize={fontSize} color="black" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', minWidth: 0 }}>
              {item.value}
            </Typography>
          </Tooltip>
        </Grid>
      ))}
    </Grid>
    <Grid container direction="row" gap={1}>
      <Grid item sx={{ minWidth: 0 }}>
        <Typography fontSize={fontSize} fontWeight="bold" color="grey" noWrap>
          Taxonomies:
        </Typography>
        <Grid container direction="row" spacing={0.5} sx={{ minWidth: 0 }}>
          {element.extension.taxonomies.map((taxonomy, index) => (
            <Grid item key={index} sx={{
              border: "1px solid #ededed",
              backgroundColor: "#ededed",
              borderRadius: 2,
              mt: 0.5,
              ml: 0.5,
              p: 0.5,
              minWidth: 0
            }}>
              <Tooltip title={taxonomy} arrow>
                <Typography fontSize={8} noWrap>
                  {taxonomy}
                </Typography>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  </>
);

const DimensionDetails = ({ element, fontSize }: { element: cDimensionValueReference, fontSize: number }) => (
  <>
    <Grid container direction="row" gap={1}>
      {[
        { label: 'Dimension Value', value: element.dimensionValue.dimensionName },
        { label: 'Company', value: element.dimensionValue.name || 'N/A' },
        { label: 'Children', value: element.dimensionValue.taxonomyValue?.length || '0' },
        { label: 'Parent', value: element.dimensionValue.taxonomyValue?.[0]?.dimensionValueReference?.name || 'N/A' }
      ].map((item, index) => (
        <Grid item key={index} sx={{ minWidth: 0 }}>
          <Typography fontSize={fontSize} align='left' fontWeight="bold" color="grey" noWrap>
            {item.label}:
          </Typography>
          <Tooltip title={item.value} arrow>
            <Typography fontSize={fontSize} color="black" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', minWidth: 0 }}>
              {item.value}
            </Typography>
          </Tooltip>
        </Grid>
      ))}
    </Grid>
    <Grid container direction="row" gap={1}>
      <Grid item sx={{ minWidth: 0 }}>
        <Typography fontSize={fontSize} fontWeight="bold" color="grey" noWrap>
          Taxonomies:
        </Typography>
        <Grid container direction="row" spacing={0.5} sx={{ minWidth: 0 }}>
          {element.dimensionValue.taxonomyValue?.map((taxonomy, index) => (
            <Grid item key={index} sx={{
              border: "1px solid #ededed",
              backgroundColor: "#ededed",
              borderRadius: 2,
              mt: 0.5,
              ml: 0.5,
              p: 0.5,
              minWidth: 0
            }}>
              <Tooltip title={taxonomy.dimensionReference?.name || "No Name"} arrow>
                <Typography fontSize={8} noWrap>
                  {taxonomy.dimensionReference?.name || "No Name"}
                </Typography>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  </>
);

const LabeledTextField: React.FC<LabeledTextFieldProps> = ({
  label,
  fieldType,
  options = [],
  placeholder,
  value,
  disabled,
  endpointUrl,
  onValueChange,
}) => {
  const [optionsState, setOptionsState] = useState<string[] | cDateSpectrums[] | cTagGroup[] | cTaxonomyElement[] | cDimensionValueReference[]>([]);
  const [selectedElement, setSelectedElement] = useState<cTaxonomyElement | cDimensionValueReference | null>(null);
  const [selectedDateSpectrum, setSelectedDateSpectrum] = useState<cDateSpectrums | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (endpointUrl) {
          const response = await fetch(endpointUrl);
          const data = await response.json();

          switch (fieldType) {
            case "dimension":
              setOptionsState(data as cDimensionValueReference[]);
              break
            case "element":
              setOptionsState(data as cTaxonomyElement[]);
              break;
            case "tagGroup":
              setOptionsState(data as cTagGroup[]);
              break;
            case "dateSpectrum":
              setOptionsState(data as cDateSpectrums[]);
              break;
            case "endpoint":
              setOptionsState(data as string[]);
              break;
            default:
              break;
          }
        }
      } catch (error) {
        console.error("Failed to fetch data from endpoint:", error);
      }
    };

    if (["endpoint", "element", "tagGroup", "dateSpectrum"].includes(fieldType || "")) {
      fetchData();
    } else {
      if (fieldType !== "element") {
        setOptionsState(options);
      }
    }
  }, [fieldType, endpointUrl, options]);

  const renderFieldType = () => {

    switch (fieldType) {
      case "dimension":
        return (
          <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid item xs={4}>
              <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 12 }} />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={optionsState as cDimensionValueReference[]}
                getOptionLabel={(option) => option.dimensionValue?.dimensionName || ""}
                renderOption={(props, option) => (
                  <li {...props} style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    border: "1px solid #e6e6e6",
                    padding: 4,
                    fontSize: 6,
                    justifyContent: "flex-start",
                  }}>
                    <DimensionDetails element={option} fontSize={10} />
                  </li>
                )}
                value={selectedElement as cDimensionValueReference}
                onChange={(event, newValue) => {
                  setSelectedElement(newValue || null);
                  console.log("Selected Element:", newValue);
                  if (onValueChange) {
                    onValueChange(event as ChangeEvent<HTMLInputElement>);
                  }
                }}

                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={placeholder || ""}
                    sx={{ width: "100%" }}
                    inputProps={{ ...params.inputProps, style: { fontSize: 12 } }}
                    InputLabelProps={{ style: { fontSize: 12 } }}
                    variant="standard"
                    disabled={disabled}
                  />
                )}
                PopperComponent={({ children, ...popperProps }) => (
                  <Popper {...popperProps}>
                    {children}
                  </Popper>
                )}
              />
              {selectedElement && <DimensionDetails element={selectedElement as cDimensionValueReference} fontSize={10} />}
            </Grid>
          </Grid>
        );

      case "element":
        return (
          <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid item xs={4}>
              <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 12 }} />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={optionsState as cTaxonomyElement[]}
                getOptionLabel={(option) => option.extension.name || ""}
                renderOption={(props, option) => (
                  <li {...props} style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    border: "1px solid #e6e6e6",
                    padding: 4,
                    fontSize: 6,
                    justifyContent: "flex-start",
                  }}>
                    <ElementDetails element={option} fontSize={10} />
                  </li>
                )}
                value={selectedElement as cTaxonomyElement}
                onChange={(event, newValue) => {
                  setSelectedElement(newValue || null);
                  console.log("Selected Element:", newValue);
                  if (onValueChange) {
                    onValueChange(event as ChangeEvent<HTMLInputElement>);
                  }
                }}

                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={placeholder || ""}
                    sx={{ width: "100%" }}
                    inputProps={{ ...params.inputProps, style: { fontSize: 12 } }}
                    InputLabelProps={{ style: { fontSize: 12 } }}
                    variant="standard"
                    disabled={disabled}
                  />
                )}
                PopperComponent={({ children, ...popperProps }) => (
                  <Popper {...popperProps}>
                    {children}
                  </Popper>
                )}
              />
              {selectedElement && <ElementDetails element={selectedElement as cTaxonomyElement} fontSize={10} />}
            </Grid>
          </Grid>
        );

      case "searchSelect":
        return (
          <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid item xs={4}>
              <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 12 }} />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={options as string[]}
                getOptionLabel={(option) => option}
                value={value?.toString() || ""}
                onChange={(event, newValue) => {
                  if (onValueChange) {
                    const newEvent = {
                      ...event,
                      target: {
                        ...event.target,
                        value: newValue || "",
                      },
                    } as ChangeEvent<HTMLInputElement>;
                    onValueChange(newEvent);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={placeholder || ""}
                    sx={{ width: "100%" }}
                    inputProps={{ ...params.inputProps, style: { fontSize: 12 } }}
                    InputLabelProps={{ style: { fontSize: 12 } }}
                    variant="standard"
                    disabled={disabled}
                  />
                )}
              />
            </Grid>
          </Grid>
        );

      case "select":
        return (
          <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid item xs={4}>
              <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 12 }} />
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                sx={{ width: "100%" }}
                inputProps={{ style: { fontSize: 12 } }}
                InputLabelProps={{ style: { fontSize: 12 } }}
                variant="standard"
                placeholder={placeholder || ""}
                value={value || ""}
                disabled={disabled}
                onChange={(event) => onValueChange && onValueChange(event as ChangeEvent<HTMLInputElement>)}
              >
                {(optionsState as string[]).map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        );

      case "tagGroup":
        return (
          <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid item xs={4}>
              <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 12 }} />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={optionsState as cTagGroup[]}
                getOptionLabel={(option) => option.name}
                value={
                  (optionsState as cTagGroup[]).find((option) => option.id === value) || null
                }
                onChange={(event, newValue) => {
                  if (onValueChange) {
                    const newEvent = {
                      ...event,
                      target: {
                        ...event.target,
                        value: newValue ? newValue.id : "",
                      },
                    } as ChangeEvent<HTMLInputElement>;
                    onValueChange(newEvent);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={placeholder || ""}
                    inputProps={{ ...params.inputProps, style: { fontSize: 12 } }}
                    InputLabelProps={{ style: { fontSize: 12 } }}
                    variant="standard"
                    disabled={disabled}
                  />
                )}
              />
            </Grid>
          </Grid>
        );

      case "dateSpectrum":
        return (
          <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid item xs={4}>
              <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 12 }} />
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                sx={{ width: "100%" }}
                inputProps={{ style: { fontSize: 12 } }}
                InputLabelProps={{ style: { fontSize: 12 } }}
                variant="standard"
                placeholder={placeholder || ""}
                value={selectedDateSpectrum ? selectedDateSpectrum.dateSpectrum : ""}
                disabled={disabled}
                onChange={(event) => {
                  const selectedOption = (optionsState as cDateSpectrums[]).find(
                    (option) => option.dateSpectrum === event.target.value
                  );
                  setSelectedDateSpectrum(selectedOption || null);
                  if (onValueChange) {
                    onValueChange(event as ChangeEvent<HTMLInputElement>);
                  }
                }}
              >
                {(optionsState as cDateSpectrums[]).map((option, index) => (
                  <MenuItem key={index} value={option.dateSpectrum}>
                    {option.dateSpectrum}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        );

      case "checkbox":
        return (
          <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid item xs={4}>
              <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 12 }} />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                sx={{
                  p: 1,
                  height: 10,
                  ':focus': {
                    backgroundColor: "orange",
                  },
                }}
                control={
                  <Checkbox

                    checked={Boolean(value)}
                    onChange={(event) => {
                      if (onValueChange) {
                        onValueChange(event as ChangeEvent<HTMLInputElement>);
                      }
                    }}
                  />
                } label={undefined}
              />
            </Grid>
          </Grid>
        );

      case "text":
        return (
          <Grid container spacing={0} sx={{ mb: 2 }}>
            <Grid item xs={4}>
              <ListItemText primary={label} primaryTypographyProps={{ fontWeight: 600, fontSize: 12 }} />
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{ width: "100%" }}
                inputProps={{ style: { fontSize: 12 } }}
                InputLabelProps={{ style: { fontSize: 12 } }}
                variant="standard"
                placeholder={placeholder || ""}
                value={value || ""}
                disabled={disabled}
                onChange={(event) => onValueChange && onValueChange(event as ChangeEvent<HTMLInputElement>)}
              />
            </Grid>
          </Grid>
        );

      default:
        return null;
    }
  };

  return renderFieldType();
};

export default LabeledTextField;