import axios, { AxiosResponse } from "axios";
import { validationTable } from "./types";

export const createNewValidation = async (
    payload: validationTable
): Promise<AxiosResponse<void>> => {
    return axios.post(`/api/validation/entryinformation/create`, payload);
}

export const updateValidationDetails = async (
    id: string,
    payload: Pick<validationTable,
        | "code"
        | "explanation"
        | "link"
        | "severityOverride"
    >
): Promise<AxiosResponse<validationTable>> => {
    return axios.post(`/api/validation/entryinformation/${id}/update`, payload);
}

export const deleteValidation = async (
    id: string
): Promise<AxiosResponse<void>> => {
    return axios.delete(`/api/validation/entryinformation/${id}/delete`);
}