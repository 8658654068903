import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import { t } from "@lingui/macro";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useState } from "react";

export interface ConversionSearchByIdDialogProps {}

const ConversionSearchByIdDialog = () => {
  const [searchValue, setSearchValue] = useState("");

  return (
    <OutlinedInput
      sx={{ width: 450 }}
      placeholder={t`Insert Conversion Id`}
      type="text"
      value={searchValue}
      onKeyDown={(e) => {
        if (e.key === "Enter" && searchValue.length > 0) {
          window.open(`/conversions/${searchValue}`);
        }
      }}
      onChange={(e) => setSearchValue(e.currentTarget.value)}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            disabled={searchValue.length === 0}
            onClick={() => window.open(`/conversions/${searchValue}`)}
          >
            <ArrowForwardIcon />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export default ConversionSearchByIdDialog;
