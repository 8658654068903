import { Trans, t } from "@lingui/macro";
import {
  Alert,
  Box,
  Collapse,
  Container,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Snackbar,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ListItem from "@mui/material/ListItem/ListItem";
import moment from "moment";
import { useEffect, useState } from "react";
import { ApiConversion, ApiConversionStep } from "../../api/types";
import {
  ExpandedBorderColor,
  taxonomyViewNoValueText,
} from "../../helpers/constants";
import { startPolling, stopPolling } from "../../helpers/pollingHelper";
import { useGetQuery } from "../../hooks/useGetQuery";
import DataTable from "../dataTable";
import { Loader } from "../loader";
import StepMessagesDialog from "./steps/stepMessagesDialog";
import { stepsTableColumns } from "./steps/stepsTableColumns";
import { generalGet } from "../../api/general";
import Select from "@mui/material/Select";
import { DownloadAsBlob } from "../../helpers/downloadAsBlob";
import { DownloadArtifact } from "../../helpers/downloadArtifact";

export interface ConversionExpandedatableRowProps {
  rowData: ApiConversion;
  columnCount: number;
  isOpen: boolean;
}

const ConversionExpandedatableRow = ({
  rowData,
  columnCount,
  isOpen,
}: ConversionExpandedatableRowProps) => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [cacheBuster, setCacheBuster] = useState(crypto.randomUUID());
  const [conversionIntervalId, setConversionIntervalId] = useState(-1);
  const [artifactIntervalId, setArtifactIntervalId] = useState(-1);
  const [selectedStepId, setSelectedStepId] = useState<string>();
  const [artifactsData, setArtifactsData] = useState<string[]>([]);
  const [selectedArtifact, setSelectedArtifact] = useState<string>("");
  const [artifactLoading, setArtifactLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const { data, loading } = useGetQuery<ApiConversionStep[]>(
    `/api/conversions/${rowData.id}/steps?cb=${cacheBuster}`,
    !isOpen
  );

  useEffect(() => {
    if (openAlert) {
      setTimeout(() => {
        setOpenAlert(false);
      }, 5000);
    }
  }, [openAlert]);

  const fetchData = async () => {
    const data = await generalGet(
      `/api/conversions/artifacts/${rowData.id}/types?cb=${cacheBuster}`
    );
    setArtifactsData(data.data as string[]);
    setCacheBuster(crypto.randomUUID());
  };

  useEffect(() => {
    if (isOpen) {
      fetchData();
      setCacheBuster(crypto.randomUUID());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      if (rowData.status === "Running" && conversionIntervalId === -1) {
        setConversionIntervalId(
          startPolling(() => {
            try {
              fetchData();
            } catch (ex: any) {
              setAlertMessage(
                ex.response.data.IsTranslated
                  ? ex.response.data.Exceptions[0]
                  : t`Error getting artifacts`
              );
              setOpenAlert(true);
            }
            setCacheBuster(crypto.randomUUID());
          }, 5)
        );
      }
    } else if (rowData.status === "Completed") {
      stopPolling(conversionIntervalId);
      stopPolling(artifactIntervalId);
      setConversionIntervalId(-1);
      setArtifactIntervalId(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData.status, conversionIntervalId, isOpen]);

  useEffect(() => {
    if (initialLoading && !loading) {
      setInitialLoading(false);
    }
  }, [initialLoading, loading]);

  const handleArtifactChange = async () => {
    try {
      setArtifactLoading(true);
      const res = await DownloadArtifact(rowData.id, selectedArtifact);
      DownloadAsBlob(res.blob, res.name);
      setArtifactLoading(false);
    } catch (ex: any) {
      setAlertMessage(
        ex.response.data.IsTranslated
          ? ex.response.data.Exceptions[0]
          : t`An error occurred while downloading the artifact.`
      );
      setOpenAlert(true);
      setArtifactLoading(false);
    }
  };

  return (
    <>
      <TableRow key={`table_row_${rowData.id}_expanded`}>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: "#c4c6c6",
          }}
          colSpan={columnCount + 1}
          sx={{ borderBottom: "unset" }}
        >
          <Collapse in={isOpen}>
            <Box sx={{ backgroundColor: "transparent", pt: 4 }}></Box>
            <Container disableGutters={true} maxWidth={false} sx={{ mb: 2 }}>
              <Box
                sx={{
                  borderRadius: 1,
                  backgroundColor: "white",
                }}
              >
                <Grid container spacing={2} sx={{ ml: 0 }}>
                  <Grid
                    item
                    xs={1.3}
                    sx={{
                      borderRight: "1px solid" + ExpandedBorderColor,
                      borderBottom: "1px solid" + ExpandedBorderColor,
                    }}
                  >
                    <ListItem disableGutters>
                      <ListItemText
                        primary={t`Period Start`}
                        secondary={
                          rowData.metaData.period.start !== null
                            ? moment(rowData.metaData.period.start).format(
                                "DD MMM YYYY"
                              )
                            : taxonomyViewNoValueText()
                        }
                        secondaryTypographyProps={{
                          noWrap: true,
                          whiteSpace: "initial",
                        }}
                      />
                    </ListItem>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      borderRight: "1px solid" + ExpandedBorderColor,
                      borderBottom: "1px solid" + ExpandedBorderColor,
                    }}
                  >
                    <ListItem disableGutters>
                      <ListItemText
                        primary={t`Period End`}
                        secondary={
                          rowData.metaData.period.end !== null
                            ? moment(rowData.metaData.period.end).format(
                                "DD MMM YYYY"
                              )
                            : taxonomyViewNoValueText()
                        }
                        secondaryTypographyProps={{
                          noWrap: true,
                          whiteSpace: "initial",
                        }}
                      />
                    </ListItem>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      borderRight: "1px solid" + ExpandedBorderColor,
                      borderBottom: "1px solid" + ExpandedBorderColor,
                    }}
                  >
                    <ListItem disableGutters>
                      <ListItemText
                        primary={t`Reporter Name`}
                        secondary={
                          rowData?.metaData?.reporter?.name ||
                          taxonomyViewNoValueText()
                        }
                        secondaryTypographyProps={{
                          noWrap: true,
                          whiteSpace: "initial",
                        }}
                      />
                    </ListItem>
                  </Grid>
                  <Grid
                    item
                    xs={1.6}
                    sx={{
                      borderRight: "1px solid" + ExpandedBorderColor,
                      borderBottom: "1px solid" + ExpandedBorderColor,
                    }}
                  >
                    <ListItem disableGutters>
                      <ListItemText
                        primary={t`Reporter Id`}
                        secondary={
                          rowData.metaData.reporter.identifier ||
                          taxonomyViewNoValueText()
                        }
                        secondaryTypographyProps={{
                          noWrap: true,
                          whiteSpace: "initial",
                        }}
                      />
                    </ListItem>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      borderRight: "1px solid" + ExpandedBorderColor,
                      borderBottom: "1px solid" + ExpandedBorderColor,
                    }}
                  >
                    <ListItem disableGutters>
                      <ListItemText
                        primary={t`Class`}
                        secondary={
                          rowData.metaData.reporter.accountingClass ||
                          taxonomyViewNoValueText()
                        }
                        secondaryTypographyProps={{
                          noWrap: true,
                          whiteSpace: "initial",
                        }}
                      />
                    </ListItem>
                  </Grid>
                  <Grid
                    item
                    xs={1.5}
                    sx={{
                      borderRight: "1px solid" + ExpandedBorderColor,
                      borderBottom: "1px solid" + ExpandedBorderColor,
                    }}
                  >
                    <ListItem disableGutters>
                      <ListItemText
                        primary={t`Partner Name`}
                        secondary={
                          rowData.metaData.partner.name ||
                          taxonomyViewNoValueText()
                        }
                        secondaryTypographyProps={{
                          noWrap: true,
                          whiteSpace: "initial",
                        }}
                      />
                    </ListItem>
                  </Grid>
                  <Grid
                    item
                    xs={1.5}
                    sx={{
                      borderRight: "1px solid" + ExpandedBorderColor,
                      borderBottom: "1px solid" + ExpandedBorderColor,
                    }}
                  >
                    <ListItem disableGutters>
                      <ListItemText
                        primary={t`Partner Id`}
                        secondary={
                          rowData.metaData.partner.identifier ||
                          taxonomyViewNoValueText()
                        }
                        secondaryTypographyProps={{
                          noWrap: true,
                          whiteSpace: "initial",
                        }}
                      />
                    </ListItem>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      borderBottom: "1px solid" + ExpandedBorderColor,
                      borderRight: "1px solid" + ExpandedBorderColor,
                    }}
                  >
                    <ListItem disableGutters>
                      <ListItemText
                        primary={t`Taxonomy Entry Point`}
                        secondary={
                          rowData.metaData.taxonomy.entryPoint ||
                          taxonomyViewNoValueText()
                        }
                        secondaryTypographyProps={{
                          noWrap: true,
                          whiteSpace: "initial",
                        }}
                      />
                    </ListItem>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      borderBottom: "1px solid" + ExpandedBorderColor,
                      borderRight: "1px solid" + ExpandedBorderColor,
                    }}
                  >
                    <ListItem disableGutters>
                      <ListItemText
                        primary={t`Taxonomy Id`}
                        secondary={
                          rowData.metaData.taxonomy.id ||
                          taxonomyViewNoValueText()
                        }
                        secondaryTypographyProps={{
                          noWrap: true,
                          whiteSpace: "initial",
                        }}
                      />
                    </ListItem>
                  </Grid>
                  <Grid
                    item
                    xs={2.9}
                    sx={{
                      borderBottom: "1px solid" + ExpandedBorderColor,
                      borderRight: "1px solid" + ExpandedBorderColor,
                    }}
                  >
                    <ListItem disableGutters>
                      <ListItemText
                        primary={t`Taxonomy Name`}
                        secondary={
                          rowData.metaData.taxonomy.name ||
                          taxonomyViewNoValueText()
                        }
                        secondaryTypographyProps={{
                          noWrap: true,
                          whiteSpace: "initial",
                        }}
                      />
                    </ListItem>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      borderBottom: "1px solid" + ExpandedBorderColor,
                      borderRight: "1px solid" + ExpandedBorderColor,
                    }}
                  >
                    <ListItem disableGutters>
                      <ListItemText
                        primary={t`File size`}
                        secondary={
                          "Input: " +
                            (
                              Number(rowData.metaData.inputFileSize) /
                              1024 /
                              1024
                            ).toFixed(2) +
                            " Mb / Output: " +
                            (
                              Number(rowData.metaData.outputFileSize) /
                              1024 /
                              1024
                            ).toFixed(2) +
                            " Mb" || taxonomyViewNoValueText()
                        }
                        secondaryTypographyProps={{
                          noWrap: true,
                          whiteSpace: "initial",
                        }}
                      />
                    </ListItem>
                  </Grid>
                  <Container maxWidth={false}>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        height: 120,
                        alignContent: "end",
                        alignItems: "end",
                      }}
                    >
                      <Grid item xs={7}>
                        <Typography variant="h5" sx={{}}>
                          Steps
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          variant="h5"
                          sx={{
                            flexGrow: 5,
                            width: "100%",
                            alignContent: "flex-end",
                          }}
                        >
                          {/* Artifacts */}
                        </Typography>
                      </Grid>
                      {artifactsData.length > 0 && (
                        <>
                          <Grid item xs={2}>
                            <FormControl fullWidth>
                              <InputLabel
                                variant="standard"
                                sx={{ width: "100%", ml: 2 }}
                              >
                                Select Artifact
                              </InputLabel>
                              <Select
                                sx={{ width: "100%" }}
                                value={selectedArtifact}
                                onChange={(e) =>
                                  setSelectedArtifact(e.target.value)
                                }
                                label="Select Artifact"
                              >
                                {artifactsData.map((artifact) => (
                                  <MenuItem key={artifact} value={artifact}>
                                    {artifact}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={1}>
                            <LoadingButton
                              loading={artifactLoading}
                              onClick={() => handleArtifactChange()}
                              variant="contained"
                              sx={{
                                pt: 2,
                                pb: 2,
                                pl: 2,
                                pr: 2,
                                alignContent: "right",
                              }}
                            >
                              Download
                            </LoadingButton>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Container>
                      {loading && !data && data === null && (
                        <Loader height={400} />
                      )}
                    </Container>
                  </Container>
                  <Container
                    disableGutters={true}
                    maxWidth={false}
                    sx={{ mt: 5 }}
                  >
                    {!initialLoading && data && data.length > 0 && (
                      <DataTable<ApiConversionStep>
                        columns={stepsTableColumns()}
                        data={data}
                        onClickRow={(rowId) => setSelectedStepId(rowId)}
                        nested
                      />
                    )}
                    {initialLoading && <Loader height={650} />}
                  </Container>
                </Grid>
              </Box>
            </Container>
          </Collapse>
        </TableCell>
      </TableRow>
      <StepMessagesDialog
        onClose={() => setSelectedStepId(undefined)}
        open={selectedStepId !== undefined}
        stepId={selectedStepId}
        stepName={data?.find((step) => step.id === selectedStepId)?.name}
      />
      <Snackbar
        open={openAlert}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="error">
          <Trans>{alertMessage}</Trans>
        </Alert>
      </Snackbar>
    </>
  );
};

export default ConversionExpandedatableRow;
