import { t, Trans } from "@lingui/macro";
import {
  Chip,
  Collapse,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { ApiStepConversionMessage } from "../../../api/types";
import { getStatusColor } from "../../../helpers/getConversionStatusColoer";
import { useGetQuery } from "../../../hooks/useGetQuery";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";
import { Loader } from "./../../loader";
import { messageTextLength } from "../../../helpers/constants";
import { startPolling } from "../../../helpers/pollingHelper";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import EmptyTableAlert from "../../emptyTableAlert";
import InboxIcon from "@mui/icons-material/Inbox";

export interface StepMessagesDialogProps {
  stepId: string | undefined;
  stepName: string | undefined;
  open: boolean;
  onClose: () => void;
}

const StepMessagesDialog = ({
  stepId,
  stepName,
  open,
  onClose,
}: StepMessagesDialogProps) => {
  const [cacheBuster, setCacheBuster] = useState(crypto.randomUUID());
  const [intervalId, setIntervalId] = useState(-1);

  const { data, loading, setData } = useGetQuery<ApiStepConversionMessage[]>(
    `/api/conversions/steps/${stepId}/messages?cb=${cacheBuster}`,
    stepId === undefined
  );

  useEffect(() => {
    if (intervalId === -1 && open) {
      setIntervalId(
        startPolling(() => {
          setCacheBuster(crypto.randomUUID());
        }, 5)
      );
    }
  }, [intervalId, open]);

  const dialogBodyRef = useRef<any>(null);

  const handleClose = () => {
    setData([]);
    onClose();
  };

  useEffect(() => {
    if (!loading && data && data.length > 0) {
      dialogBodyRef.current?.focus();
    }
  }, [loading, data]);

  return (
    <Dialog open={open} maxWidth="lg" fullWidth onClose={handleClose}>
      {stepId && stepName && (
        <>
          <DialogTitle>
            {`${stepName} - `}
            <Trans>System Messages</Trans>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{ minHeight: 210 }}
            tabIndex={0}
            ref={dialogBodyRef}
          >
            {loading && !data && (
              <DialogContentText>
                <Loader height={50} />
              </DialogContentText>
            )}
            {data && data.length > 0 && (
              <TableContainer sx={{ overflowX: "hidden", overflowY: "auto" }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Trans>Category</Trans>
                      </TableCell>
                      <TableCell>
                        <Trans>Date</Trans>
                      </TableCell>
                      <TableCell>
                        <Trans>Message</Trans>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((message) => (
                      <Row message={message} stepId={stepId} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {!loading && data && data.length === 0 && (
              <EmptyTableAlert
                severity="info"
                subTitle={t`No messages found for selected step`}
                mainIcon={
                  <InboxIcon sx={{ fontSize: 75, mb: 2, fill: "#e6e6e6" }} />
                }
                showIcon={true}
              />
            )}
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default StepMessagesDialog;

interface RowType {
  message: ApiStepConversionMessage;
  stepId: string;
}

const Row = ({ message, stepId }: RowType) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow key={`message_table_row_${stepId}_${message.id}`}>
        <TableCell sx={{ maxWidth: 100 }}>
          <Chip
            sx={{ width: 90 }}
            label={message.severity}
            variant="filled"
            size="small"
            color={getStatusColor(message.severity)}
          />
        </TableCell>
        <TableCell sx={{ maxWidth: 200, minWidth: 180 }}>
          {moment(message.createdAt).format("DD/MM/YYYY HH:mm:ss")}
        </TableCell>
        <TableCell
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            maxWidth: 750,
            whiteSpace: "nowrap",
          }}
        >
          {message.text}
        </TableCell>
        <TableCell>
          {message.text.length > messageTextLength ? (
            <>
              {!open ? (
                <KeyboardArrowDownIcon
                  onClick={() => {
                    setOpen(!open);
                  }}
                  sx={{
                    cursor: "pointer",
                  }}
                />
              ) : (
                <KeyboardArrowUpIcon
                  onClick={() => {
                    setOpen(!open);
                  }}
                  sx={{
                    cursor: "pointer",
                  }}
                />
              )}
            </>
          ) : null}
        </TableCell>
      </TableRow>
      {message.text.length > messageTextLength && (
        <TableRow
          sx={{
            backgroundColor: open ? "#ededed" : "none",
            borderLeft: open ? "1.5px solid black" : "none",
          }}
        >
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              display: open ? "table-cell" : "none",
            }}
            colSpan={4}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Container maxWidth={"md"}>
                <code
                  style={{
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    display: "inline-block",
                    width: "100%",
                  }}
                >
                  {message.text}
                </code>
              </Container>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
