import { useEffect, useState } from "react";
import { formatTableTimeSpanValue } from "../helpers/formatTableDateValue";

export interface TimerDisplayTextProps {
  startValue: string | null;
}

const TimerDisplayText = ({ startValue }: TimerDisplayTextProps) => {
  const [currentValue, setCurrentValue] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentValue(formatTableTimeSpanValue(startValue || "", new Date()));
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [startValue]);

  return <>{currentValue}</>;
};

export default TimerDisplayText;
