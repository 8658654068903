import { Paper, Grid, Typography, Switch } from "@mui/material";
import { ApiOrganization } from "../api/types";

interface ToggleSwitcherProps {
  title: string;
  subText: string;
  onChange: (e: any) => void;
  currentvalue:
    | ApiOrganization["isCredentialAuthDisallowed"]
    | ApiOrganization["isMicrosoftAuthDisallowed"]
    | ApiOrganization["licensing"]["onlyAllowVisualFile"]
    | ApiOrganization["licensing"]["hasInspector"]
    | ApiOrganization["licensing"]["hasConverter"];
  handleFieldValueChange?: (
    key: keyof ApiOrganization | string | undefined,
    value: string | boolean
  ) => void;
}

export const ToggleSwitcher: React.FC<ToggleSwitcherProps> = ({
  title,
  subText,
  onChange,
  currentvalue,
}) => {
  return (
    <Paper
      elevation={0}
      sx={{
        border: "1px solid #e6e6e6",
        p: 1,
        borderRadius: 2,
        transition: "0.3s",
        "&:hover": {
          boxShadow: "0 0 10px rgba(0,0,0,0.1)",
        },
      }}
    >
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={12} md={8}>
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="caption">{subText}</Typography>
        </Grid>
        <Grid item xs={12} md={4} container justifyContent="flex-end">
          <Switch onChange={onChange} checked={currentvalue} />
        </Grid>
      </Grid>
    </Paper>
  );
};
