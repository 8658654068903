import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import { TaxonomyListProps } from "../../api/types";
import { t } from "@lingui/macro";
import { getAllTaxonomies } from "../../helpers/taxonomyHelpers";

interface SelectingTaxonomyProps {
  open: boolean;
  isDialog: boolean;
  onClose: () => void;
  onApply: (selectedTaxonomyIds: string[]) => void;
  preSelectedTaxonomies?: string[];
}

const SelectTaxonomies = ({
  open,
  isDialog,
  onClose,
  onApply,
  preSelectedTaxonomies,
}: SelectingTaxonomyProps) => {
  return (
    <>
      {isDialog ? (
        <Container maxWidth={false}>
          <Dialog open={open} onClose={onClose} fullWidth>
            <MainTaxonomySelector
              isDialog={isDialog}
              open={open}
              onClose={onClose}
              onApply={onApply}
              preSelectedTaxonomies={preSelectedTaxonomies}
            />
          </Dialog>
        </Container>
      ) : (
        <Container>
          <MainTaxonomySelector
            isDialog={isDialog}
            open={open}
            onClose={onClose}
            onApply={onApply}
            preSelectedTaxonomies={preSelectedTaxonomies}
          />
        </Container>
      )}
    </>
  );
};

const MainTaxonomySelector = ({
  isDialog,
  onApply,
  onClose,
  preSelectedTaxonomies,
}: SelectingTaxonomyProps) => {
  const [localTaxonomies, setLocalTaxonomies] = useState<TaxonomyListProps[]>([]);
  const [selectedTaxonomyIds, setSelectedTaxonomyIds] = useState<string[]>([]);

  useEffect(() => {
    const fetchTaxonomies = async () => {
      const allTaxonomies = await getAllTaxonomies();

      const updatedTaxonomies = allTaxonomies.map((taxonomy) => ({
        ...taxonomy,
        isSelected: preSelectedTaxonomies?.includes(taxonomy.id) || taxonomy.isSelected || false,
      }));

      setLocalTaxonomies(updatedTaxonomies);

      const initialSelectedIds = updatedTaxonomies
        .filter((taxonomy) => taxonomy.isSelected)
        .map((taxonomy) => taxonomy.id);

      setSelectedTaxonomyIds(initialSelectedIds);
    };

    fetchTaxonomies();
  }, [preSelectedTaxonomies]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    const updatedTaxonomies = localTaxonomies.map((taxonomy) => {
      if (taxonomy.id === value) {
        return {
          ...taxonomy,
          isSelected: checked,
        };
      }
      return taxonomy;
    });

    setLocalTaxonomies(updatedTaxonomies);

    const updatedSelectedIds = checked
      ? [...selectedTaxonomyIds, value]
      : selectedTaxonomyIds.filter((id) => id !== value);

    setSelectedTaxonomyIds(updatedSelectedIds);
    if (!isDialog) {
      onApply(updatedSelectedIds);
    }
  };

  const handleApply = () => {
    onApply(selectedTaxonomyIds);
    onClose();
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h5">{t`Select Taxonomies`}</Typography>
      <FormControl sx={{ minWidth: "100%" }}>
        <FormGroup sx={{ mt: 2 }}>
          {localTaxonomies.map((taxonomy) => (
            <FormControlLabel
              key={taxonomy.id}
              control={
                <Checkbox
                  checked={taxonomy.isSelected}
                  onChange={handleChange}
                  value={taxonomy.id}
                />
              }
              label={taxonomy.labels?.[0]?.text || taxonomy.id}
            />
          ))}
        </FormGroup>
      </FormControl>
      {isDialog && (
        <Button sx={{ mt: 5 }} variant="outlined" onClick={handleApply}>
          Apply
        </Button>
      )}
    </Box>
  );
};

export default SelectTaxonomies;