import {
  TableHead,
  TableRow,
  TableCell,
  styled,
  tableCellClasses,
  TableCellProps,
  Container,
  Box,
} from "@mui/material";
import React, { Fragment } from "react";

export interface TableColumnHeader<T> {
  id: keyof T | string;
  label: string;
  width?: number;
  optionsHeaderText?: string;
  options?: string[];
  shouldBeFiltered?: boolean;
  inputType?: "text" | "select";
}

export interface TableHeaderProps<T> {
  columnHeaders: TableColumnHeader<T>[];
  expandable?: boolean;
  nested?: boolean;
  setFilter?: (id: string, value: string) => void;
}

interface StyledTableCellProps extends TableCellProps {
  width?: number;
  nested?: boolean;
}

const StyledTableCell = styled((props: StyledTableCellProps) => {
  const { nested, width, ...nativeProps } = props;
  return (
    <TableCell sx={{ width: width ? width : undefined }} {...nativeProps}>
      {nativeProps.children}
    </TableCell>
  );
})(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#072a44",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function TableHeader<T>({
  columnHeaders,
  expandable,
  nested,
}: TableHeaderProps<T>) {
  return (
    <TableHead>
      <TableRow>
        {expandable && <StyledTableCell nested={nested}></StyledTableCell>}
        {columnHeaders.map((ch, index) => (
          <Container component={Fragment} key={index} sx={{
            display: "flex",
            flexDirection: "column",
          }}>
            <TableCell
              sx={{
                backgroundColor: "#072a44",
                color: (theme) => theme.palette.common.white,
              }}
              key={`head_column_${index}`}
              width={ch.width ? ch.width : undefined}
            >
              <Box>
                {ch.label}
              </Box>
            </TableCell>
          </Container>
        ))}
      </TableRow>
    </TableHead>
  );
}