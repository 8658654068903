import { t, Trans } from "@lingui/macro";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  ListItemText,
  Snackbar,
  Switch,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { ApiUserDetails } from "../../api/types";
import {
  reSendActivationEmail,
  toggleUserActivation,
  updateUserDetails,
} from "../../api/users";
import { useGetQuery } from "../../hooks/useGetQuery";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import { LoadingButton } from "@mui/lab";

export interface UserDetailsDialogProps {
  open: boolean;
  userId: string | undefined;
  onClose: (updateStatus: "none" | "success" | "error") => void;
  removeActivationToggleField?: boolean;
}

const UserDetailsDialog = ({
  open,
  userId,
  onClose,
  removeActivationToggleField,
}: UserDetailsDialogProps) => {
  const [cacheBuster, setCacheBuster] = useState(crypto.randomUUID());
  const { data, error, loading, setData } = useGetQuery<ApiUserDetails>(
    `/api/users/${userId}?cb=${cacheBuster}`,
    userId === undefined
  );
  const [originalTitle, setOriginalTitle] = useState("");
  const [updating, setUpdating] = useState(false);
  const [loadingResendActivation, setLoadingResendActivation] = useState(false);
  const [updateError, setUpdateError] = useState<string>();
  const [updateSuccess, setUpdateSuccess] = useState<string>();
  const [showConsentDialog, setShowConsentDialog] = useState(false);

  const handleFieldValueChange = (
    key: keyof ApiUserDetails | string | undefined,
    value: string | boolean
  ) => {
    if (key) {
      if (!originalTitle) {
        setOriginalTitle(data?.name || "");
      }
      const updated = { ...data };
      (updated as any)[key] = value;
      setData(updated as ApiUserDetails);
    }
  };

  const handleToggleUserActivation = async () => {
    setUpdating(true);
    try {
      await toggleUserActivation(
        userId as string,
        data?.isDeactiviated as boolean
      );
      setCacheBuster(crypto.randomUUID());
    } catch (ex: any) {
      setUpdateError(
        ex.response.data.IsTranslated
          ? ex.response.data.Exceptions[0]
          : t`Could not update user. Try again later`
      );
    } finally {
      setShowConsentDialog(false);
      setUpdating(false);
    }
  };

  const handleClose = (updateStatus: "none" | "success" | "error") => {
    if (updateStatus === "success") {
      setCacheBuster(crypto.randomUUID());
    }
    onClose(updateStatus);
    setOriginalTitle("");
    setUpdating(false);
  };

  const handleSubmit = async () => {
    setUpdating(true);
    try {
      if (data) {
        await updateUserDetails(userId as string, {
          name: data?.name,
          isAdministrator: data?.isAdministrator,
        });
      }
      handleClose("success");
    } catch (ex: any) {
      setUpdating(false);
      setUpdateError(
        ex.response.data.IsTranslated
          ? ex.response.data.Exceptions[0]
          : t`Could not update user. Try again later`
      );
    }
  };

  const handleResendActivationEmail = async () => {
    setLoadingResendActivation(true);
    try {
      if (data) {
        await reSendActivationEmail(data?.id);
        setUpdateSuccess(t`Activation email sent successfully`);
      }
      setLoadingResendActivation(false);
    } catch (ex: any) {
      setLoadingResendActivation(false);
      setUpdateError(
        ex.response.data.IsTranslated
          ? ex.response.data.Exceptions[0]
          : t`Could not resend activation email. Try again later`
      );
    }
  };

  return (
    <>
      <Dialog
        open={open}
        disableEscapeKeyDown
        maxWidth="lg"
        fullWidth
        onClose={(reason) => {
          if (reason !== "backdropClick" || "escapeKeyDown") {
            handleClose("none");
          }
        }}
      >
        {loading && (
          <CircularProgress
            sx={{ position: "absolute", left: "50%", top: "50%" }}
          />
        )}
        {!loading && error && (
          <Alert severity="error">
            <Trans>Could not load user details. Please try again later</Trans>
          </Alert>
        )}
        {!loading && !error && data && (
          <>
            <DialogTitle>{originalTitle || data.name}</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <ListItemText
                        primary={t`ID`}
                        primaryTypographyProps={{ fontWeight: 700 }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <ListItemText
                        title={data?.id}
                        secondary={data?.id}
                        secondaryTypographyProps={{
                          noWrap: true,
                          whiteSpace: "initial",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <ListItemText
                        primary={t`EMAIL`}
                        primaryTypographyProps={{ fontWeight: 700 }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <ListItemText
                        title={data?.emailAddress}
                        secondary={data?.emailAddress}
                        secondaryTypographyProps={{
                          noWrap: true,
                          whiteSpace: "initial",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <ListItemText
                        primary={t`NAME`}
                        primaryTypographyProps={{ fontWeight: 700 }}
                      />
                    </Grid>

                    <Grid item xs={8}>
                      <ListItemText>
                        <TextField
                          variant="standard"
                          value={data?.name}
                          onChange={(e) =>
                            handleFieldValueChange(
                              "name",
                              e.currentTarget.value
                            )
                          }
                        />
                      </ListItemText>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <ListItemText
                        primary={t`IS ADMIN`}
                        primaryTypographyProps={{ fontWeight: 700 }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <Switch
                        checked={data?.isAdministrator}
                        onChange={(e) =>
                          handleFieldValueChange(
                            "isAdministrator",
                            e.target.checked
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <ListItemText
                        primary={t`LOCKED OUT`}
                        primaryTypographyProps={{ fontWeight: 700 }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      {data?.isLockedOut && <LockOutlinedIcon />}
                      {!data?.isLockedOut && <LockOpenOutlinedIcon />}
                    </Grid>
                  </Grid>
                </Grid>
                {!removeActivationToggleField && (
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <ListItemText
                          primary={t`IS ACTIVATED`}
                          primaryTypographyProps={{ fontWeight: 700 }}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <Switch
                          checked={data?.isDeactiviated === false}
                          onChange={() => setShowConsentDialog(true)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <LoadingButton
                variant="outlined"
                loading={loadingResendActivation}
                onClick={() => handleResendActivationEmail()}
              >
                {t`Send signup email`}
              </LoadingButton>
              <Button variant="outlined" onClick={() => handleClose("none")}>
                <Trans>Close</Trans>
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={updating}
              >
                {!updating && <Trans>Update</Trans>}
                {updating && <CircularProgress size={25} />}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Dialog open={showConsentDialog}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Trans>You are about to</Trans>{" "}
            {data?.isDeactiviated ? t`activate` : t`deactivate`}{" "}
            <Trans>this user</Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConsentDialog(false)}>No</Button>
          <Button onClick={handleToggleUserActivation}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={updateError !== undefined}
        autoHideDuration={3000}
        onClose={() => setUpdateError(undefined)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="error">
          {updateError ? updateError : "Something went wrong"}
        </Alert>
      </Snackbar>
      <Snackbar
        open={updateSuccess !== undefined}
        autoHideDuration={3000}
        onClose={() => setUpdateSuccess(undefined)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="success">
          {updateSuccess ? updateSuccess : "Something went wrong"}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UserDetailsDialog;
