import { t } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { ApiUser, ApiUserDetails } from "../../api/types";
import { DataTableColumn } from "../dataTable";
import { Link, Switch, Tooltip } from "@mui/material";
import moment from "moment";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";

export function usersTableColumns(): DataTableColumn<ApiUser>[] {
  return [
    {
      header: {
        id: "name",
        label: t(i18n)`NAME`,
      },
      key: "name",
    },
    {
      header: {
        id: "organizationName",
        label: t(i18n)`ORGANIZATION NAME`,
      },
      key: "organizationName",
      valueFormatter: (row) => (
        <Link
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            window.open(`../organizations/${row.organizationId}`, "_blank");
          }}
        >
          {row.organizationName}
        </Link>
      ),
    },
    {
      header: {
        id: "createdAt",
        label: t(i18n)`CREATED AT`,
      },
      key: "createdAt",
      valueFormatter: (row) =>
        moment(row.createdAt.toString()).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      header: {
        id: "emailAddress",
        label: t(i18n)`EMAIL`,
      },
      key: "emailAddress",
    },
    {
      header: {
        id: "id",
        label: t(i18n)`ID`,
      },
      key: "id",
    },
    {
      header: {
        id: "isLockedOut",
        label: t(i18n)`LOCKED`,
      },
      key: "isLockedOut",
      valueFormatter: (row) => (Boolean(row.isDeactiviated) ? "Yes" : "No"),
    },
  ];
}

export function orgUsersTableColumns(
  toggleActivationHandler: (id: string) => void,
  includeAdminColumn: boolean
): DataTableColumn<ApiUserDetails>[] {
  const columns: DataTableColumn<ApiUserDetails>[] = [
    ...usersTableColumns().filter(
      (col: DataTableColumn<ApiUser>) => col.key !== "isLockedOut"
    ),
    {
      header: {
        id: "isDeactiviated",
        label: t(i18n)`Activated`,
      },
      key: "isDeactiviated",
      valueFormatter: (row) => (
        <Switch
          checked={!row.isDeactiviated}
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
          onClick={(e) => {
            e.stopPropagation();
            toggleActivationHandler(row.id);
          }}
        />
      ),
    },
  ];

  if (includeAdminColumn) {
    columns.push({
      header: {
        id: "isAdministrator",
        label: t(i18n)`Is Administrator`,
      },
      key: "isAdministrator",
      valueFormatter: (row) => {
        return row.isAdministrator ? (
          <Tooltip title={t`Administrator account`}>
            <SupervisorAccountIcon />
          </Tooltip>
        ) : (
          <></>
        );
      },
    });
  }
  return columns;
}
