import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
} from "@mui/material";
import { t } from "@lingui/macro";
import { removeSubscription } from "../../../api/organizations";

export interface RemoveSubscriptionProps {
  open: boolean;
  id: string | undefined;
  onClose: (updateStatus: "none" | "success" | "error", reason: string) => void;
}

const RemoveSubscriptionDialog = ({
  open,
  id,
  onClose,
}: RemoveSubscriptionProps) => {
  const handleClose = (
    updateStatus: "none" | "success" | "error",
    reason: string
  ) => {
    onClose(updateStatus, reason);
  };

  const handleSubmit = async () => {
    try {
      const removeSub = removeSubscription(id as string);
      await removeSub;
      handleClose("success", t`Subscription removed`);
    } catch (ex: any) {
      handleClose(
        "error",
        ex.response.data.IsTranslated
          ? ex.reponse.data.Exceptions[0]
          : t`Something went wrong`
      );
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={(reason) => {
          if (reason !== "backdropClick" || "escapeKeyDown") {
            handleClose("none", "");
          }
        }}
      >
        <DialogTitle>{t`Remove subscription?`}</DialogTitle>
        <DialogContent>
          {t`Please confirm that you'd like to remove the subscription.`}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose("none", "")}>{t`Cancel`}</Button>
          <Button
            variant="outlined"
            onClick={handleSubmit}
          >{t`Confirm`}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RemoveSubscriptionDialog;
