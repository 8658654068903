export const toCamelCase = (obj: any): {} => {
  if (!obj) return obj;
  let newO: any = undefined;
  let origKey = "";
  let newKey = "";
  let value = undefined;
  if (obj instanceof Array) {
    return obj.map(function (value) {
      if (typeof value === "object") {
        value = toCamelCase(value);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in obj) {
      if (obj.hasOwnProperty(origKey)) {
        newKey = (
          origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey
        ).toString();
        value = obj[origKey];
        if (
          value instanceof Array ||
          (value !== null && value.constructor === Object)
        ) {
          value = toCamelCase(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
};
