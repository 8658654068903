import { t } from "@lingui/macro";
import { ImportType } from "../components/taxonomies/taxonomyImportDialog";

export const getFileLocationHelperText = (
  fileCount: number,
  importType: ImportType | undefined
): string => {
  if (fileCount === 0) return "";
  if (importType === "data") {
    if (fileCount > 1) {
      return t`Full url ending with the directory where all the files exist externally`;
    } else {
      return t`Full url of the file ending with the file name where the file exists externally`;
    }
  }
  return "";
};
