import { useMemo, useCallback } from "react";
import { t, Trans } from "@lingui/macro";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import Container from "@mui/system/Container";
import AsyncAutoCompleteSelect from "../asyncAutoCompleteSelect";
import AsyncAutoCompleteTextField from "../asyncAutoCompleteTextField";
import { ApiTaxonomy, ConversionQueryRequest } from "../../api/types";

export interface ConversionFilterMenuProps {
  request: ConversionQueryRequest;
  changeReqValue: (key: string, value: any) => void;
  clearSearchParams: () => void;
  currentConversionRequest: ConversionQueryRequest;
  hasUrlParams: () => boolean;
}

const ConversionFilterMenu = ({
  request,
  changeReqValue,
  clearSearchParams,
  currentConversionRequest,
  hasUrlParams,
}: ConversionFilterMenuProps) => {
  const clearAllHandler = useCallback(() => {
    if (hasUrlParams()) {
      clearSearchParams();
    }
  }, [clearSearchParams, hasUrlParams]);

  const handleChangeReqValue = useCallback(
    (key: string, value: any) => {
      changeReqValue(key, value);
    },
    [changeReqValue]
  );

  const convertStartAtValue = useMemo<Moment | null>(() => {
    return request.ConvertStartAt ? moment(request.ConvertStartAt) : null;
  }, [request.ConvertStartAt]);

  const convertEndAtValue = useMemo<Moment | null>(() => {
    return request.ConvertEndAt ? moment(request.ConvertEndAt) : null;
  }, [request.ConvertEndAt]);

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 290,
        p: 2,
        zIndex: 5,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              zIndex: 5,
            }}
          >
            <Typography variant="h5" fontWeight="bold">
              Filter
            </Typography>
            {JSON.stringify(request) && hasUrlParams() && (
              <Button
                variant="outlined"
                title={t`Clear All`}
                onClick={clearAllHandler}
              >
                <ClearIcon /> Clear Filter(s)
              </Button>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <AsyncAutoCompleteTextField
            endpointUrl="/api/conversions/users/filter?keyword"
            label={t`Select User`}
            minimunSearchKeyCount={3}
            useEncodeURIComponent={false}
            selectedValueId={request.userId}
            onSelect={(val) => handleChangeReqValue("userId", val ? val : null)}
          />
        </Grid>
        <Grid item xs={12}>
          <AsyncAutoCompleteTextField
            endpointUrl="/api/conversions/organizations/filter?keyword"
            label={t`Select Organization`}
            minimunSearchKeyCount={3}
            useEncodeURIComponent={false}
            selectedValueId={request.organizationId}
            onSelect={(val) => handleChangeReqValue("organizationId", val ? val : null)}
          />
        </Grid>
        <Grid item xs={12}>
          <AsyncAutoCompleteTextField
            endpointUrl="/api/conversions/apps/filter?keyword"
            label={t`Select Application`}
            minimunSearchKeyCount={1}
            selectedValueId={request.appId}
            useEncodeURIComponent={true}
            onSelect={(val) => handleChangeReqValue("appId", val ? val : null)}
          />
        </Grid>
        <Grid item xs={12}>
          <AsyncAutoCompleteSelect<ApiTaxonomy>
            endpointUrl="api/conversions/taxonomies/all"
            alternateEndpointUrl="/api/taxonomies/"
            identifierProp="id"
            selectedValueId={request.taxonomyId}
            label={t`Select Taxonomy`}
            labelProp="systemName"
            onSelect={(val) =>
              handleChangeReqValue("taxonomyId", val ? val : null)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t`Reporter Identifier`}
            value={request.ReporterIdentifier || ""}
            sx={{ width: "100%" }}
            onChange={(e) =>
              handleChangeReqValue("ReporterIdentifier", e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <AsyncAutoCompleteTextField
            endpointUrl="/api/conversions/reporters/filter?keyword"
            label={t`Reporter Name`}
            minimunSearchKeyCount={1}
            selectedValueId={request.ReporterName}
            useEncodeURIComponent={true}
            onSelect={(val) => handleChangeReqValue("ReporterName", val ? val : null)}
          />
        </Grid>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              ampm={false}
              format="DD/MM/YYYY HH:mm"
              label={t`Imported After`}
              value={convertStartAtValue}
              disableFuture
              onChange={(newValue) => {
                handleChangeReqValue(
                  "ConvertStartAt",
                  newValue?.isValid() ? newValue : null
                );
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              ampm={false}
              format="DD/MM/YYYY HH:mm"
              label={t`Imported Before`}
              value={convertEndAtValue}
              onChange={(newValue) => {
                handleChangeReqValue(
                  "ConvertEndAt",
                  newValue?.isValid() ? newValue : null
                );
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="conversions_filter_status_field">
              <Trans>Status</Trans>
            </InputLabel>
            <Select
              labelId="conversions_filter_status_field"
              value={request.status || "none"}
              label={t`Status`}
              onChange={(e) =>
                handleChangeReqValue(
                  "status",
                  e.target.value === "none" ? null : e.target.value
                )
              }
              endAdornment={
                <IconButton
                  sx={{ display: request.status ? null : "none" }}
                  onClick={() => handleChangeReqValue("status", null)}
                >
                  <ClearIcon />
                </IconButton>
              }
            >
              <MenuItem value="none">
                <Trans>Any</Trans>
              </MenuItem>
              <MenuItem value="running">
                <Trans>Running</Trans>
              </MenuItem>
              <MenuItem value="completed">
                <Trans>Completed</Trans>
              </MenuItem>
              <MenuItem value="failed">
                <Trans>Failed</Trans>
              </MenuItem>
              <MenuItem value="cancelled">
                <Trans>Cancelled</Trans>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={currentConversionRequest.hasWarnings || false}
                onChange={(e) =>
                  handleChangeReqValue(
                    "hasWarnings",
                    e.target.checked ? true : null
                  )
                }
              />
            }
            label={t`Has Warnings`}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={currentConversionRequest.hasErrors || false}
                onChange={(e) =>
                  handleChangeReqValue(
                    "hasErrors",
                    e.target.checked ? true : null
                  )
                }
              />
            }
            label={t`Has Errors`}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ConversionFilterMenu;
