import React, { useState } from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableFooter,
    TablePagination,
    Paper,
    IconButton,
    TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

interface Label {
    lang: string;
    text: string;
}

interface LabelTableProps {
    initialLabels: Label[];
    onLabelsChange: (labels: Label[]) => void;
}

export default function LabelTable({ initialLabels, onLabelsChange }: LabelTableProps) {
    const [labels, setLabels] = useState<Label[]>(initialLabels);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // Handle pagination change
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Handle changes to the language or text fields
    const handleLabelChange = (index: number, field: keyof Label, value: string) => {
        const updatedLabels = [...labels];
        updatedLabels[index] = {
            ...updatedLabels[index],
            [field]: value,
        };
        setLabels(updatedLabels);
        onLabelsChange(updatedLabels);
    };

    // Add a new empty row to the table
    const handleAddRow = () => {
        setLabels([...labels, { lang: "", text: "" }]);
    };

    // Delete a row from the table
    const handleDeleteRow = (index: number) => {
        const updatedLabels = labels.filter((_, i) => i !== index);
        setLabels(updatedLabels);
        onLabelsChange(updatedLabels);
    };

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Language</TableCell>
                            <TableCell>Text</TableCell>
                            <TableCell align="right">
                                <IconButton onClick={handleAddRow}>
                                    <AddIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {labels
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((label, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <TextField
                                            value={label.lang}
                                            onChange={(e) => handleLabelChange(index, "lang", e.target.value)}
                                            placeholder="Language"
                                            fullWidth
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            value={label.text}
                                            onChange={(e) => handleLabelChange(index, "text", e.target.value)}
                                            placeholder="Text"
                                            fullWidth
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <IconButton onClick={() => handleDeleteRow(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                count={labels.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    );
}
