import { ApiConversion, ApiStepConversionMessage } from "../api/types";

export const getStatusColor = (
  status: ApiConversion["status"] | ApiStepConversionMessage["severity"]
):
  | "default"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning" => {
  if (status === "Completed") return "success";
  if (status === "Failed" || status === "Error") return "error";
  if (status === "Running" || status === "Warn") return "warning";
  if (status === "Info") return "info";
  if (status === "Debug") return "secondary";

  return "default";
};
