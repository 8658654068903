import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import tokenStorageHelper from "../../helpers/tokenStorage";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    tokenStorageHelper.clear();
    navigate("/login", { replace: true });
  }, [navigate]);

  return <></>;
};

export default Logout;
