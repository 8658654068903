import { ContainerWrapper } from "../containerWrapper";
import { Alert, Container, Paper, Snackbar } from "@mui/material";
import { t } from "@lingui/macro";
import ContentHeaderToolbar from "../contentHeaderToolbar";
import { permissionSchemeTable } from "../../api/types";
import DataTable from "../dataTable";
import EmptyTableAlert from "../emptyTableAlert";
import { useEffect, useState } from "react";
import { Loader } from "../loader";
import TaskIcon from "@mui/icons-material/Task";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { permissionSchemeColumns } from "./permissionSchemeColumns";
import CreateSchemeDialog from "./createSchemeDialog";
import SchemeDetailsDialog from "./permissionSchemeDetailsDialog";
import { useGetQuery } from "../../hooks/useGetQuery";

export const PermissionSchemes = () => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [cacheBuster, setCacheBuster] = useState(crypto.randomUUID());
  const [selectedPermissionScheme, setSelectedPermissionScheme] =
    useState<permissionSchemeTable>();
  const [currentQuery, setCurrentQuery] = useState("");
  const [alertMessage, setAlertMessage] = useState<
    | {
        message: string;
        severity: "error" | "warning" | "info" | "success" | undefined;
      }
    | undefined
  >(undefined);

  const { data, error, loading } = useGetQuery<permissionSchemeTable[]>(
    `/api/taxonomies/permissionSchemes/all?cb=${cacheBuster}`,
    cacheBuster === undefined
  );

  useEffect(() => {
    if (error) {
      const errorData = error?.response?.data as {
        IsTranslated?: boolean;
        Exceptions?: string[];
      };
      if (errorData?.IsTranslated === true && errorData?.Exceptions) {
        setAlertMessage({
          message: errorData.Exceptions[0],
          severity: "error",
        });
      }
    }
  }, [error]);

  useEffect(() => {
    if (initialLoading && !loading && (error || data)) {
      setInitialLoading(false);
    }
  }, [loading, initialLoading, error, data]);

  const [showCreateValidationDialog, setShowCreateValidationDialog] =
    useState(false);

  const handleCloseSchemeDialog = (
    updateStatus: "none" | "success" | "error",
    reason: string | undefined
  ) => {
    setSelectedPermissionScheme(undefined);
    setShowCreateValidationDialog(false);
    if (updateStatus === "success") {
      setAlertMessage({
        message: reason || "",
        severity: updateStatus,
      });
      /* Timeout, becuase otherwise it calls it to quickly and db is not updated  */
      setTimeout(() => {
        setCacheBuster(crypto.randomUUID());
      }, 100);
    } else {
      if (updateStatus === "error" && reason) {
        setAlertMessage({
          message: reason || "",
          severity: updateStatus,
        });
      }
    }
  };

  const handleClickRow = (rowId: string) => {
    setSelectedPermissionScheme(data?.find((x) => x.id === rowId) || undefined);
  };

  return (
    <ContainerWrapper>
      <Container
        maxWidth={false}
        sx={{
          ml: 0,
          mb: 10,
          overflow: "auto",
        }}
      >
        <Paper
          elevation={1}
          sx={{
            maxHeight: 1000,
            minHeight: 650,
            overflow: "auto",
            display: "flex",
            justifyItems: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Container maxWidth={false}>
            <ContentHeaderToolbar
              titleIcon={<TaskIcon fontSize="large" />}
              title={t`Permission Schemes`}
              actions={[
                {
                  id: "addNew",
                  title: t`Create Scheme`,
                  icon: AddIcon,
                  disabled: false,
                  turnedOn: false,
                  clickAction: () => setShowCreateValidationDialog(true),
                },
              ]}
              CreateButton={true}
              createButtonWidth={220}
              showIcons
              searchBar
              searchValue={currentQuery}
              onUpdateSearch={setCurrentQuery}
              searchPlaceholder={t`Search Permission Schemes`}
              searchBarWidth={400}
            />
          </Container>
          {loading && <Loader height={500} />}
          {currentQuery.length < 0 && (
            <EmptyTableAlert
              severity="info"
              title={t`No validation loaded`}
              subTitle={t`Use the search bar to find validations (minimum 3 characters)`}
              mainIcon={
                <SearchIcon sx={{ fontSize: 75, mb: 2, fill: "#e6e6e6" }} />
              }
              showIcon={true}
            />
          )}
          {!loading && !data && !initialLoading && (
            <EmptyTableAlert
              severity="info"
              title={t`No validation match your query`}
              subTitle={t`No luck, Try changing your search filter`}
              mainIcon={
                <ThumbDownOffAltIcon
                  sx={{ fontSize: 75, mb: 2, fill: "#e6e6e6" }}
                />
              }
              showIcon={true}
            />
          )}
          {!loading && data && (
            <DataTable<permissionSchemeTable>
              columns={permissionSchemeColumns()}
              data={data}
              onClickRow={handleClickRow}
              noWidth={true}
            />
          )}
        </Paper>
      </Container>
      <CreateSchemeDialog
        open={showCreateValidationDialog}
        onClose={handleCloseSchemeDialog}
      />
      <SchemeDetailsDialog
        onClose={handleCloseSchemeDialog}
        initialData={
          selectedPermissionScheme !== undefined
            ? selectedPermissionScheme
            : undefined
        }
        open={selectedPermissionScheme !== undefined}
      />
      <Snackbar
        open={alertMessage?.severity !== undefined}
        autoHideDuration={3000}
        onClose={() => setAlertMessage(undefined)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
      </Snackbar>
    </ContainerWrapper>
  );
};
