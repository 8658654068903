import { PropsWithChildren } from "react";
import tokenStorageHelper from "../helpers/tokenStorage";
import { jwtDecode } from "jwt-decode";
import { DecodedToken } from "../api/types";
import { Paper, Container, Typography, Link } from "@mui/material";
import { ContainerWrapper } from "./containerWrapper";

interface WithAccessProps extends PropsWithChildren {
  accessTokenKey: keyof DecodedToken;
}

const WithAccess = ({ accessTokenKey, children }: WithAccessProps) => {
  if (tokenStorageHelper.token) {
    var decodedToken = jwtDecode(tokenStorageHelper.token) as
      | DecodedToken
      | undefined;
    if (decodedToken && decodedToken[accessTokenKey] === "True") {
      return <>{children}</>;
    }
  }
  return (
    <ContainerWrapper>
      <Paper sx={{ minHeight: 650, p: 5 }}>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h3" sx={{ fontWeight: "bold" }}>
            No Access
          </Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            You do not have the required permissions to view this page. Please
            contact{" "}
            <Link target="_top" href={`mailto:helpdesk@workiva.com`}>
              helpdesk@workiva.com
            </Link>{" "}
            to gain access
          </Typography>
        </Container>
      </Paper>
    </ContainerWrapper>
  );
};

export default WithAccess;
