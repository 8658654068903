export const stopPolling = (id: number) => {
  clearInterval(id);
};

export const startPolling = (
  fn: Function,
  pollInterval: number = 5
): number => {
  return setInterval(fn, pollInterval * 1000);
};
