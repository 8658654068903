import { ConversionQueryRequest } from "../api/types";

export const isConversionQueryRequestEmpty = (
  req: ConversionQueryRequest
): boolean => {
  if (!req) return true;
  return (
    req.userId === null &&
    req.taxonomyId === null &&
    req.status === null &&
    req.organizationId === null &&
    req.hasWarnings === null &&
    req.hasErrors === null &&
    req.ReporterName === null &&
    req.ReporterIdentifier === null &&
    req.ConvertStartAt === null &&
    req.ConvertEndAt === null && 
    req.appId === null
  );
};
