import * as React from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TablePagination,
    TableRow,
    Paper,
    IconButton,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TableHead,
    Typography,
    styled,
    tableCellClasses,
    Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import DeleteIcon from '@mui/icons-material/Delete';
import { cDimensionValueReference } from '../../api/types';
import { t } from '@lingui/macro';
import LabeledTextField from '../labeledTextField';

// Pagination Controls
function TablePaginationActions(props: any) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0}>
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

// Data Table with Add Button and Autocomplete Dialog
export default function GenericGridTable({ data, setData }: { data: cDimensionValueReference[]; setData: (newData: cDimensionValueReference[]) => void }) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Open/Close dialog
    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    // Handle deleting a row
    const handleDeleteRow = (index: number) => {
        const newData = data.filter((_, i) => i !== index);
        setData(newData);
    };

    // Handle adding a new dimension value
    const handleAddDimensionValue = (dimensionValue: cDimensionValueReference) => {
        const newData = [...data, dimensionValue];
        setData(newData);
        handleCloseDialog();
        console.log('New data:', newData);
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#072a44",
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 12,
        },
    }));


    return (
        <Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: "50vh" }}>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>{t`Dimension`}</StyledTableCell>
                            <StyledTableCell>{t`Dimension Name`}</StyledTableCell>
                            <StyledTableCell>{t`Value Name`}</StyledTableCell>
                            <StyledTableCell align='right'>
                                <Tooltip title={t`Add new dimension value`}>
                                    <AddIcon
                                        onClick={handleOpenDialog}
                                        cursor='pointer'
                                    />
                                </Tooltip>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ height: "50vh" }}>
                        {(rowsPerPage > 0
                            ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : data
                        ).map((row, index) => (
                            <TableRow key={index} sx={{ maxHeight: 10, height: 5 }}>
                                <TableCell>{row.dimensionValue.name}</TableCell>
                                <TableCell>{row.dimensionValue.dimensionName}</TableCell>
                                <TableCell>{row.dimensionValue.valueName}</TableCell>
                                <TableCell align="right">
                                    <IconButton onClick={() => handleDeleteRow(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        {rowsPerPage > 0 && (
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%',
                                        }}
                                    >
                                        <Typography>{t`No dimensions have been added yet.`}</Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={4}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <LabeledTextField
                                        label={t`Override Typed Member`}
                                        fieldType='element'
                                        options={mockDimensionValueOptions}
                                    />
                                    <Box>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            count={data.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </Box>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <DialogCustomDimensionValue
                open={openDialog}
                onClose={handleCloseDialog}
                onAdd={handleAddDimensionValue}
            />
        </Box >
    );
}

const DialogCustomDimensionValue = ({
    open,
    onClose,
    onAdd,
}: { open: boolean; onClose: () => void; onAdd: (dimensionValue: cDimensionValueReference) => void; }) => {
    const [newDimensionValue, setNewDimensionValue] = React.useState<cDimensionValueReference>({
        dimensionValue: {
            taxonomyValue: [],
            customDimensionValue: null,
            onlyWhenConsolidated: null,
            isCustom: false,
            dimensionName: '',
            valueName: '',
            name: '',
        },
        typedMemberValue: null,
    }
    );


    const handleAddDimensionValue = () => {
        if (newDimensionValue) {
            onAdd(newDimensionValue);
        }
        onClose();
        console.log('New dimension value:', newDimensionValue);
    };

    const updateDimensionValue = (updatedFields: Partial<cDimensionValueReference['dimensionValue']>) => {
        setNewDimensionValue(prev => ({
            ...prev,
            dimensionValue: {
                ...prev.dimensionValue,
                ...updatedFields
            }
        }));
    };

    const handleFieldChangeEntireElement = (field: keyof cDimensionValueReference, value: cDimensionValueReference[keyof cDimensionValueReference]) => {
        if (field === 'dimensionValue') {
            updateDimensionValue(value as Partial<cDimensionValueReference['dimensionValue']>);
        } else {
            setNewDimensionValue(prev => ({
                ...prev,
                [field]: value as string | null
            }));
        }
    };


    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth='lg'
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                },
            }}
        >
            <DialogTitle>{t`Add Dimension Value`}</DialogTitle>
            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'wrap',
                    gap: 2,
                    padding: 2,
                }}
            >
                <LabeledTextField
                    label={t`Dimension`}
                    fieldType='dimension'
                    options={mockDimensionValueOptions}
                    value={newDimensionValue.dimensionValue.dimensionName || ''}
                    onValueChange={(selectedDimension) => {
                        const fullDimensionObject = mockDimensionValueOptions.find(
                            option => option.dimensionValue
                        );

                        if (fullDimensionObject) {
                            handleFieldChangeEntireElement('dimensionValue', fullDimensionObject.dimensionValue);
                            console.log('Setting full dimension value object:', fullDimensionObject.dimensionValue);
                        }
                    }}
                />

                <LabeledTextField
                    label={t`Typed member value`}
                    fieldType='text'
                    value={newDimensionValue.typedMemberValue || ''}
                    onValueChange={(e) => handleFieldChangeEntireElement('typedMemberValue', e.target.value || null)}
                />

                <LabeledTextField
                    label={t`Bind to consolidated value`}
                    fieldType='select'
                    options={['Yes', 'No']}
                    value={newDimensionValue.dimensionValue.onlyWhenConsolidated || ''}
                    onValueChange={(e) => handleFieldChangeEntireElement('dimensionValue', { ...newDimensionValue.dimensionValue, onlyWhenConsolidated: e.target.value || null })}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t`Cancel`}</Button>
                <Button onClick={handleAddDimensionValue}>{t`Add`}</Button>
            </DialogActions>
        </Dialog>
    );
};


const mockDimensionValueOptions: cDimensionValueReference[] = [
    {
        dimensionValue: {
            taxonomyValue: [{
                dimensionReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'ifrs-full:comprehensiveincomethatisverylonghahaxdkekw',
                    description: 'Description of Dimension1',
                },
                dimensionValueReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Value1',
                    description: 'Description of Value1',
                },
            }],
            customDimensionValue: null,
            onlyWhenConsolidated: null,
            isCustom: false,
            dimensionName: 'Dimension1',
            valueName: 'Value1',
            name: 'Dimension1_Value1',
        },
        typedMemberValue: null,
    },
    {
        dimensionValue: {
            taxonomyValue: [{
                dimensionReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Dimension2',
                    description: 'Description of Dimension2',
                },
                dimensionValueReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Value2',
                    description: 'Description of Value2',
                },
            }],
            customDimensionValue: null,
            onlyWhenConsolidated: null,
            isCustom: false,
            dimensionName: 'Dimension2',
            valueName: 'Value2',
            name: 'Dimension2_Value2',
        },
        typedMemberValue: null,
    },
    {
        dimensionValue: {
            taxonomyValue: [{
                dimensionReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Dimension3',
                    description: 'Description of Dimension3',
                },
                dimensionValueReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Value3',
                    description: 'Description of Value3',
                },
            }],
            customDimensionValue: null,
            onlyWhenConsolidated: null,
            isCustom: false,
            dimensionName: 'Dimension3',
            valueName: 'Value3',
            name: 'Dimension3_Value3',
        },
        typedMemberValue: null,
    },
    {
        dimensionValue: {
            taxonomyValue: [{
                dimensionReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Dimension4',
                    description: 'Description of Dimension4',
                },
                dimensionValueReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Value4',
                    description: 'Description of Value4',
                },
            }],
            customDimensionValue: null,
            onlyWhenConsolidated: null,
            isCustom: false,
            dimensionName: 'Dimension4',
            valueName: 'Value4',
            name: 'Dimension4_Value4',
        },
        typedMemberValue: null,
    },
    {
        dimensionValue: {
            taxonomyValue: [{
                dimensionReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Dimension5',
                    description: 'Description of Dimension5',
                },
                dimensionValueReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Value5',
                    description: 'Description of Value5',
                },
            }],
            customDimensionValue: null,
            onlyWhenConsolidated: null,
            isCustom: false,
            dimensionName: 'Dimension5',
            valueName: 'Value5',
            name: 'Dimension5_Value5',
        },
        typedMemberValue: null,
    },
    {
        dimensionValue: {
            taxonomyValue: [{
                dimensionReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Dimension6',
                    description: 'Description of Dimension6',
                },
                dimensionValueReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Value6',
                    description: 'Description of Value6',
                },
            }],
            customDimensionValue: null,
            onlyWhenConsolidated: null,
            isCustom: false,
            dimensionName: 'Dimension6',
            valueName: 'Value6',
            name: 'Dimension6_Value6',
        },
        typedMemberValue: null,
    },
    {
        dimensionValue: {
            taxonomyValue: [{
                dimensionReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Dimension7',
                    description: 'Description of Dimension7',
                },
                dimensionValueReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Value7',
                    description: 'Description of Value7',
                },
            }],
            customDimensionValue: null,
            onlyWhenConsolidated: null,
            isCustom: false,
            dimensionName: 'Dimension7',
            valueName: 'Value7',
            name: 'Dimension7_Value7',
        },
        typedMemberValue: null,
    },
    {
        dimensionValue: {
            taxonomyValue: [{
                dimensionReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Dimension8',
                    description: 'Description of Dimension8',
                },
                dimensionValueReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Value8',
                    description: 'Description of Value8',
                },
            }],
            customDimensionValue: null,
            onlyWhenConsolidated: null,
            isCustom: false,
            dimensionName: 'Dimension8',
            valueName: 'Value8',
            name: 'Dimension8_Value8',
        },
        typedMemberValue: null,
    },
    {
        dimensionValue: {
            taxonomyValue: [{
                dimensionReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Dimension9',
                    description: 'Description of Dimension9',
                },
                dimensionValueReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Value9',
                    description: 'Description of Value9',
                },
            }],
            customDimensionValue: null,
            onlyWhenConsolidated: null,
            isCustom: false,
            dimensionName: 'Dimension9',
            valueName: 'Value9',
            name: 'Dimension9_Value9',
        },
        typedMemberValue: null,
    },
    {
        dimensionValue: {
            taxonomyValue: [{
                dimensionReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Dimension10',
                    description: 'Description of Dimension10',
                },
                dimensionValueReference: {
                    namespaceAbbreviation: 'NS',
                    name: 'Value10',
                    description: 'Description of Value10',
                },
            }],
            customDimensionValue: null,
            onlyWhenConsolidated: null,
            isCustom: false,
            dimensionName: 'Dimension10',
            valueName: 'Value10',
            name: 'Dimension10_Value10',
        },
        typedMemberValue: null,
    },
];
