import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  addSubscription,
  getPermissionSchemesAvaliable,
} from "../../../api/organizations";
import { ApiSchemes } from "../../../api/types";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { t } from "@lingui/macro";
import moment from "moment";
export interface NewSubScriptionDialogProps {
  id: string | undefined;
  open: boolean;
  onClose: (updateStatus: "none" | "success" | "error") => void;
}

export const NewSubScriptionDialog = ({
  id,
  open,
  onClose,
}: NewSubScriptionDialogProps) => {
  const handleClose = (updateStatus: "none" | "success" | "error") => {
    onClose(updateStatus);
  };

  const [permissionSchemes, setPermissionSchemes] = useState<ApiSchemes[]>();
  const [expireAt, setExpireAt] = useState<string>();
  const [selectedPermissionScheme, setSelectedPermissionScheme] =
    useState<string>();

  useEffect(() => {
    if (open) {
      const getData = async () => {
        const response = await getPermissionSchemesAvaliable(id as string);
        setPermissionSchemes(response.data);
      };
      getData();
    }
  }, [open, id]);

  const handleCreateNewSubscription = async () => {
    try {
      const addSub = addSubscription(
        id as string,
        selectedPermissionScheme as string,
        expireAt ? moment(expireAt).toISOString() : null
      );
      await addSub;
      handleClose("success");
    } catch (ex: any) {
      handleClose(
        ex.response.data.IsTranslated
          ? ex.response.data.Exceptions[0]
          : t`Could not update organization. Try again later`
      );
    }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Dialog
          open={open}
          disableEscapeKeyDown
          PaperProps={{ style: { maxWidth: 800, minWidth: 800 } }}
          maxWidth="xl"
          onClose={(reason) => {
            if (reason !== "backdropClick" || "escapeKeyDown") {
              handleClose("none");
            }
          }}
        >
          <DialogTitle>{t`Create new Subscription`} </DialogTitle>
          {!permissionSchemes && <DialogContent>{t`Loading...`}</DialogContent>}
          {permissionSchemes && (
            <>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={4} container alignItems="center">
                    <InputLabel id="permission-scheme-label">
                      {t`Permission Scheme`}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={8}>
                    <Select
                      fullWidth
                      labelId="permission-scheme-label"
                      variant="outlined"
                      onChange={(e) => {
                        setSelectedPermissionScheme(e.target.value as string);
                      }}
                    >
                      {permissionSchemes.map((scheme) => (
                        <MenuItem key={scheme.id} value={scheme.id}>
                          {scheme.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={4} container alignItems="center">
                    <InputLabel id="expire-at-label">{t`Expire at`}</InputLabel>
                  </Grid>
                  <Grid item xs={8}>
                    <DatePicker
                      label={t`Enter Expire date`}
                      onChange={(newValue) => {
                        setExpireAt(newValue?.toLocaleString() as string);
                      }}
                      sx={{ minWidth: "100%" }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  onClick={handleCreateNewSubscription}
                >
                  {t`CREATE`}
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </LocalizationProvider>
    </>
  );
};
