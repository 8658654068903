import { t } from "@lingui/macro";
import { Container, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { ApiOrganization } from "../../api/types";
import { useGetQuery } from "../../hooks/useGetQuery";
import ContentHeaderToolbar from "../contentHeaderToolbar";
import DataTable from "../dataTable";
import EmptyTableAlert from "../emptyTableAlert";
import { organizationsTableColumns } from "./organizationsTableColumns";
import SearchIcon from "@mui/icons-material/Search";
import CreateOrganizationDialog from "./createOrganizationDialog";
import { useNavigate } from "react-router-dom";
import { Loader } from "../loader";
import { ContainerWrapper } from "../containerWrapper";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import AddIcon from "@mui/icons-material/Add";
import AlertSnackbar from "../alertSnackbar";

const Organizations = () => {
  const [cacheBuster, setCacheBuster] = useState(crypto.randomUUID());
  const [selectedOrganizationsId, setSelectedOrganizationsId] =
    useState<string>();
  const [currentQuery, setCurrentQuery] = useState("");
  const { data, error, loading } = useGetQuery<ApiOrganization[]>(
    `/api/organizations/search?keyword=${currentQuery}&cb=${cacheBuster}`,
    currentQuery.length < 3
  );
  const [showCreateOrganizationDialog, setShowCreateOrganizationDialog] =
    useState(false);

  const [alertMessage, setAlertMessage] = useState<
    | {
      message: string;
      severity: "error" | "warning" | "info" | "success" | undefined;
    }
    | undefined
  >(undefined);

  function handlecloseDialog(
    updateStatus: "none" | "success" | "error",
    reason?: string
  ) {
    setShowCreateOrganizationDialog(false);
    if (updateStatus === "success" || updateStatus === "error") {
      setAlertMessage({
        message: reason || updateStatus === "success" ? t`Organization created` : t`Organization was not created`,
        severity: updateStatus,
      });
    }
    if (updateStatus === "success") {
      setCacheBuster(crypto.randomUUID());
    }
  }

  const navigate = useNavigate();

  /* Not optimal but works? - is there a better method? */
  useEffect(() => {
    if (selectedOrganizationsId !== undefined) {
      navigate(`/organizations/${selectedOrganizationsId}`);
    }
  }, [selectedOrganizationsId, navigate]);

  return (
    <>
      <ContainerWrapper>
        <Container
          maxWidth={false}
          sx={{
            ml: 0,
            mb: 10,
            overflow: "auto",
          }}
          component="div"
        >
          <Paper
            elevation={1}
            sx={{
              maxHeight: 1000,
              minHeight: 650,
              overflow: "auto",
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Container maxWidth={false}>
              <ContentHeaderToolbar
                titleIcon={<ApartmentOutlinedIcon fontSize="large" />}
                title={t`Organizations`}
                actions={[
                  {
                    id: "addNew",
                    title: t`Create `,
                    icon: AddIcon,
                    disabled: false,
                    turnedOn: false,
                    clickAction: () => setShowCreateOrganizationDialog(true),
                  },
                ]}
                CreateButton={true}
                createButtonWidth={220}
                showIcons
                searchBar
                searchValue={currentQuery}
                onUpdateSearch={setCurrentQuery}
                searchPlaceholder={t`Search Organizations`}
                searchAutoFocus={true}
                searchBarWidth={400}
              />
            </Container>
            {loading && <Loader height={500} />}
            {currentQuery.length < 3 && (
              <EmptyTableAlert
                severity="info"
                title={t`No organizations loaded`}
                subTitle={t`Use the search bar to find organizations (minimum 3 characters)`}
                mainIcon={
                  <SearchIcon sx={{ fontSize: 75, mb: 2, fill: "#e6e6e6" }} />
                }
                showIcon={true}
              />
            )}
            {currentQuery.length > 2 &&
              !loading &&
              !error &&
              data &&
              data.length === 0 && (
                <EmptyTableAlert
                  severity="info"
                  title={t`No organizations match your query`}
                  subTitle={t`No luck, Try changing your search filter`}
                  mainIcon={
                    <ThumbDownOffAltIcon
                      sx={{ fontSize: 75, mb: 2, fill: "#e6e6e6" }}
                    />
                  }
                  showIcon={true}
                />
              )}
            {currentQuery.length > 2 &&
              !loading &&
              !error &&
              data &&
              data.length > 0 && (
                <DataTable<ApiOrganization>
                  columns={organizationsTableColumns()}
                  data={data}
                  onClickRow={setSelectedOrganizationsId}
                />
              )}
          </Paper>
        </Container>
        <CreateOrganizationDialog
          open={showCreateOrganizationDialog}
          onClose={handlecloseDialog}
        />
        <AlertSnackbar
          alertMessage={alertMessage}
          updateAlertStatus={setAlertMessage}
        />
      </ContainerWrapper>
    </>
  );
};

export default Organizations;
