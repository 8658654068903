import { Container } from "@mui/system";
import { ContainerWrapper } from "../containerWrapper";
import { Pagination, Paper } from "@mui/material";
import { t } from "@lingui/macro";
import ContentHeaderToolbar from "../contentHeaderToolbar";
import { validationTable } from "../../api/types";
import DataTable from "../dataTable";
import EmptyTableAlert from "../emptyTableAlert";
import { useEffect, useState } from "react";
import { validationTableColumns } from "./validationTableColumns";
import { Loader } from "../loader";
import TaskIcon from "@mui/icons-material/Task";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import CreateValidationDialog from "./createValidationDialog";
import ValidationById from "./validationById";
import { generalGet } from "../../api/general";
import AlertSnackbar from "../alertSnackbar";

const Validation = () => {
  const [cacheBuster, setCacheBuster] = useState(crypto.randomUUID());
  const [selectedValidationId, setSelectedValidationId] = useState<string>();
  const [currentQuery, setCurrentQuery] = useState("");
  const [data, setData] = useState<validationTable[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [alertMessage, setAlertMessage] = useState<
    | {
      message: string;
      severity: "error" | "warning" | "info" | "success" | undefined;
    }
    | undefined
  >(undefined);

  const pageSize = 25;
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await generalGet<validationTable[]>(
        `/api/validation/entryinformation/all?cb=${cacheBuster}`
      );
      setData(response.data);
    } catch (ex: any) {
      setAlertMessage({
        message: ex.response.data.IsTranslated
          ? ex.response.data.Exceptions[0]
          : t`Could not update organization. Try again later`,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cacheBuster]);

  const filteredData =
    currentQuery.length >= 3
      ? data.filter((item) => item.code.includes(currentQuery))
      : data;

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const pagedData = filteredData.slice(startIndex, endIndex);

  const [showCreateValidationDialog, setShowCreateValidationDialog] =
    useState(false);

  const handleCloseValidationDialog = (
    updateStatus: "error" | "none" | "success",
    message?: string
  ) => {
    setSelectedValidationId(undefined);
    setShowCreateValidationDialog(false);
    setAlertMessage({
      message: message || "",
      severity: updateStatus === "none" ? undefined : updateStatus,
    });
    if (updateStatus === "success") {
      setCacheBuster(crypto.randomUUID());
    }
  };

  return (
    <ContainerWrapper>
      <Container
        maxWidth={false}
        sx={{
          ml: 0,
          mb: 10,
          overflow: "auto",
        }}
      >
        <Paper
          elevation={1}
          sx={{
            maxHeight: 1000,
            minHeight: 650,
            overflow: "auto",
            display: "flex",
            justifyItems: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Container maxWidth={false}>
            <ContentHeaderToolbar
              titleIcon={<TaskIcon fontSize="large" />}
              title={t`Validations`}
              actions={[
                {
                  id: "addNew",
                  title: t`Create `,
                  icon: AddIcon,
                  disabled: false,
                  turnedOn: false,
                  clickAction: () => setShowCreateValidationDialog(true),
                },
              ]}
              CreateButton={true}
              createButtonWidth={220}
              showIcons
              searchBar
              searchValue={currentQuery}
              onUpdateSearch={setCurrentQuery}
              searchPlaceholder={t`Search Validations`}
              searchAutoFocus={true}
              searchBarWidth={400}
            />
          </Container>
          {loading && <Loader height={500} />}
          {currentQuery.length < 0 && (
            <EmptyTableAlert
              severity="info"
              title={t`No validation loaded`}
              subTitle={t`Use the search bar to find validations (minimum 3 characters)`}
              mainIcon={
                <SearchIcon sx={{ fontSize: 75, mb: 2, fill: "#e6e6e6" }} />
              }
              showIcon={true}
            />
          )}
          {!loading && !pagedData && (
            <EmptyTableAlert
              severity="info"
              title={t`No validation match your query`}
              subTitle={t`No luck, Try changing your search filter`}
              mainIcon={
                <ThumbDownOffAltIcon
                  sx={{ fontSize: 75, mb: 2, fill: "#e6e6e6" }}
                />
              }
              showIcon={true}
            />
          )}
          {!loading && pagedData && pagedData.length > 0 && (
            <DataTable<validationTable>
              columns={validationTableColumns()}
              data={pagedData}
              onClickRow={setSelectedValidationId}
              noWidth={true}
            />
          )}
          <Container
            maxWidth={false}
            sx={{
              display: "flex",
              alignItems: "flex-end",
              mt: "auto",
              justifyContent: "flex-end",
            }}
          >
            <Pagination
              count={Math.ceil(filteredData.length / pageSize)}
              page={currentPage}
              onChange={(_, pageNumber) => setCurrentPage(pageNumber)}
              showFirstButton
            />
          </Container>
        </Paper>
      </Container>
      <CreateValidationDialog
        open={showCreateValidationDialog}
        onClose={handleCloseValidationDialog}
      />
      <ValidationById
        id={selectedValidationId}
        open={selectedValidationId !== undefined}
        onClose={handleCloseValidationDialog}
      />
      <AlertSnackbar
        alertMessage={alertMessage}
        updateAlertStatus={setAlertMessage}
      />
    </ContainerWrapper>
  );
};

export default Validation;
