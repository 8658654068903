import { t } from "@lingui/macro";
import { ApiUser } from "../api/types";

export const getUserNameValidationError = (val: string | undefined): string => {
  if (val && val.length > 0 && val.length < 256) return "";
  return t`Value must be between 1 and 256 characters`;
};

export const getUserEmailValidationError = (
  val: string | undefined,
  orgDomain?: string
): string => {
  if (
    val &&
    val.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  ) {
    if (orgDomain) {
      if (orgDomain.toLowerCase() !== val.toLowerCase().split("@")[1]) {
        return t`Admin user email address must match the organization domain`;
      }
    }
    return "";
  }

  return t`Please enter a valid email address`;
};

export const isUserValid = (user: Partial<ApiUser> | null): boolean => {
  if (!user) return false;
  return (
    getUserNameValidationError(user.name).length === 0 &&
    getUserEmailValidationError(user.emailAddress).length === 0
  );
};
