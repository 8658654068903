import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { generalGet } from "../api/general";

export interface AsyncAutoCompleteSelectProps<T> {
  onSelect: (val: string) => void;
  selectedValueId: string | null;
  label: string;
  endpointUrl: string;
  alternateEndpointUrl?: string;
  identifierProp: keyof T;
  labelProp: keyof T;
}

export default function AsyncAutoCompleteSelect<T>({
  onSelect,
  selectedValueId,
  label,
  endpointUrl,
  alternateEndpointUrl,
  identifierProp,
  labelProp,
}: AsyncAutoCompleteSelectProps<T>) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<T[]>([]);
  const [selectedValue, setSelectedValue] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let active = true;
    const fetchData = async () => {
      try {
        setLoading(true);
        const resp = await generalGet<T[]>(endpointUrl);
        if (active) {
          setOptions(resp.data);
          if (selectedValueId) {
            const selectedResp = await generalGet<T>(`${alternateEndpointUrl}${selectedValueId}`);
            setSelectedValue(selectedResp.data);
          }
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchData();
    }

    return () => {
      active = false;
    };
  }, [open, endpointUrl, alternateEndpointUrl, selectedValueId]);

  const val =
    options.length > 0 && selectedValueId
      ? options.find((item: T) => item[identifierProp] === selectedValueId)
      : null;

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option[identifierProp] === value[identifierProp]}
      onChange={(event: any, newValue: T | null) => {
        onSelect(newValue ? String(newValue[identifierProp]) : "");
      }}
      getOptionLabel={(option) => String(option[labelProp])}
      options={options}
      value={val || selectedValue}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
