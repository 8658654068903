import NavbarCustomObjects from "../navbarCustomObjects";
import { useEffect, useState } from "react";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import { t } from "@lingui/macro";
import { ContainerWrapper } from "../containerWrapper";
import { Snackbar, Alert, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { CustomObjectsPageComponent } from "./customObjectsPageComponent";
import { cCustomDimensionsDetails, cCustomDimensionValueDetails, cExtensionElementsDetails, cHeaderDetails, cLabelDetails, cTagDetails } from "../../api/types";

const CustomObjects = () => {

  // State to control the display of the "Under Construction" dialog
  const [openConstructionDialog, setOpenConstructionDialog] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split("/")[2];

  const [url, setUrl] = useState<string>("");

  const [alertMessage, setAlertMessage] = useState<
    {
      message: string | undefined;
      severity: "error" | "warning" | "info" | "success" | undefined;
    }
    | undefined
  >(undefined);
  console.log(alertMessage)

  useEffect(() => {
    if (path !== url) {
      setTimeout(() => {
      }, 1500);
      setUrl(path);
    }

    // Show dialog only if not previously accepted
    if (localStorage.getItem("underConstructionAccepted") !== "true") {
      setOpenConstructionDialog(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  const handleAlertUpdate = (message: string | undefined, severity: "error" | "warning" | "info" | "success" | undefined) => {
    setAlertMessage({ message, severity });
  };

  if (!path) {
    return <Navigate to="/customobjects/tags" replace />;
  }

  const handleClosingAlert = () => {
    /* sleep for 1 second before setting undefined */
    setTimeout(() => {
      setAlertMessage(undefined);
    }, 1000);
  }

  const handleAgree = () => {
    setOpenConstructionDialog(false);
    localStorage.setItem("underConstructionAccepted", "true");
  };

  const handleDisagree = () => {
    setOpenConstructionDialog(false);
    navigate("/conversions");
  };

  return (
    <ContainerWrapper>
      <Dialog
        open={openConstructionDialog}
        onClose={handleDisagree}
        aria-labelledby="under-construction-dialog-title"
        aria-describedby="under-construction-dialog-description"
      >
        <DialogTitle id="under-construction-dialog-title">{"This page is under construction 🔨"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="under-construction-dialog-description">
            This page is under construction and is only for testing use. Anything you do here will not be saved.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisagree} color="primary">
            Disagree
          </Button>
          <Button onClick={handleAgree} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <NavbarCustomObjects />
      {
        path === "tags" && (
          <CustomObjectsPageComponent<cTagDetails>
            url="/api/test/test"
            headerTitle={t`Tags`}
            selectTemplate="tags"
            onUpdate={
              (updateStatus: "error" | "warning" | "info" | "success", reason?: string | undefined) => {
                handleAlertUpdate(reason, updateStatus);
              }
            }
          />
        )
      }
      {
        path === "headers" && (
          <CustomObjectsPageComponent<cHeaderDetails>
            url="/api/test/test"
            headerTitle={t`Headers`}
            selectTemplate="header"
            onUpdate={
              (updateStatus: "error" | "warning" | "info" | "success", reason?: string | undefined) => {
                handleAlertUpdate(reason, updateStatus);
              }
            }
          />
        )
      }
      {
        path === "labels" && (
          <CustomObjectsPageComponent<cLabelDetails>
            selectTemplate="labels"
            url="/api/test/test"
            headerTitle={t`Labels`}
            onUpdate={
              (updateStatus: "error" | "warning" | "info" | "success", reason?: string | undefined) => {
                handleAlertUpdate(reason, updateStatus);
              }
            }
          />
        )
      }
      {
        path === "dimensions" && (
          <CustomObjectsPageComponent<cCustomDimensionsDetails>
            selectTemplate="customDimensions"
            url="/api/test/test"
            headerTitle={t`Custom Dimensions`}
            onUpdate={
              (updateStatus: "error" | "warning" | "info" | "success", reason?: string | undefined) => {
                handleAlertUpdate(reason, updateStatus);
              }
            }
          />
        )
      }
      {
        path === "dimensionvalues" && (
          <CustomObjectsPageComponent<cCustomDimensionValueDetails>
            selectTemplate="dimensionValue"
            url="/api/test/test"
            headerTitle={t`Dimension Values`}
            onUpdate={
              (updateStatus: "error" | "warning" | "info" | "success", reason?: string | undefined) => {
                handleAlertUpdate(reason, updateStatus);
              }
            }
          />
        )
      }
      {
        path === "extensionelements" && (
          <CustomObjectsPageComponent<cExtensionElementsDetails>
            selectTemplate="extensionElements"
            url="/api/test/test"
            headerTitle={t`Extension Elements`}
            onUpdate={
              (updateStatus: "error" | "warning" | "info" | "success", reason?: string | undefined) => {
                handleAlertUpdate(reason, updateStatus);
              }
            }
          />
        )
      }
      {
        path === "settings" && (
          <CustomObjectsPageComponent<cExtensionElementsDetails>
            selectTemplate="settings"
            url="/api/test/test"
            headerTitle={t`Settings`}
            onUpdate={
              (updateStatus: "error" | "warning" | "info" | "success", reason?: string | undefined) => {
                handleAlertUpdate(reason, updateStatus);
              }
            }
          />
        )
      }
      {
        path === "contextsettings" && (
          <CustomObjectsPageComponent<cExtensionElementsDetails>
            selectTemplate="contextSettings"
            url="/api/test/test"
            headerTitle={t`Context Settings`}
            onUpdate={
              (updateStatus: "error" | "warning" | "info" | "success", reason?: string | undefined) => {
                handleAlertUpdate(reason, updateStatus);
              }
            }
          />
        )
      }
      {
        path === "globalrequests" && (
          <CustomObjectsPageComponent<cExtensionElementsDetails>
            selectTemplate="globalRequests"
            url="/api/test/test"
            headerTitle={t`Global Requests`}
            onUpdate={
              (updateStatus: "error" | "warning" | "info" | "success", reason?: string | undefined) => {
                handleAlertUpdate(reason, updateStatus);
              }
            }
          />
        )
      }
      {
        path === "consistencycheck" && (
          <CustomObjectsPageComponent<cExtensionElementsDetails>
            selectTemplate="consistencyCheck"
            url="/api/test/test"
            headerTitle={t`Consistency Check`}
            onUpdate={
              (updateStatus: "error" | "warning" | "info" | "success", reason?: string | undefined) => {
                handleAlertUpdate(reason, updateStatus);
              }
            }
            isConsistencyCheck
          />
        )
      }
      <Snackbar
        open={alertMessage !== undefined}
        autoHideDuration={3000}
        onClose={handleClosingAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity={alertMessage?.severity}>
          {alertMessage?.message}
        </Alert>
      </Snackbar>
    </ContainerWrapper>
  );
};

export default CustomObjects;
