import { Trans, t } from "@lingui/macro";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItemText,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ApiDetailedTaxonomy, permissionSchemeTable } from "../../api/types";
import {
  togglePermissionScheme,
  toggleReflectPermissionSchemeDetails,
} from "../../api/permissionSchemes";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

export interface schemeDetailsDialogProps {
  open: boolean;
  initialData: permissionSchemeTable | undefined;
  onClose: (
    updateStatus: "none" | "success" | "error",
    reason?: string | undefined
  ) => void;
}

const SchemeDetailsDialog = ({
  open,
  initialData,
  onClose,
}: schemeDetailsDialogProps) => {
  const [updating, setUpdating] = useState<boolean>(false);
  const [data, setData] = useState<permissionSchemeTable>({
    id: "",
    name: "",
    createdAt: "",
    priceInEuro: 0,
    isActive: false,
  });

  useEffect(() => {
    if (initialData) {
      setData({
        id: initialData.id || "",
        name: initialData.name || "",
        createdAt: initialData.createdAt || "",
        priceInEuro: initialData.priceInEuro || 0,
        isActive: initialData.isActive || false,
      });
    }
  }, [initialData]);

  const handleFieldValueChange = (
    key: keyof ApiDetailedTaxonomy | string | undefined,
    value: string
  ) => {
    if (key) {
      const updated = { ...data };
      (updated as any)[key] = value;
      setData(updated as permissionSchemeTable);
    }
  };

  const handleClose = (
    updateStatus: "none" | "success" | "error",
    reason?: string | undefined
  ) => {
    onClose(updateStatus, reason);
  };

  const handleSubmit = async () => {
    setUpdating(true);
    try {
      toggleReflectPermissionSchemeDetails(data.id || "", {
        name: data?.name || "",
        priceInEuro: data?.priceInEuro || 0,
      });
      setUpdating(false);
      handleClose("success", t`Taxonomy scheme updated!`);
    } catch (ex: any) {
      onClose(
        ex.response.data.IsTranslated
          ? ex.response.data.Exceptions[0]
          : t`Could not update organization. Try again later`
      );
      handleClose("error");
    }
  };

  const handleActivation = async () => {
    try {
      if (data && data.id) {
        await togglePermissionScheme(data.id, data.isActive ? false : true);
        onClose("success", t`PermissionScheme updated`);
      }
    } catch (ex: any) {
      console.log(ex);
      onClose(
        "error",
        ex.response.data.IsTranslated
          ? ex.response.data.Exceptions[0]
          : t`Error. Try again later`
      );
    }
  };

  return (
    <>
      <Dialog
        open={open}
        disableEscapeKeyDown
        maxWidth="lg"
        sx={{
          overflow: "auto",
          maxHeight: 1200,
          minHeight: 1000,
        }}
        onClose={(reason) => {
          if (reason !== "backdropClick" || "escapeKeyDown") {
            handleClose("none", "");
          }
        }}
      >
        <DialogTitle>{initialData?.name || data?.name}</DialogTitle>
        {!data && !initialData && (
          <Alert severity="error">
            <Trans>
              Could not load taxonomy details. Please try again later
            </Trans>
          </Alert>
        )}
        <>
          <DialogContent
            sx={{
              minHeight: 210,
              minWidth: 600,
            }}
          >
            <Grid item xs={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <ListItemText
                    primary={t`NAME`}
                    primaryTypographyProps={{ fontWeight: 700 }}
                  />
                </Grid>
                <Grid item xs={7}>
                  <ListItemText>
                    <TextField
                      variant="standard"
                      value={data?.name}
                      onChange={(e) =>
                        handleFieldValueChange("name", e.currentTarget.value)
                      }
                    />
                  </ListItemText>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <ListItemText
                    primary={t`Price in Euro`}
                    primaryTypographyProps={{ fontWeight: 700 }}
                  />
                </Grid>
                <Grid item xs={7}>
                  <ListItemText>
                    <TextField
                      type="number"
                      variant="standard"
                      value={data?.priceInEuro}
                      onChange={(e) =>
                        handleFieldValueChange(
                          "priceInEuro",
                          e.currentTarget.value
                        )
                      }
                    />
                  </ListItemText>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid>
              <Button variant="outlined" onClick={() => handleClose("none")}>
                <Trans>Close</Trans>
              </Button>
              <Button
                variant={data.isActive ? "outlined" : "contained"}
                onClick={() => handleActivation()}
                disabled={updating}
                sx={{
                  ml: 2,
                  backgroundColor: data.isActive ? "transparent" : "white",
                  color: data.isActive ? "black" : "black",
                  ":hover": {
                    backgroundColor: data.isActive ? "transparent" : "#e6e6e6",
                  },
                }}
              >
                {!data.isActive ? (
                  <>
                    <CheckIcon sx={{ fill: "green", pr: 0.5 }} />
                    Activate
                  </>
                ) : (
                  <>
                    <CloseIcon sx={{ fill: "red", pr: 0.5 }} />
                    DeActivate
                  </>
                )}
              </Button>
              <Button
                sx={{ ml: 2 }}
                variant="contained"
                onClick={handleSubmit}
                disabled={
                  updating ||
                  (initialData?.name === data.name &&
                    initialData?.priceInEuro.toString() ===
                      data.priceInEuro.toString()) ||
                  data.name.length === 0 ||
                  data.priceInEuro === 0
                }
              >
                {!updating && <Trans>Update</Trans>}
                {updating && <CircularProgress size={25} />}
              </Button>
            </Grid>
          </DialogActions>
        </>
      </Dialog>
    </>
  );
};

export default SchemeDetailsDialog;
